import {takeLatest, put, call} from 'redux-saga/effects';
import * as auth from '../actions/auth';
import {getMembershipStatus, getUserOffers, joinDeals} from '../actions/deals';
import {getUserBio} from '../actions/account';
import {getToken, socialAuth, signOut, getUserData, signUp, refreshToken} from '../../api/auth';
import {gaEvents} from '../../constants/ga4events';

export function* signInWorker({payload}) {
  try {
    const data = yield call(getToken, {
      username: payload.username,
      password: payload.password,
    });

    if (data.access_token) {
      localStorage.setItem('accessToken', data.access_token);
      localStorage.setItem('rT', data.refresh_token);
      localStorage.setItem(
        'loginTs',
        JSON.stringify({expiresIn: data.expires_in, updateTime: new Date()}),
      );

      if ((window as any).SessionStack) {
        (window as any).SessionStack.log('User Signed In (email)', {level: 'info'});
      }

      yield put(auth.signInSuccess({error: false, data: data}));

      yield put(getUserBio());

      if (payload.disability_organization) {
        yield put(auth.getUserData({disability_organization: payload.disability_organization}));
      } else {
        yield put(auth.getUserData({login: true}));
      }

      yield put(getMembershipStatus());

      if (window.location.pathname !== '/r') yield put(getUserOffers());
    } else {
      yield put(auth.signInFailure({error: true, data: data}));
    }
  } catch (e) {
    let error;

    try {
      error = JSON.parse(e);
    } catch (e) {
      error = null;
    }

    yield put(auth.signInFailure({error: true, data: error ? error : {}}));
  }
}

export function* signInWatcher() {
  yield takeLatest(auth.signIn.toString(), signInWorker);
}

export function* updateTokenWorker() {
  try {
    const data = yield call(refreshToken);

    if (data.access_token) {
      localStorage.setItem('accessToken', data.access_token);
      localStorage.setItem('rT', data.refresh_token);
      localStorage.setItem(
        'loginTs',
        JSON.stringify({expiresIn: data.expires_in, updateTime: new Date()}),
      );

      yield put(auth.signInSuccess({error: false, data: data}));
    } else {
      yield put(auth.signInFailure({error: true, data: data}));
      yield put(auth.signOut());
    }
  } catch (e) {
    let error;

    try {
      error = JSON.parse(e);
    } catch (e) {
      error = null;
    }

    yield put(auth.signInFailure({error: true, data: error ? error : {}}));
    yield put(auth.signOut());
  }
}

export function* updateTokenWatcher() {
  yield takeLatest(auth.updateToken.toString(), updateTokenWorker);
}

export function* signOutWorker() {
  try {
    yield call(signOut);
    localStorage.removeItem('Name');
    localStorage.removeItem('email');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('clubMember');
    localStorage.removeItem('userId');
    localStorage.removeItem('rT');
    localStorage.removeItem('loginTs');
    localStorage.removeItem('specialOffers');
    localStorage.removeItem('UserOffers');

    window.dispatchEvent(new Event('storage'));
    if ((window as any).SessionStack) {
      (window as any).SessionStack.log('User Signed Out', {level: 'info'});
    }
    gaEvents.eventLogOut();
    yield put(auth.signOutSuccess());
  } catch (e) {
    let error;

    try {
      error = JSON.parse(e);
    } catch (e) {
      error = null;
    }

    yield put(auth.signOutFailure({error: true, data: error ? error : {}}));
  }
}

export function* signOutWatcher() {
  yield takeLatest(auth.signOut.toString(), signOutWorker);
}

export function* socialSignInWorker({payload}) {
  try {
    yield call(socialAuth, payload);
  } catch (e) {
    let error;

    try {
      error = JSON.parse(e);
    } catch (e) {
      error = null;
    }

    yield put(auth.socialSignInFailure(error ? error : {}));
  }
}

export function* socialSignInWatcher() {
  yield takeLatest(auth.socialSignIn.toString(), socialSignInWorker);
}

export function* getUserDataWorker({payload}) {
  try {
    const data = yield call(getUserData);

    if (data.email) {
      localStorage.setItem('email', data.email);
      localStorage.setItem('userId', data['https://accessiblego/app_metadata'].customerId);

      if (payload) {
        if (payload.disability_organization) {
          yield put(
            joinDeals({
              customerId: data['https://accessiblego/app_metadata'].customerId,
              cugOrganization: payload.disability_organization,
            }),
          );
        }

        if (payload.login) {
          gaEvents.eventLoginEmail(data['https://accessiblego/app_metadata'].customerId);
        }
      }

      yield put(auth.getUserDataSuccess({error: false, data: data}));
    } else {
      yield put(auth.getUserDataFailure({error: true, data: data}));
    }
  } catch (e) {
    yield put(auth.getUserDataFailure({error: true, data: {}}));
  }
}

export function* getUserDataWatcher() {
  yield takeLatest(auth.getUserData.toString(), getUserDataWorker);
}

export function* signUpWorker({payload}) {
  try {
    const data = yield call(signUp, payload);
    if (data._id) {
      gaEvents.eventSignUpEmail();
      yield put(
        auth.signIn({
          username: payload.email,
          password: payload.password,
          ...(payload.disability_organization && {
            disability_organization: payload.disability_organization,
          }),
        }),
      );
      yield put(auth.signUpSuccess({status: 'success', data: data}));
    } else {
      yield put(auth.signUpFailure({status: 'failed', data: data}));
    }
  } catch (e) {
    let error;

    try {
      error = JSON.parse(e);
    } catch (e) {
      error = null;
    }

    yield put(auth.signUpFailure({status: 'failed', desc: error ? error : {}}));
  }
}

export function* signUpWatcher() {
  yield takeLatest(auth.signUp.toString(), signUpWorker);
}

export function* socialSignUpWorker({payload}) {
  try {
    yield call(socialAuth, payload);
  } catch (e) {
    //to change
    yield put(auth.socialSignUpFailure({error: true}));
  }
}

export function* socialSignUpWatcher() {
  yield takeLatest(auth.socialSignUp.toString(), socialSignUpWorker);
}
