import {
  handleBody,
  handleExceptions,
  POST,
  GET,
  // createArrayEndpoint
} from '../utils/api';
import {getConfig} from '../config';
import {webAuth} from '../index';

const config = getConfig();

export const getToken = (data: {
  username: string;
  password: string;
}): Promise<void | Response | object | null> => {
  const endpoint = `https://${config.domain}/oauth/token`;
  const options = {
    body: {
      realm: 'Username-Password-Authentication',
      audience: config.audience,
      client_id: config.clientId,
      scope: 'openid profile email offline_access',
      grant_type: config.grant_type,
      username: data.username,
      password: data.password,
    },
    external: true,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

//refresh token rotation
export const refreshToken = (): Promise<void | Response | object | null> => {
  const endpoint = `https://${config.domain}/oauth/token`;
  const options = {
    body: {
      grant_type: 'refresh_token',
      client_id: config.clientId,
      refresh_token: localStorage.getItem('rT'),
    },
    external: true,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const socialAuth = (data: {connection: string; action: string}): any => {
  webAuth.authorize({
    connection: data.connection,
    responseType: 'token',
    redirect_uri: `${window.location.origin}/social_login_redir?login=${data.connection}&r=${window.location.href}`,
    state: `${data.connection} ${data.action}`,
  });
};

//Alternative Logout
//#region
// export const signOut = (): Promise<void | Response | object | null> => {
//   const params = {
//     returnTo: window.location.pathname.slice(1, 8) === 'account'
// ? `${window.location.origin}/r?r=home`
// : `${window.location.origin}/r?r=${window.location.pathname.slice(1)}`
//     client_id: config.clientId,
//   };

//   const endpoint = createArrayEndpoint(`https://${config.domain}/v2/logout`, params);

//   return GET(endpoint, {external: true}).then((response) => {
//     if (response.status >= 400) {
//       return handleExceptions(response);
//     }

//     return handleBody(response);
//   });
// };
//#endregion
//

export const signOut = (): any => {
  webAuth.logout({
    returnTo:
      window.location.pathname.slice(1, 8) === 'account'
        ? `${window.location.origin}/r?r=home`
        : `${window.location.origin}/r?r=${window.location.href}`,
    clientID: config.clientId,
  });
};

export const getUserData = (): Promise<void | Response | object | null> => {
  const endpoint = `https://${config.domain}/userinfo`;

  return GET(endpoint, {external: true, token: true}).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const signUp = (data: {
  name: string;
  surname: string;
  email: string;
  password: string;
  disability_organization?: string;
}): Promise<void | Response | object | null> => {
  const endpoint = `https://${config.domain}/dbconnections/signup`;
  const options = {
    body: {
      audience: config.audience,
      client_id: config.clientId,
      connection: 'Username-Password-Authentication',
      email: data.email,
      password: data.password,
      response_type: 'token id_token',
      user_metadata: {
        given_name: data.name,
        family_name: data.surname,
        disability_organization: data.disability_organization,
      },
    },
    external: true,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
