import {handleActions, combineActions} from 'redux-actions';
import {getTravelIdeasSuccess, getTravelIdeasFailure} from '../../actions/travelIdeas';
import {ITravelIdea} from '../../../interfaces/travelIdeas';

export interface travelIdeasStateType {
  error?: string | object;
  errors?: null | Array<string>;
  data?: Array<ITravelIdea>;
}

const initialState = {};

const handlerMap = new Map([
  [
    combineActions(getTravelIdeasSuccess, getTravelIdeasFailure),
    (state, {payload}) => {
      return {
        ...state,
        ...payload,
      };
    },
  ],
]);

export default handleActions(handlerMap, initialState);
