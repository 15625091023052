import {handleActions, combineActions} from 'redux-actions';
import * as auth from '../../actions/auth';

export interface authStateType {
  signUpState: {
    status?: string;
    data?: {
      _id: string;
      email_verified: boolean;
      email: string;
      user_metadata: {
        given_name: string;
        family_name: string;
        disability_organization?: string;
      };
    };
    desc?: {
      error?: string;
      name: string;
      code: string;
      description: string;
      statusCode: number;
    };
  };
  authState: {
    error: boolean;
    isAuth: boolean;
    data: {
      error?: string;
      error_description?: string;
      access_token?: string;
      refresh_token?: string;
      id_token?: string;
      scope?: string;
      expires_in?: number;
      token_type?: string;
    };
  };
  user: {
    error: boolean;
    data: {
      given_name: string;
      family_name: string;
      nickname: string;
      name: string;
      picture: string;
      locale: string;
      updated_at: string;
      email: string;
      email_verified: boolean;
      sub: string;
    };
  };
}

export const initialState: authStateType = {
  signUpState: {},
  authState: {error: false, isAuth: localStorage.getItem('accessToken') ? true : false, data: {}},
  user: {
    error: false,
    data: {
      given_name: localStorage.getItem('Name') || '',
      family_name: '',
      nickname: '',
      name: '',
      picture: '',
      locale: '',
      updated_at: '',
      email: localStorage.getItem('email') || '',
      email_verified: false,
      sub: '',
    },
  },
};

const handlerMap = new Map([
  [
    combineActions(
      auth.signInSuccess,
      auth.socialSignInSuccess,
      auth.signOutFailure,
      auth.updateTokenSuccess,
    ),
    (state, {payload}) => {
      return {
        ...state,
        authState: {...payload, isAuth: true},
      };
    },
  ],
  [
    combineActions(auth.getUserDataFailure, auth.getUserDataSuccess),
    (state, {payload}) => {
      return {
        ...state,
        user: payload,
      };
    },
  ],
  [
    combineActions(auth.signInFailure, auth.socialSignInFailure),
    (state, {payload}) => {
      return {
        ...state,
        user: {},
        authState: {...payload, isAuth: false},
      };
    },
  ],
  [
    combineActions(auth.signOutSuccess, auth.updateTokenFailure),
    () => {
      return {
        ...initialState,
      };
    },
  ],
  [
    combineActions(
      auth.signUpFailure,
      auth.signUpSuccess,
      auth.socialSignUpFailure,
      auth.socialSignUpSuccess,
    ),
    (state, {payload}) => {
      return {
        ...state,
        signUpState: payload,
      };
    },
  ],
]);

export default handleActions(handlerMap, initialState);
