import {createAction} from 'redux-actions';

const PREFIX = 'FORUM_';

export const getDiscoursePayload = createAction(`${PREFIX}GET_DISCOURSE_PAYLOAD`);
export const getDiscoursePayloadSuccess = createAction(
  `${PREFIX}GET_DISCOURSE_PAYLOAD_SUCCESS`,
  (payload) => payload,
);
export const getDiscoursePayloadFailure = createAction(
  `${PREFIX}GET_DISCOURSE_PAYLOAD_FAILURE`,
  (payload) => payload,
);
