import {handleBody, handleExceptions, GET, POST} from '../utils/api';
import {createArrayEndpoint} from '../utils/api';
import {getConfig} from '../config';

const config = getConfig();

export const getIsUser = (email: string) => {
  const endpoint = createArrayEndpoint('user/isUser', {email: email});

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const changePassword = (email: string): Promise<void | Response | object | null> => {
  const endpoint = `https://${config.domain}/dbconnections/change_password`;
  const options = {
    body: {
      client_id: config.clientId,
      email: email,
      connection: 'Username-Password-Authentication',
    },
    external: true,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const getClientIP = () => {
  //1 reserve API:
  // const endpoint = 'https://api.ipify.org/?format=json';
  //2 reserve API:
  // const endpoint = ' https://geolocation-db.com/json/';

  const endpoint = 'https://ipapi.co/json/';

  return GET(endpoint, {external: true}).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
