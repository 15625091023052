import {takeLatest, call, put} from 'redux-saga/effects';
import * as popularDestinations from '../actions/popularDestinations';
import {getPopularDestinations} from '../../api/popularDestinations';

export function* getPopularDestinationsWorker() {
  try {
    const data = yield call(getPopularDestinations);

    yield put(
      popularDestinations.getPopularDestinationsSuccess({error: false, destinations: data}),
    );
  } catch (e) {
    yield put(popularDestinations.getPopularDestinationsFailure({error: true, destinations: []}));
  }
}

export function* getPopularDestinationsWatcher() {
  yield takeLatest(
    popularDestinations.getPopularDestinations.toString(),
    getPopularDestinationsWorker,
  );
}
