import {takeLatest, call, put} from 'redux-saga/effects';
import * as contact from '../actions/contact';
import {sendUserMessage} from '../../api/contact';

export function* sendUserMessageWorker({payload}) {
  try {
    const data = yield call(sendUserMessage, payload);

    if (data.success) {
      yield put(contact.sendUserMessageSuccess({error: false, data: data}));
    } else {
      yield put(contact.sendUserMessageFailure({error: true, data: {}}));
    }
  } catch (e) {
    yield put(contact.sendUserMessageFailure({error: true, data: {}}));
  }
}

export function* sendUserMessageWatcher() {
  yield takeLatest(contact.sendUserMessage.toString(), sendUserMessageWorker);
}
