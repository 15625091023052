import {handleActions, combineActions} from 'redux-actions';
import * as deals from '../../actions/deals';
import {
  IMembershipStatusState,
  IOrganization,
  IGivebackParticipantRes,
} from '../../../interfaces/deals';

export interface dealsState {
  membershipStatus: IMembershipStatusState;
  joinStatus: IMembershipStatusState;
  disabilityOrganization: {error: boolean; data: IOrganization};
  oneTimeDiscount: {error: boolean; data: string | number};
  referralDiscount: {error: boolean; data: string | number};
  givebackParticipant: {error: boolean; data: IGivebackParticipantRes};
  userOffers: {error: boolean; data: string};
}

const offer = localStorage.getItem('UserOffers')
  ? JSON.parse(localStorage.getItem('UserOffers')).offerType
  : '';

const initialState = {
  membershipStatus: {
    error: false,
    data: {status: localStorage.getItem('clubMember') || 'fail'},
  },
  joinStatus: {error: false, data: {}},
  disabilityOrganization: {error: false, data: {}},
  oneTimeDiscount: {error: false, data: ''},
  referralDiscount: {error: false, data: ''},
  givebackParticipant: {error: false, data: ''},
  userOffers: {error: false, data: offer},
};

const handlerMap = new Map([
  [
    combineActions(deals.getMembershipSuccess, deals.getMembershipFailure),
    (state, {payload}) => {
      return {
        ...state,
        membershipStatus: {...payload},
      };
    },
  ],
  [
    combineActions(deals.joinDealsSuccess, deals.joinDealsFailure),
    (state, {payload}) => {
      return {
        ...state,
        joinStatus: {...payload},
      };
    },
  ],
  [
    combineActions(deals.getOrganizationNameSuccess, deals.getOrganizationNameFailure),
    (state, {payload}) => {
      return {
        ...state,
        disabilityOrganization: {...payload},
      };
    },
  ],
  [
    combineActions(deals.activateOneTimeDiscountSuccess, deals.activateOneTimeDiscountFailure),
    (state, {payload}) => {
      return {
        ...state,
        oneTimeDiscount: {...payload},
      };
    },
  ],
  [
    combineActions(deals.activateReferralDiscountSuccess, deals.activateReferralDiscountFailure),
    (state, {payload}) => {
      return {
        ...state,
        referralDiscount: {...payload},
      };
    },
  ],
  [
    combineActions(
      deals.activateGivebackParticipantSuccess,
      deals.activateGivebackParticipantFailure,
    ),
    (state, {payload}) => {
      return {
        ...state,
        givebackParticipant: {...payload},
      };
    },
  ],
  [
    combineActions(deals.getUserOffersSuccess, deals.getUserOffersFailure),
    (state, {payload}) => {
      return {
        ...state,
        userOffers: {...payload},
      };
    },
  ],
]);

export default handleActions(handlerMap, initialState);
