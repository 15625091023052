import {createAction} from 'redux-actions';

export const getFavorites = createAction('GET_FAVORITES');
export const getFavoritesSuccess = createAction('GET_FAVORITES_SUCCESS', (payload) => payload);
export const getFavoritesFailure = createAction('GET_FAVORITES_FAILURE', (payload) => payload);

export const addFavorite = createAction('ADD_FAVORITE');
export const addFavoriteSuccess = createAction('ADD_FAVORITE_SUCCESS', (payload) => payload);
export const addFavoriteFailure = createAction('ADD_FAVORITE_FAILURE', (payload) => payload);

export const removeFavorite = createAction('REMOVE_FAVORITE');
export const removeFavoriteSuccess = createAction('REMOVE_FAVORITE_SUCCESS', (payload) => payload);
export const removeFavoriteFailure = createAction('REMOVE_FAVORITE_FAILURE', (payload) => payload);
