import {takeLatest, call, put} from 'redux-saga/effects';
import * as account from '../actions/account';
import * as apiAccount from '../../api/account';

//Bio
export function* getBioWorker() {
  try {
    const data = yield call(apiAccount.getBio);

    const formatedData = JSON.parse(data.substring(5));

    if (formatedData) {
      yield put(account.getUserBioSuccess({error: false, data: formatedData}));
    } else {
      yield put(account.getUserBioFailure({error: true, data: {}}));
    }
  } catch (e) {
    yield put(account.getUserBioFailure({error: true, data: {}}));
  }
}

export function* getBioWatcher() {
  yield takeLatest(account.getUserBio.toString(), getBioWorker);
}

export function* updateBioWorker({payload}) {
  try {
    const data = yield call(apiAccount.updateBio, payload);
    const formatedData = JSON.parse(data.substring(5));

    if (formatedData && formatedData.status === 'success') {
      yield put(account.updateUserBioSuccess(formatedData));
      yield put(account.getUserBio());
    } else {
      yield put(account.updateUserBioFailure({error: true}));
    }
  } catch (e) {
    yield put(account.updateUserBioFailure({error: true}));
  }
}

export function* updateBioWatcher() {
  yield takeLatest(account.updateUserBio.toString(), updateBioWorker);
}

//Location
export function* getLocationWorker() {
  try {
    const data = yield call(apiAccount.getLocation);
    const formatedData = JSON.parse(data.substring(5));

    if (formatedData) {
      yield put(account.getUserLocationSuccess({error: false, data: formatedData}));
    } else {
      yield put(account.getUserLocationFailure({error: true, data: {}}));
    }
  } catch (e) {
    yield put(account.getUserLocationFailure({error: true, data: {}}));
  }
}

export function* getLocationWatcher() {
  yield takeLatest(account.getUserLocation.toString(), getLocationWorker);
}

export function* saveLocationWorker({payload}) {
  try {
    const data = yield call(apiAccount.saveLocation, payload);
    const formatedData = JSON.parse(data.substring(5));

    yield put(account.updateUserLocationSuccess(formatedData));
    yield put(account.getUserLocation());
  } catch (e) {
    yield put(account.updateUserLocationFailure({error: true}));
  }
}

export function* saveLocationWatcher() {
  yield takeLatest(account.updateUserLocation.toString(), saveLocationWorker);
}

//Preferences
export function* getPreferencesWorker() {
  try {
    const data = yield call(apiAccount.getPreferences);
    const formatedData = JSON.parse(data.substring(5));

    if (formatedData) {
      yield put(account.getUserPreferencesSuccess({error: false, data: formatedData}));
    } else {
      yield put(account.getUserPreferencesFailure({error: true, data: {}}));
    }
  } catch (e) {
    yield put(account.getUserPreferencesFailure({error: true, data: {}}));
  }
}

export function* getPreferencesWatcher() {
  yield takeLatest(account.getUserPreferences.toString(), getPreferencesWorker);
}

export function* savePreferenceWorker({payload}) {
  try {
    const data = yield call(apiAccount.savePreference, payload);
    const formatedData = JSON.parse(data.substring(5));

    yield put(account.updateUserPreferencesSuccess(formatedData));
  } catch (e) {
    yield put(account.updateUserPreferencesFailure({error: true}));
  }
}

export function* savePreferenceWatcher() {
  yield takeLatest(account.updateUserPreferences.toString(), savePreferenceWorker);
}

export function* deletePreferenceWorker({payload}) {
  try {
    const data = yield call(apiAccount.deletePreference, payload);
    const formatedData = JSON.parse(data.substring(5));

    yield put(account.deleteUserPreferencesSuccess(formatedData));
  } catch (e) {
    yield put(account.deleteUserPreferencesFailure({error: true}));
  }
}

export function* deletePreferenceWatcher() {
  yield takeLatest(account.deleteUserPreferences.toString(), deletePreferenceWorker);
}
