import React, {useEffect, useState} from 'react';
import ToastContainer from 'react-bootstrap/ToastContainer';
import {useLocation} from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import ToastMessage from '../components/Popups/Toast';

class ErrorBoundaryInner extends React.Component<
  any,
  {error: string; eventId: string; errorInfo: string; hasError: boolean}
> {
  state = {
    error: '',
    eventId: '',
    errorInfo: '',
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    return {hasError: true, error};
  }

  componentDidUpdate(prevProps) {
    if (!this.props.hasError && prevProps.hasError) {
      this.setState({hasError: false});
    }
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.log({error, errorInfo});

    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);

      this.setState({eventId, errorInfo});
      this.props.setHasError(true);
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <div className='ac-error-container'></div>
          <ToastContainer className='ac-toasts-container'>
            <ToastMessage type='error' />
          </ToastContainer>
        </>
      );
    }
    return this.props.children;
  }
}

const ErrorBoundary = ({children}) => {
  const [hasError, setHasError] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (hasError) {
      setHasError(false);
    }
  }, [location.key]);

  return (
    <ErrorBoundaryInner hasError={hasError} setHasError={setHasError}>
      {children}
    </ErrorBoundaryInner>
  );
};

export default ErrorBoundary;
