import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import validator from 'validator';
import {Row, Col, OverlayTrigger, Tooltip} from 'react-bootstrap';
import ProfilePhoto from './PhotoUpload';

import Image from '../../../../../components/UI/Image';
import edit from '../../../../../../assets/edit.svg';

import {getUser} from '../../../../../selectors/auth';
import {getUserBioState, getUserLocationState} from '../../../../../selectors/account';
import {grayScaleModeState} from '../../../../../selectors/globalConfig';
import {updateUserLocation, updateUserBio} from '../../../../../redux/actions/account';

interface Location {
  city: string;
  state: string;
  country: string;
}
interface UserName {
  givenName: string;
  familyName: string;
}
interface ErrorState {
  name: boolean;
  location: boolean;
  phone: boolean;
}

interface IProps {
  setExternalError: (value: boolean) => void;
  setExternalErrorType: (value: string) => void;
}

const Main = ({setExternalError, setExternalErrorType}: IProps) => {
  const [name, setName] = useState<UserName>({givenName: '', familyName: ''});
  const [editName, setEditName] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [editPhoneNumber, setEditPhoneNumber] = useState<boolean>(false);
  const [editLocation, setEditLocation] = useState<boolean>(false);
  const [location, setLocation] = useState<Location>({city: '', state: '', country: ''});
  const [error, setError] = useState<ErrorState>({
    name: false,
    location: false,
    phone: false,
  });

  const dispatch = useDispatch();
  const greyMode = useSelector(grayScaleModeState);
  const userName = useSelector(getUser) && useSelector(getUser).data.given_name;
  const userLocation = useSelector(getUserLocationState);
  const userBio = useSelector(getUserBioState);

  useEffect(() => {
    if (userBio.data.data) {
      const bio = userBio.data.data;
      setName({
        givenName: bio.givenName || '',
        familyName: bio.familyName || '',
      });
      setPhoneNumber(bio.phone || '');
    } else if (userName) {
      setName({...name, givenName: userName ? userName : ''});
    }
  }, [userBio]);

  useEffect(() => {
    if (userLocation.data.data && userLocation.data.status === 'success') {
      const location = userLocation.data.data;
      setLocation({
        city: location.city ? location.city : '',
        state: location.stateProv ? location.stateProv : '',
        country: location.country ? location.country : '',
      });
    }
  }, [userLocation]);

  const handleEdit = (e): void => {
    const bio = userBio.data.data;

    if (e.currentTarget.id === 'userName') setEditName(true);
    if (e.currentTarget.id === 'cancelEditName') {
      setName({
        givenName: bio && bio.givenName ? bio.givenName : '',
        familyName: bio && bio.familyName ? bio.familyName : '',
      });
      setEditName(false);
    }

    if (e.currentTarget.id === 'phone-number') setEditPhoneNumber(true);
    if (e.currentTarget.id === 'cancelEdit-phone-number') {
      setPhoneNumber(bio && bio.phone ? bio.phone : '');
      setEditPhoneNumber(false);
    }

    if (e.currentTarget.id === 'location') setEditLocation(true);
    if (e.currentTarget.id === 'cancelEditLocation') {
      const location = userLocation.data.data;
      setLocation({
        city: location && location.city ? location.city : '',
        state: location && location.stateProv ? location.stateProv : '',
        country: location && location.country ? location.country : '',
      });
      setEditLocation(false);
    }
  };

  const handleChange = (e): void => {
    setError({name: false, location: false, phone: false});

    if (e.target.id === 'userNameInput') setName({...name, givenName: e.target.value});
    if (e.target.id === 'userFamilyNameInput') setName({...name, familyName: e.target.value});
    if (e.target.id === 'phoneNumberInput') setPhoneNumber(e.target.value);
    if (e.target.id === 'locationCity') setLocation({...location, city: e.target.value});
    if (e.target.id === 'locationState') setLocation({...location, state: e.target.value});
    if (e.target.id === 'locationCountry') setLocation({...location, country: e.target.value});
  };

  const validation = (
    userName: UserName,
    userLocation: Location,
    userPhone: string,
  ): ErrorState => {
    const isError = {
      name: false,
      location: false,
      phone: false,
    };

    const nameRegEx = /^[a-zA-Z\s']*$/;
    const locationRegEx = /^[a-zA-Z]*$/;
    const phoneRegEx = /^[0-9+]*$/;

    //name
    if (
      (userName.givenName &&
        userName.givenName.length > 0 &&
        !userName.givenName.match(nameRegEx)) ||
      (userName.givenName && userName.givenName.length > 25) ||
      (userName.familyName &&
        userName.familyName.length > 0 &&
        !userName.familyName.match(nameRegEx)) ||
      (userName.familyName && userName.familyName.length > 25)
    ) {
      isError.name = true;
    }

    //location
    if (
      (userLocation.city.length > 0 &&
        userLocation.city.length <= 2 &&
        !userLocation.city.match(locationRegEx)) ||
      (userLocation.state.length > 0 &&
        userLocation.state.length <= 2 &&
        !userLocation.state.match(locationRegEx)) ||
      (userLocation.country.length > 0 &&
        userLocation.country.length <= 2 &&
        !userLocation.country.match(locationRegEx)) ||
      userLocation.city.length > 25 ||
      userLocation.state.length > 25 ||
      userLocation.country.length > 25
    ) {
      isError.location = true;
    }

    //phone
    if (
      (userPhone.length > 0 && !userPhone.match(phoneRegEx)) ||
      userPhone.length > 15 ||
      !validator.isMobilePhone(userPhone)
    ) {
      isError.phone = true;
    }

    setError(isError);
    return isError;
  };

  const handleSave = (e): void => {
    const isError = validation(name, location, phoneNumber);

    if (e.target.id === 'saveName' && !isError.name) {
      dispatch(
        updateUserBio({
          givenName: name.givenName.length > 0 ? name.givenName : null,
          familyName: name.familyName.length > 0 ? name.familyName : null,
          fieldType: 'name',
        }),
      );
      setEditName(false);
    }
    if (e.target.id === 'saveLocation' && !isError.location) {
      dispatch(
        updateUserLocation({
          city: location.city,
          stateProv: location.state,
          country: location.country,
        }),
      );
      setEditLocation(false);
    }
    if (e.target.id === 'save-phone-number' && !isError.phone) {
      dispatch(
        updateUserBio({
          phone: phoneNumber.length > 0 ? phoneNumber : null,
          fieldType: 'phone',
        }),
      );
      setEditPhoneNumber(false);
    }
  };

  return (
    <section className={greyMode === 'true' ? 'ac-greyMode' : ''}>
      <Row>
        <Col xs={7} sm={8}>
          <div className='ac-account__block-wrapper'>
            <div className='ac-account__label-block'>
              <p>Name</p>
              <OverlayTrigger
                placement='top'
                overlay={<Tooltip className='ac-tooltip'>Click to edit</Tooltip>}
              >
                <button onClick={handleEdit} title='edit name' id='userName'>
                  <img src={edit} alt='' width='17' height='17' />
                </button>
              </OverlayTrigger>
            </div>
            {editName ? (
              <div className='ac-account__input-block ac-account__input-block--margin'>
                <div className='input-effect'>
                  <input
                    type='text'
                    id='userNameInput'
                    value={name.givenName}
                    onChange={handleChange}
                    autoFocus
                    placeholder=''
                  />
                  <label htmlFor='userNameInput'>
                    {name.givenName.length > 0 ? '' : 'given name'}
                  </label>
                  <span className='focus-border'></span>
                </div>
                <div className='input-effect'>
                  <input
                    type='text'
                    id='userFamilyNameInput'
                    value={name.familyName}
                    onChange={handleChange}
                    autoFocus
                    placeholder=''
                  />
                  <label htmlFor='userFamilyNameInput'>
                    {name.familyName.length > 0 ? '' : 'family name'}
                  </label>
                  <span className='focus-border'></span>
                </div>
                {error.name && <p className='input-error'>Please enter a valid name</p>}
                <div className='buttons-block'>
                  <button onClick={handleSave} id='saveName'>
                    save
                  </button>
                  <button onClick={handleEdit} id='cancelEditName'>
                    cancel
                  </button>
                </div>
              </div>
            ) : (
              <p>
                {name.givenName} {name.familyName}
              </p>
            )}
          </div>
          <div className='ac-account__block-wrapper'>
            <div className='ac-account__label-block'>
              <p>Location</p>
              <OverlayTrigger
                placement='top'
                overlay={<Tooltip className='ac-tooltip'>Click to edit</Tooltip>}
              >
                <button onClick={handleEdit} title='edit location' id='location'>
                  <Image
                    attr={{
                      src: edit,
                      alt: 'edit',
                      width: '17',
                      height: '17',
                    }}
                  />
                </button>
              </OverlayTrigger>
            </div>
            {editLocation ? (
              <div className='ac-account__input-block ac-account__input-block--margin'>
                <div className='input-effect'>
                  <input
                    type='text'
                    id='locationCity'
                    value={location.city}
                    onChange={handleChange}
                    placeholder=''
                  />
                  <label htmlFor='locationCity'>{location.city.length > 0 ? '' : 'city'}</label>
                  <span className='focus-border'></span>
                </div>
                <div className='input-effect'>
                  <input
                    type='text'
                    id='locationState'
                    value={location.state}
                    onChange={handleChange}
                    placeholder=''
                  />
                  <label htmlFor='locationState'>{location.state.length > 0 ? '' : 'state'}</label>
                  <span className='focus-border'></span>
                </div>
                <div className='input-effect'>
                  <input
                    type='text'
                    id='locationCountry'
                    value={location.country}
                    onChange={handleChange}
                    placeholder=''
                  />
                  <label htmlFor='locationCountry'>
                    {location.country.length > 0 ? '' : 'country'}
                  </label>
                  <span className='focus-border'></span>
                </div>
                {error.location && <p className='input-error'>Please enter a valid location</p>}
                <div className='buttons-block'>
                  <button onClick={handleSave} id='saveLocation'>
                    save
                  </button>
                  <button onClick={handleEdit} id='cancelEditLocation'>
                    cancel
                  </button>
                </div>
              </div>
            ) : (
              <p>
                {location.city.length === 0 &&
                location.state.length === 0 &&
                location.country.length === 0 ? (
                  'No location specified. Click edit to add your city, state, and country.'
                ) : (
                  <>
                    {location.city.length > 0 && <span>{location.city}</span>}
                    {location.state.length > 0 && <span>, {location.state}</span>}
                    {location.country.length > 0 && (
                      <span>
                        {location.city.length > 0 || (location.state.length > 0 && ',')}{' '}
                        {location.country}
                      </span>
                    )}
                  </>
                )}
              </p>
            )}
          </div>
          <div className='ac-account__block-wrapper'>
            <div className='ac-account__label-block'>
              <p>Phone number </p>
              <OverlayTrigger
                placement='top'
                overlay={<Tooltip className='ac-tooltip'>Click to edit</Tooltip>}
              >
                <button onClick={handleEdit} title='edit phone number' id='phone-number'>
                  <Image
                    attr={{
                      src: edit,
                      alt: 'edit',
                      width: '17',
                      height: '17',
                    }}
                  />
                </button>
              </OverlayTrigger>
            </div>
            {editPhoneNumber ? (
              <div className='ac-account__input-block'>
                <div className='input-effect'>
                  <input
                    type='tel'
                    id='phoneNumberInput'
                    value={phoneNumber}
                    onChange={handleChange}
                    autoFocus
                    placeholder=''
                    title='phone number'
                  />
                  <span className='focus-border'></span>
                </div>
                {error.phone && <p className='input-error'>Please enter a valid phone number</p>}
                <div className='buttons-block'>
                  <button onClick={handleSave} id='save-phone-number'>
                    save
                  </button>
                  <button onClick={handleEdit} id='cancelEdit-phone-number'>
                    cancel
                  </button>
                </div>
              </div>
            ) : (
              <p>{phoneNumber}</p>
            )}
          </div>
        </Col>
        <Col xs={5} sm={4}>
          <ProfilePhoto
            setExternalError={setExternalError}
            setExternalErrorType={setExternalErrorType}
          />
        </Col>
      </Row>
    </section>
  );
};

export default Main;
