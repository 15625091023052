import {handleActions, combineActions} from 'redux-actions';
import * as cityGuides from '../../actions/cityGuides';
import {
  IArticle,
  IArticlesData,
  // , ICity
} from '../../../interfaces/cityGuides';

export interface cityGuidesStateType {
  // cities: {error: boolean; data: Array<ICity>};
  articles: {error: boolean; data: Array<IArticle>};
  articlesData: {error: boolean; data: IArticlesData};
}

const initialState = {
  // cities: {error: false, data: null},
  articles: {},
  articlesData: {error: false, data: null},
};

const handlerMap = new Map([
  // [
  //   combineActions(cityGuides.getCitiesSuccess, cityGuides.getCitiesFailure),
  //   (state, {payload}) => {
  //     return {
  //       ...state,
  //       cities: payload,
  //     };
  //   },
  // ],
  [
    combineActions(cityGuides.getCityArticlesSuccess, cityGuides.getCityArticlesFailure),
    (state, {payload}) => {
      return {
        ...state,
        articles: payload,
      };
    },
  ],
  [
    combineActions(cityGuides.getArticlesSuccess, cityGuides.getArticlesFailure),
    (state, {payload}) => {
      return {
        ...state,
        articlesData: payload,
      };
    },
  ],
]);

export default handleActions(handlerMap, initialState);
