import {handleActions, combineActions} from 'redux-actions';
import {
  getPopularDestinationsSuccess,
  getPopularDestinationsFailure,
} from '../../actions/popularDestinations';
import {PopularDestination} from '../../../interfaces/popularDestinations';

export interface popularDestinationsStateType {
  error: boolean;
  destinations: Array<PopularDestination>;
}

const initialState = {};

const handlerMap = new Map([
  [
    combineActions(getPopularDestinationsSuccess, getPopularDestinationsFailure),
    (state, {payload}) => {
      return {
        ...state,
        ...payload,
      };
    },
  ],
]);

export default handleActions(handlerMap, initialState);
