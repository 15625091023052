import {createAction} from 'redux-actions';

const PREFIX = 'CHECKOUT_';

export const getBookingContractData = createAction(`${PREFIX}GET_BOOKING_CONTRACT_DATA`);
export const getBookingContractDataSuccess = createAction(
  `${PREFIX}GET_BOOKING_CONTRACT_DATA_SUCCESS`,
  (payload) => payload,
);
export const getBookingContractDataFailure = createAction(
  `${PREFIX}GET_BOOKING_CONTRACT_DATA_FAILURE`,
  (payload) => payload,
);

export const getRegularRate = createAction(`${PREFIX}GET_REGULAR_RATE`);
export const getRegularRateSuccess = createAction(
  `${PREFIX}GET_REGULAR_RATE_SUCCESS`,
  (payload) => payload,
);
export const getRegularRateFailure = createAction(
  `${PREFIX}GET_REGULAR_RATE_FAILURE`,
  (payload) => payload,
);

export const bookHotel = createAction(`${PREFIX}BOOK_HOTEL`);
export const bookHotelSuccess = createAction(`${PREFIX}BOOK_HOTEL_SUCCESS`, (payload) => payload);
export const bookHotelFailure = createAction(`${PREFIX}BOOK_HOTEL_FAILURE`, (payload) => payload);
