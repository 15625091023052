import {takeLatest, call, put} from 'redux-saga/effects';
import * as search from '../actions/typeAheadSearch';
import {getSuggestions, getArticles, getDestinations, getPosts} from '../../api/typeAheadSearch';

export function* getSuggestionsWorker() {
  try {
    const res = yield call(getSuggestions);

    if (res) {
      yield put(search.getSuggestionsSuccess({error: false, data: res.data}));
    }
  } catch (e) {
    yield put(search.getSuggestionsFailure({error: true, data: []}));
  }
}

export function* getSuggestionsWatcher() {
  yield takeLatest(search.getSuggestions.toString(), getSuggestionsWorker);
}

export function* getCitiesWorker({payload}) {
  try {
    const data = yield call(getDestinations, payload);

    if (data.value) {
      yield put(search.getCitiesSuccess({error: false, data: data.value}));
    }
  } catch (e) {
    yield put(search.getCitiesFailure({error: true, data: []}));
  }
}

export function* getCitiesWatcher() {
  yield takeLatest(search.getCities.toString(), getCitiesWorker);
}

export function* getAirportsWorker({payload}) {
  try {
    const data = yield call(getDestinations, payload);

    if (data.value) {
      yield put(search.getAirportsSuccess({error: false, data: data.value}));
    }
  } catch (e) {
    yield put(search.getAirportsFailure({error: true, data: []}));
  }
}

export function* getAirportsWatcher() {
  yield takeLatest(search.getAirports.toString(), getAirportsWorker);
}

export function* getHotelsWorker({payload}) {
  try {
    const data = yield call(getDestinations, payload);

    if (data.value) {
      yield put(search.getHotelsSuccess({error: false, data: data.value}));
    }
  } catch (e) {
    yield put(search.getHotelsFailure({error: true, data: []}));
  }
}

export function* getHotelsWatcher() {
  yield takeLatest(search.getHotels.toString(), getHotelsWorker);
}

export function* getPostsWorker({payload}) {
  try {
    const data = yield call(getPosts, payload);

    if (data) {
      yield put(search.getPostsSuccess({error: false, data: data}));
    }
  } catch (e) {
    yield put(search.getPostsFailure({error: true, data: []}));
  }
}

export function* getPostsWatcher() {
  yield takeLatest(search.getPosts.toString(), getPostsWorker);
}

export function* getArticlesWorker({payload}) {
  try {
    const data = yield call(getArticles, payload);

    if (data) {
      yield put(search.getArticlesSuccess({error: false, data: data}));
    }
  } catch (e) {
    yield put(search.getArticlesFailure({error: true, data: []}));
  }
}

export function* getArticlesWatcher() {
  yield takeLatest(search.getArticles.toString(), getArticlesWorker);
}
