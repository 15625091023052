import {handleActions, combineActions} from 'redux-actions';
import * as posts from '../../actions/latestPosts';
import {ILatestPost} from '../../../interfaces/latestPost';

export interface latestPostsStateType {
  latest_posts: {error: boolean; data: Array<ILatestPost>};
  airTravelPosts: {error: boolean; data: Array<ILatestPost>};
}

const initialState = {
  latest_posts: {error: false, data: []},
  airTravelPosts: {error: false, data: []},
};

const handlerMap = new Map([
  [
    combineActions(posts.getLatestPostsSuccess, posts.getLatestPostsFailure),
    (state, {payload}) => {
      return {
        ...state,
        latest_posts: payload,
      };
    },
  ],
  [
    combineActions(posts.getAirTravelPostsSuccess, posts.getAirTravelPostsFailure),
    (state, {payload}) => {
      return {
        ...state,
        airTravelPosts: payload,
      };
    },
  ],
]);

export default handleActions(handlerMap, initialState);
