import React, {useState} from 'react';
import Toast from 'react-bootstrap/Toast';
import {toastMessages} from '../../../constants/messages';
import './styles.less';

interface IProps {
  setError?: (value: boolean) => void;
  type: string;
}

const ToastMessage = ({setError, type}: IProps) => {
  const [show, setShow] = useState<boolean>(true);

  const handleClose = () => {
    setShow(false);
    if (setError) setError(false);
  };

  return (
    <Toast className='ac-toast' onClose={handleClose} show={show} delay={5000} autohide>
      <Toast.Header className='ac-toast__header'>
        <strong>Warning</strong>
      </Toast.Header>
      <Toast.Body>
        <p>{type.length > 0 ? toastMessages[type] : toastMessages.error}</p>
      </Toast.Body>
    </Toast>
  );
};

export default ToastMessage;
