import {handleBody, handleExceptions, POST} from '../utils/api';

export const sendUserMessage = (data: {
  Name: string;
  Email: string;
  Message: string;
  IsTest?: boolean;
}) => {
  const endpoint = 'contact';
  const options = {
    body: data,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
