import {handleBody, handleExceptions, GET, createArrayEndpoint} from '../utils/api';

// export const getCities = () => {
//   const endpoint = 'https://accessiblego.azureedge.net/data/popularDestinations.json';

//   return GET(endpoint, {external: true}).then((response) => {
//     if (response.status >= 400) {
//       return handleExceptions(response);
//     }

//     return handleBody(response);
//   });
// };

export const getCityArticle = (data: {city: string}) => {
  const endpoint = createArrayEndpoint(
    'https://accessiblego-admin.azurewebsites.net/Article/GetByCity',
    {...data},
  );

  return GET(endpoint, {external: true}).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const getArticles = (data: {title: string}) => {
  const endpoint = createArrayEndpoint(
    'https://accessiblego-admin.azurewebsites.net/Article/GetByTitle',
    {...data},
  );

  return GET(endpoint, {external: true}).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
