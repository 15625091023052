import React from 'react';
import {useSelector} from 'react-redux';
import {grayScaleModeState} from '../../../selectors/globalConfig';
import './styles.less';

interface IProps {
  mode?: string;
}

const Loader = ({mode}: IProps) => {
  const greyMode = useSelector(grayScaleModeState);

  return (
    <div
      className={`ac-loader ${greyMode === 'true' ? 'ac-greyMode' : ''} ${
        mode ? `ac-loader--${mode}` : ''
      }`}
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Loader;
