import {createAction} from 'redux-actions';

export const getPopularDestinations = createAction('GET_POPULAR_DESTINATIONS');
export const getPopularDestinationsSuccess = createAction(
  'GET_POPULAR_DESTINATIONS_SUCCESS',
  (payload) => payload,
);
export const getPopularDestinationsFailure = createAction(
  'GET_POPULAR_DESTINATIONS_FAILURE',
  (payload) => payload,
);
