import {takeLatest, call, put} from 'redux-saga/effects';
import {getIsUser, changePassword, getClientIP} from '../../api/settings';
import * as settings from '../actions/settings';

export function* getIsUserWorker({payload}) {
  try {
    const res = yield call(getIsUser, payload);

    if (res.status === 'success') {
      if (res.data) {
        yield put(settings.changePassword(payload));
      }
      yield put(settings.getIsUserSuccess(res));
    } else {
      yield put(settings.getIsUserFailure({error: true}));
    }
  } catch (e) {
    yield put(settings.getIsUserFailure({error: true}));
  }
}

export function* getIsUserWatcher() {
  yield takeLatest(settings.getIsUser.toString(), getIsUserWorker);
}

export function* changePasswordWorker({payload}) {
  try {
    const data = yield call(changePassword, payload);

    yield put(settings.changePasswordSuccess({error: false, data: data}));
  } catch (e) {
    yield put(settings.changePasswordFailure({error: true}));
  }
}

export function* changePasswordWatcher() {
  yield takeLatest(settings.changePassword.toString(), changePasswordWorker);
}

export function* getClientIPWorker() {
  try {
    const data = yield call(getClientIP);

    if (data.ip) {
      yield put(settings.getClientIPSuccess({error: false, data: data.ip}));
    } else {
      yield put(settings.getClientIPFailure({error: true, data: ''}));
    }
  } catch (e) {
    yield put(settings.getClientIPFailure({error: true, data: ''}));
  }
}

export function* getClientIPWatcher() {
  yield takeLatest(settings.getClientIP.toString(), getClientIPWorker);
}
