import React, {useEffect} from 'react';
import {useLocation} from 'react-router';

const ScrollToTop = (props) => {
  const location = useLocation();

  useEffect(() => {
    if (window.location.hash && window.location.hash.length > 0) return;

    window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>;
};

export default ScrollToTop;
