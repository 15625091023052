import {takeLatest, call, put} from 'redux-saga/effects';
import * as posts from '../actions/latestPosts';
import {getAirTravelPosts, getLatestPosts} from '../../api/latestPosts';

export function* getLatestPostsWorker() {
  try {
    const res = yield call(getLatestPosts);

    if (res.data) {
      yield put(posts.getLatestPostsSuccess({error: false, data: res.data}));
    } else {
      yield put(posts.getLatestPostsFailure({error: true, data: []}));
    }
  } catch (e) {
    // Sentry
    // eslint-disable-next-line no-console
    console.error('community forum', e);
    yield put(posts.getLatestPostsFailure({error: true, data: []}));
  }
}

export function* getAirTravelPostsWorker() {
  try {
    const res = yield call(getAirTravelPosts);

    if (res.data) {
      yield put(posts.getAirTravelPostsSuccess({error: false, data: res.data}));
    } else {
      yield put(posts.getAirTravelPostsFailure({error: true, data: []}));
    }
  } catch (e) {
    // Sentry
    // eslint-disable-next-line no-console
    console.error('community forum 2', e);
    yield put(posts.getAirTravelPostsFailure({error: true, data: []}));
  }
}

export function* getLatestPostsWatcher() {
  yield takeLatest(posts.getLatestPosts.toString(), getLatestPostsWorker);
  yield takeLatest(posts.getAirTravelPosts.toString(), getAirTravelPostsWorker);
}
