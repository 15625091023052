import {takeLatest, call, put} from 'redux-saga/effects';
import * as transfers from '../actions/transfers';
import {bookTransfer} from '../../api/transfers';

export function* bookTransferWorker({payload}) {
  try {
    const data = yield call(bookTransfer, payload);

    if (data.status === 'success') {
      yield put(transfers.bookTransferSuccess({error: false, data: data}));
    } else {
      yield put(transfers.bookTransferFailure({error: true, data: data}));
    }
  } catch (e) {
    yield put(transfers.bookTransferFailure({error: true, data: {}}));
  }
}

export function* bookTransferWatcher() {
  yield takeLatest(transfers.bookTransfer.toString(), bookTransferWorker);
}
