import {takeLatest, call, put} from 'redux-saga/effects';
import * as vanRentals from '../actions/vanRentals';
import {rentVan} from '../../api/vanRentals';

export function* rentVanWorker({payload}) {
  try {
    const data = yield call(rentVan, payload);

    if (data.success) {
      yield put(vanRentals.rentVanSuccess({error: false, data: data}));
    } else {
      yield put(vanRentals.rentVanFailure({error: true, data: {}}));
    }
  } catch (e) {
    yield put(vanRentals.rentVanFailure({error: true, data: {}}));
  }
}

export function* rentVanWatcher() {
  yield takeLatest(vanRentals.rentVan.toString(), rentVanWorker);
}
