import {handleActions, combineActions} from 'redux-actions';
import * as account from '../../actions/account';

export interface accountStateType {
  userBio: bioStateType;
  userLocation: locationStateType;
  userPreferences: preferencesStateType;
  updateUserBio: any;
  updateUserLocation: any;
  actionPreferences: any;
}
export interface bioStateType {
  error: boolean;
  data: {
    status?: string;
    errors?: object | null;
    data?: {
      userBioId: number;
      customerid: string;
      givenName?: string;
      familyName?: string;
      ageRange: string;
      gender: string;
      travelFrequency?: string;
      travelPurpose?: string;
      bio?: string;
      phone?: string;
      country?: string;
      created: string;
      lmod: string;
    };
  };
}
export interface locationStateType {
  error?: string | object;
  data: {
    status?: string;
    errors?: object | null;
    data?: {
      city: string | null;
      country: string | null;
      created: string;
      customerid: string;
      lmod: string;
      stateProv: string | null;
      userLocationId: number;
    };
  };
}
export interface preferencesStateType {
  error?: boolean;
  data: {
    status?: string;
    errors?: object | null;
    data?: Array<any>;
  };
}

export const initialState = {
  userBio: {error: false, data: {}},
  userLocation: {error: false, data: {}},
  userPreferences: {error: false, data: {}},
  updateUserBio: {status: 'default'},
  updateUserLocation: {status: 'default'},
  actionPreferences: {status: 'default'},
};

const handlerMap = new Map([
  [
    combineActions(account.getUserPreferencesSuccess, account.getUserPreferencesFailure),
    (state, {payload}) => {
      return {
        ...state,
        userPreferences: payload,
      };
    },
  ],
  [
    combineActions(account.getUserLocationSuccess, account.getUserLocationFailure),
    (state, {payload}) => {
      return {
        ...state,
        userLocation: payload,
      };
    },
  ],
  [
    combineActions(account.getUserBioSuccess, account.getUserBioFailure),
    (state, {payload}) => {
      return {
        ...state,
        userBio: payload,
      };
    },
  ],
  [
    combineActions(account.updateUserLocationSuccess, account.updateUserLocationFailure),
    (state, {payload}) => {
      return {
        ...state,
        updateUserLocation: payload,
      };
    },
  ],
  [
    combineActions(
      // account.saveUserBioSuccess,
      // account.saveUserBioFailure,
      account.updateUserBioSuccess,
      account.updateUserBioFailure,
    ),
    (state, {payload}) => {
      return {
        ...state,
        updateUserBio: payload,
      };
    },
  ],
  [
    combineActions(
      account.updateUserPreferencesSuccess,
      account.updateUserPreferencesFailure,
      account.deleteUserPreferencesSuccess,
      account.deleteUserPreferencesFailure,
    ),
    (state, {payload}) => {
      return {
        ...state,
        actionPreferences: payload,
      };
    },
  ],
]);

export default handleActions(handlerMap, initialState);
