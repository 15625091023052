import React, {lazy} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {Route, Routes, Navigate} from 'react-router-dom';
import Sidebar from './components/Sidebar';
import MyAccount from './components/MyAccount';
const Bookings = lazy(() => import('./components/Bookings'));
const Favorites = lazy(() => import('./components/Favorites'));
const Reviews = lazy(() => import('./components/Reviews'));
const Deals = lazy(() => import('./components/Deals'));
const Settings = lazy(() => import('./components/Settings'));
//import useDocTitle from '../../hooks/useDocTitle';
import {profileRoutes} from '../../constants/routes';
import './styles.less';

const Profile = () => {
  //useDocTitle();

  return (
    <Container className='ac-profile'>
      <Row className='ac-profile__container'>
        <Col xs={12} md={3}>
          <Sidebar />
        </Col>
        <Col xs={12} md={9} className='ac-profile__page'>
          <Routes>
            <Route path={profileRoutes.profile} element={<MyAccount />} />
            <Route path={profileRoutes.bookings} element={<Bookings />} />
            <Route path={profileRoutes.favorites} element={<Favorites />} />
            <Route path={profileRoutes.reviews} element={<Reviews />} />
            <Route path={profileRoutes.deals} element={<Deals />} />
            <Route path={profileRoutes.settings} element={<Settings />} />
            <Route path={'*'} element={<Navigate replace to={profileRoutes.profile} />} />
          </Routes>
        </Col>
      </Row>
    </Container>
  );
};

export default Profile;
