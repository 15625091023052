import ReactGA from 'react-ga4';

//Recommended Events
const signUpEmail = () => ReactGA.event('sign_up', {method: 'email'});
const signUpFacebook = () => ReactGA.event('sign_up', {method: 'Facebook'});
const signUpGoogle = () => ReactGA.event('sign_up', {method: 'Google'});
const loginEmail = (userId) => {
  ReactGA.event('login', {method: 'email', 'user-id': userId});
  ReactGA.event('userData', {'user-id': userId});
};
const loginFacebook = () => ReactGA.event('login', {method: 'Facebook'});
const loginGoogle = () => ReactGA.event('login', {method: 'Google'});

//Custom Events
const logOut = () => ReactGA.event('logoff');

const clickedButton = (params: {mocked?: boolean; category: string}) =>
  ReactGA.event({
    action: 'button click',
    category: params.category,
    label: params.mocked ? 'mocked' : 'real',
  });

const hotelSearched = (params: {
  type: string;
  city: string;
  state: string;
  country: string;
  display: string;
  hid: string;
  checkin: string;
  checkout: string;
  rooms: number;
  adults: number;
  children: number;
}) =>
  ReactGA.event('hotel_search', {
    type: params.type,
    city: params.city,
    state: params.state,
    country: params.country,
    term: params.display,
    hid: params.hid,
    checkin: params.checkin,
    checkout: params.checkout,
    rooms: params.rooms,
    adults: params.adults,
    children: params.children,
  });

const hotelViewed = (params: {city: string; hotel: string}) =>
  ReactGA.event('view_hotel', {city: params.city, hotel: params.hotel});

const disabilityOrgSpecified = (params: {org: string}) =>
  ReactGA.event('enroll_cug', {org: params.org});

const hotelBooked = (params: {city: string; hotel: string}) =>
  ReactGA.event('booked_hotel', {city: params.city, hotel: params.hotel});

const gaEvents = {
  eventSignUpEmail: signUpEmail,
  eventSignUpFacebook: signUpFacebook,
  eventSignUpGoogle: signUpGoogle,
  eventLoginEmail: loginEmail,
  eventLoginFacebook: loginFacebook,
  eventLoginGoogle: loginGoogle,
  eventLogOut: logOut,
  eventHotelSearched: hotelSearched,
  eventHotelViewed: hotelViewed,
  eventHotelBooked: hotelBooked,
  eventDisabilityOrgSpecified: disabilityOrgSpecified,
  eventClickedButton: clickedButton,
};

export {gaEvents};
