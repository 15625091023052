import React, {useEffect, useState} from 'react';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import './styles.less';

interface IProps {
  attr: {
    src: string;
    alt?: string;
    height: string;
    width: string;
  };
  className?: string;
  id?: string;
}

const Img = ({attr, className, id}: IProps) => {
  const [valid, setValid] = useState<boolean>(false);

  useEffect(() => {
    const img = new Image();
    img.src = attr.src;

    if (img.complete) {
      setValid(true);
    } else {
      img.onload = () => {
        setValid(true);
      };

      img.onerror = () => {
        setValid(false);
      };
    }
  }, [attr.src]);

  return (
    <>
      {valid ? (
        <span className={`ac-lazyLoadImg ${className ? className : ''}`}>
          <LazyLoadImage
            alt={attr.alt ? attr.alt : ''}
            height={attr.height}
            src={attr.src}
            width={attr.width}
            aria-hidden='true'
            id={id ? id : ''}
          />
        </span>
      ) : (
        <></>
      )}
    </>
  );
};

export default Img;
