import {createAction} from 'redux-actions';

//Profile
export const getBookings = createAction('GET_BOOKINGS');
export const getBookingsSuccess = createAction('GET_BOOKINGS_SUCCESS', (payload) => payload);
export const getBookingsFailure = createAction('GET_BOOKINGS_FAILURE', (payload) => payload);

//Hotel booking
export const getCurrentBooking = createAction('GET_CURRENT_BOOKING');
export const getCurrentBookingSuccess = createAction(
  'GET_CURRENT_BOOKING_SUCCESS',
  (payload) => payload,
);
export const getCurrentBookingFailure = createAction(
  'GET_CURRENT_BOOKING_FAILURE',
  (payload) => payload,
);

export const getBookingConfirmation = createAction('GET_BOOKING_CONFIRMATION');
export const getBookingConfirmationSuccess = createAction(
  'GET_BOOKING_CONFIRMATION_SUCCESS',
  (payload) => payload,
);
export const getBookingConfirmationFailure = createAction(
  'GET_BOOKING_CONFIRMATION_FAILURE',
  (payload) => payload,
);

export const cancelBooking = createAction('CANCEL_BOOKING');
export const cancelBookingSuccess = createAction('CANCEL_BOOKING_SUCCESS', (payload) => payload);
export const cancelBookingFailure = createAction('CANCEL_BOOKING_FAILURE', (payload) => payload);
