// import {applyMiddleware} from 'redux';
import {configureStore} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas';
import rootReducer, {StateShape} from './reducers';

const initialState: StateShape | any = {};
const sagaMiddleware = createSagaMiddleware();

// const middlewares = applyMiddleware(sagaMiddleware);

const store = configureStore({
  reducer: rootReducer,
  preloadedState: initialState,
  devTools: true,
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(rootSaga);

export const getState = () => (store.getState ? store.getState() : initialState);

export default store;
