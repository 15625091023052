import {createAction} from 'redux-actions';

const PREFIX = 'FLIGHTS_';

export const flightSearch = createAction(`${PREFIX}SEARCH`);
export const flightSearchSuccess = createAction(`${PREFIX}SEARCH_SUCCESS`, (payload) => payload);
export const flightSearchFailure = createAction(`${PREFIX}SEARCH_FAILURE`, (payload) => payload);

export const getReturnFlights = createAction(`${PREFIX}GET_RETURN_FLIGHTS`);
export const getReturnFlightsSuccess = createAction(
  `${PREFIX}GET_RETURN_FLIGHTS_SUCCESS`,
  (payload) => payload,
);
export const getReturnFlightsFailure = createAction(
  `${PREFIX}GET_RETURN_FLIGHTS_FAILURE`,
  (payload) => payload,
);

export const getContractData = createAction(`${PREFIX}GET_CONTRACT_DATA`);
export const getContractDataSuccess = createAction(
  `${PREFIX}GET_CONTRACT_DATA_SUCCESS`,
  (payload) => payload,
);
export const getContractDataFailure = createAction(
  `${PREFIX}GET_CONTRACT_DATA_FAILURE`,
  (payload) => payload,
);

export const bookFlight = createAction(`${PREFIX}BOOK_FLIGHT`);
export const bookFlightSuccess = createAction(`${PREFIX}BOOK_FLIGHT_SUCCESS`, (payload) => payload);
export const bookFlightFailure = createAction(`${PREFIX}BOOK_FLIGHT_FAILURE`, (payload) => payload);

export const getFlightConfirmation = createAction(`${PREFIX}GET_FLIGHT_CONFIRMATION`);
export const getFlightConfirmationSuccess = createAction(
  `${PREFIX}GET_FLIGHT_CONFIRMATION_SUCCESS`,
  (payload) => payload,
);
export const getFlightConfirmationFailure = createAction(
  `${PREFIX}GET_FLIGHT_CONFIRMATION_FAILURE`,
  (payload) => payload,
);

export const cancelReservation = createAction(`${PREFIX}CANCEL_RESERVATION`);
export const cancelReservationSuccess = createAction(
  `${PREFIX}CANCEL_RESERVATION_SUCCESS`,
  (payload) => payload,
);
export const cancelReservationFailure = createAction(
  `${PREFIX}CANCEL_RESERVATION_FAILURE`,
  (payload) => payload,
);
