export function getConfig() {
  return {
    domain: process.env.REACT_APP_DOMAIN,
    audience: process.env.REACT_APP_AUDIENCE,
    clientId: process.env.REACT_APP_CLIENTID,
    grant_type: 'http://auth0.com/oauth/grant-type/password-realm',
    dsn: process.env.REACT_APP_DSN,
    google_maps_api_key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    sentry_auth_token: process.env.SENTRY_AUTH_TOKEN,
    sanity_api: process.env.SANITY_API,
  };
}
