import {handleActions, combineActions} from 'redux-actions';
import {bookTransferSuccess, bookTransferFailure} from '../../actions/transfers';
import {BookTransferResponse} from '../../../interfaces/transfers';

export interface transfersStateType {
  error?: string | object;
  data?: BookTransferResponse;
}

const initialState = {};

const handlerMap = new Map([
  [
    combineActions(bookTransferSuccess, bookTransferFailure),
    (state, {payload}) => {
      return {
        ...state,
        ...payload,
      };
    },
  ],
]);

export default handleActions(handlerMap, initialState);
