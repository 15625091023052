import {createAction} from 'redux-actions';

const PREFIX = 'TRANSFERS_';

export const bookTransfer = createAction(`${PREFIX}BOOK_TRANSFER`);
export const bookTransferSuccess = createAction(
  `${PREFIX}BOOK_TRANSFER_SUCCESS`,
  (payload) => payload,
);
export const bookTransferFailure = createAction(
  `${PREFIX}BOOK_TRANSFER_FAILURE`,
  (payload) => payload,
);
