import {createAction} from 'redux-actions';

const PREFIX = 'RENTALCAR_';

export const searchCar = createAction(`${PREFIX}SEARCH_CAR`);
export const searchCarSuccess = createAction(`${PREFIX}SEARCH_CAR_SUCCESS`, (payload) => payload);
export const searchCarFailure = createAction(`${PREFIX}SEARCH_CAR_FAILURE`, (payload) => payload);

//
export const getContractData = createAction(`${PREFIX}GET_CONTRACT_DATA`);
export const getContractDataSuccess = createAction(
  `${PREFIX}GET_CONTRACT_DATA_SUCCESS`,
  (payload) => payload,
);
export const getContractDataFailure = createAction(
  `${PREFIX}GET_CONTRACT_DATA_FAILURE`,
  (payload) => payload,
);

//
export const rentCar = createAction(`${PREFIX}RENT_CAR`);
export const rentCarSuccess = createAction(`${PREFIX}RENT_CAR_SUCCESS`, (payload) => payload);
export const rentCarFailure = createAction(`${PREFIX}RENT_CAR_FAILURE`, (payload) => payload);

//
export const getRentalConfirmation = createAction(`${PREFIX}GET_RENTAL_CONFIRMATION`);
export const getRentalConfirmationSuccess = createAction(
  `${PREFIX}GET_RENTAL_CONFIRMATION_SUCCESS`,
  (payload) => payload,
);
export const getRentalConfirmationFailure = createAction(
  `${PREFIX}GET_RENTAL_CONFIRMATION_FAILURE`,
  (payload) => payload,
);

//
export const cancelReservation = createAction(`${PREFIX}CANCEL_RESERVATION`);
export const cancelReservationSuccess = createAction(
  `${PREFIX}CANCEL_RESERVATION_SUCCESS`,
  (payload) => payload,
);
export const cancelReservationFailure = createAction(
  `${PREFIX}CANCEL_RESERVATION_FAILURE`,
  (payload) => payload,
);

//
export const sendEmail = createAction(`${PREFIX}SEND_EMAIL`);
export const sendEmailSuccess = createAction(`${PREFIX}SEND_EMAIL_SUCCESS`, (payload) => payload);
export const sendEmailFailure = createAction(`${PREFIX}SEND_EMAIL_FAILURE`, (payload) => payload);
