import {handleActions, combineActions} from 'redux-actions';
import * as flightReview from '../../actions/flightReview';
import {
  IAirlinesResponse,
  IAirportsResponse,
  IAddReviewRes,
  IAirlineReview,
} from '../../../interfaces/airlineReview';

export interface flightReviewStateType {
  airports: {error?: boolean; data?: IAirportsResponse};
  airlines: {error?: boolean; data?: IAirlinesResponse};
  addReview: {
    error: boolean;
    data: IAddReviewRes;
  };
  airlineReview: {
    error: boolean;
    data: IAirlineReview;
  };
}

const initialState = {
  airports: {},
  airlines: {},
  addReview: {error: false, data: {}},
  airlineReview: {error: false, data: {}},
};

const handlerMap = new Map([
  [
    combineActions(flightReview.getAirportsSuccess, flightReview.getAirportsFailure),
    (state, {payload}) => {
      return {
        ...state,
        airports: {...payload},
      };
    },
  ],
  [
    combineActions(flightReview.getAirlinesSuccess, flightReview.getAirlinesFailure),
    (state, {payload}) => {
      return {
        ...state,
        airlines: {...payload},
      };
    },
  ],
  [
    combineActions(flightReview.addFlightReviewSuccess, flightReview.addFlightReviewFailure),
    (state, {payload}) => {
      return {
        ...state,
        addReview: {...payload},
      };
    },
  ],
  [
    combineActions(flightReview.getAirlineReviewSuccess, flightReview.getAirlineReviewFailure),
    (state, {payload}) => {
      return {
        ...state,
        airlineReview: {...payload},
      };
    },
  ],
]);

export default handleActions(handlerMap, initialState);
