import {handleBody, handleExceptions, createArrayEndpoint, GET} from '../utils/api';

export const circleLogin = () => {
  const params = {
    automatic_login: true,
  };

  const endpoint = createArrayEndpoint('https://community.accessiblego.com', params);

  return GET(endpoint, {
    external: true,
    token: true,
    externalToken: process.env.REACT_CIRCLE_AUTH_TOKEN,
  }).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
