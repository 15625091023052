import {handleBody, handleExceptions, createArrayEndpoint, GET, POST} from '../utils/api';

export const getBookingContractData = (data: {ppn_bundle: string}) => {
  const params = {
    ...data,
  };

  const endpoint = createArrayEndpoint('hotel/contract', params);

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const bookHotel = (data: {
  customer_id: string;
  ppn_bundle: string;
  tracking_id: string;
  email: string;
  name_first: string;
  name_last: string;
  phone_number: string;
  CheckinDate: string;
  CheckoutDate: string;
  HotelPPNId: string;
  guest_name_first: string;
  guest_name_last: string;
  cvc_code: string;
  card_type: string;
  card_number: string;
  expires: string;
  card_holder: string;
  address_line_one: string;
  address_line_two: string;
  address_city: string;
  address_postal_code: string;
  address_state_code: string;
  country_code: string;
  Rooms: string;
  Guests: string;
  comments: string;
  initials: string;
  isCUG: string;
  ip: string;
}) => {
  const endpoint = 'hotels/book';
  const options = {
    body: {
      type: 'hotel',
      customer_id: data.customer_id,
      ppn_bundle: data.ppn_bundle,
      tracking_id: data.tracking_id,
      email: data.email,
      name_first: data.name_first,
      name_last: data.name_last,
      phone_number: data.phone_number,
      CheckinDate: data.CheckinDate,
      CheckoutDate: data.CheckoutDate,
      HotelPPNId: data.HotelPPNId,
      guest_name_first: data.guest_name_first,
      guest_name_last: data.guest_name_last,
      cvc_code: data.cvc_code,
      card_type: data.card_type,
      card_number: data.card_number,
      expires: data.expires,
      card_holder: data.card_holder,
      address_line_one: data.address_line_one,
      address_line_two: data.address_line_two,
      address_city: data.address_city,
      address_postal_code: data.address_postal_code,
      address_state_code: data.address_state_code,
      country_code: data.country_code,
      Rooms: data.Rooms,
      Guests: data.Guests,
      comments: data.comments,
      initials: data.initials,
      isCUG: data.isCUG,
      client_ip_address: data.ip,
    },
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
