import {handleActions, combineActions} from 'redux-actions';
import * as flights from '../../actions/flights';
import {
  IFlightsContract,
  IFlightsSearchResults,
  IBookFlightRes,
  IFlightConfirmation,
  ICancelReservationState,
} from '../../../interfaces/flights';

export interface flightsStateType {
  search: {error: boolean; data: Partial<IFlightsSearchResults>};
  returnFlights: {error: boolean; data: Partial<IFlightsSearchResults>};
  contract: {error: boolean; data: Partial<IFlightsContract>};
  bookFlightRes: {error: boolean; data: Partial<IBookFlightRes>};
  flightConfirmation: {error: boolean; data: Partial<IFlightConfirmation>};
  cancelReservation: {error: boolean; data: Partial<ICancelReservationState>};
}

const initialState: flightsStateType = {
  search: {error: false, data: {}},
  returnFlights: {error: false, data: {}},
  contract: {error: false, data: {}},
  bookFlightRes: {error: false, data: {}},
  flightConfirmation: {error: false, data: {}},
  cancelReservation: {error: false, data: {}},
};

const handlerMap = new Map([
  [
    combineActions(flights.flightSearchSuccess, flights.flightSearchFailure),
    (state, {payload}) => {
      return {
        ...state,
        search: payload,
      };
    },
  ],
  [
    combineActions(flights.getReturnFlightsSuccess, flights.getReturnFlightsFailure),
    (state, {payload}) => {
      return {
        ...state,
        returnFlights: payload,
      };
    },
  ],
  [
    combineActions(flights.getContractDataSuccess, flights.getContractDataFailure),
    (state, {payload}) => {
      return {
        ...state,
        contract: payload,
      };
    },
  ],
  [
    combineActions(flights.bookFlightSuccess, flights.bookFlightFailure),
    (state, {payload}) => {
      return {
        ...state,
        bookFlightRes: payload,
      };
    },
  ],
  [
    combineActions(flights.getFlightConfirmationSuccess, flights.getFlightConfirmationFailure),
    (state, {payload}) => {
      return {
        ...state,
        flightConfirmation: payload,
      };
    },
  ],
  [
    combineActions(flights.cancelReservationSuccess, flights.cancelReservationFailure),
    (state, {payload}) => {
      return {
        ...state,
        cancelReservation: payload,
      };
    },
  ],
]);

export default handleActions(handlerMap, initialState);
