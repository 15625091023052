import {useEffect} from 'react';

const useScript = (
  script: string,
  location: string,
  attr?: {[key: string]: string},
  specificPage?: boolean,
) => {
  useEffect(() => {
    const newScript = document.createElement('script');

    const innerScript = document.createTextNode(script);
    newScript.appendChild(innerScript);

    if (attr) {
      for (const [key, value] of Object.entries(attr)) {
        newScript.setAttribute(key, value);
      }
    }

    switch (location) {
      case 'head':
        document.head.insertAdjacentElement('afterbegin', newScript);
        break;
      case 'body':
      default:
        document.body.insertAdjacentElement('beforeend', newScript);
    }

    return () => {
      if (location === 'body' && newScript) document.body.removeChild(newScript);
      if (location === 'head' && newScript && specificPage) document.head.removeChild(newScript);
    };
  }, [script]);
};

export default useScript;
