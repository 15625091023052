import {createAction} from 'redux-actions';

const PREFIX = 'ACCOUNT_';

//Bio
export const getUserBio = createAction(`${PREFIX}GET_USER_BIO`);
export const getUserBioSuccess = createAction(
  `${PREFIX}GET_USER_BIO_SUCCESS`,
  (payload) => payload,
);
export const getUserBioFailure = createAction(
  `${PREFIX}GET_USER_BIO_FAILURE`,
  (payload) => payload,
);

// export const saveUserBio = createAction(`${PREFIX}SAVE_USER_BIO`);
// export const saveUserBioSuccess = createAction(
//   `${PREFIX}SAVE_USER_BIO_SUCCESS`,
//   (payload) => payload,
// );
// export const saveUserBioFailure = createAction(
//   `${PREFIX}SAVE_USER_BIO_FAILURE`,
//   (payload) => payload,
// );

export const updateUserBio = createAction(`${PREFIX}UPDATE_USER_BIO`);
export const updateUserBioSuccess = createAction(
  `${PREFIX}UPDATE_USER_BIO_SUCCESS`,
  (payload) => payload,
);
export const updateUserBioFailure = createAction(
  `${PREFIX}UPDATE_USER_BIO_FAILURE`,
  (payload) => payload,
);

//Location
export const getUserLocation = createAction(`${PREFIX}GET_USER_LOCATION`);
export const getUserLocationSuccess = createAction(
  `${PREFIX}GET_USER_LOCATION_SUCCESS`,
  (payload) => payload,
);
export const getUserLocationFailure = createAction(
  `${PREFIX}GET_USER_LOCATION_FAILURE`,
  (payload) => payload,
);

export const updateUserLocation = createAction(`${PREFIX}UPDATE_USER_LOCATION`);
export const updateUserLocationSuccess = createAction(
  `${PREFIX}UPDATE_USER_LOCATION_SUCCESS`,
  (payload) => payload,
);
export const updateUserLocationFailure = createAction(
  `${PREFIX}UPDATE_USER_LOCATION_FAILURE`,
  (payload) => payload,
);

//Preferences
export const getUserPreferences = createAction(`${PREFIX}GET_USER_PREFERENCES`);
export const getUserPreferencesSuccess = createAction(
  `${PREFIX}GET_USER_PREFERENCES_SUCCESS`,
  (payload) => payload,
);
export const getUserPreferencesFailure = createAction(
  `${PREFIX}GET_USER_PREFERENCES_FAILURE`,
  (payload) => payload,
);

export const updateUserPreferences = createAction(`${PREFIX}UPDATE_USER_PREFERENCES`);
export const updateUserPreferencesSuccess = createAction(
  `${PREFIX}UPDATE_USER_PREFERENCES_SUCCESS`,
  (payload) => payload,
);
export const updateUserPreferencesFailure = createAction(
  `${PREFIX}UPDATE_USER_PREFERENCES_FAILURE`,
  (payload) => payload,
);

export const deleteUserPreferences = createAction(`${PREFIX}DELETE_USER_PREFERENCES`);
export const deleteUserPreferencesSuccess = createAction(
  `${PREFIX}DELETE_USER_PREFERENCES_SUCCESS`,
  (payload) => payload,
);
export const deleteUserPreferencesFailure = createAction(
  `${PREFIX}DELETE_USER_PREFERENCES_FAILURE`,
  (payload) => payload,
);
