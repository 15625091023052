import {takeLatest, call, put} from 'redux-saga/effects';
import * as flights from '../actions/flights';
import {
  bookFlight,
  cancelReservation,
  getContractData,
  getFlightConfirmation,
  getReturnFlights,
  searchFlights,
} from '../../api/flights';
import {getUserOffers} from '../actions/deals';

export function* flightSearchWorker({payload}) {
  if (payload) {
    try {
      const res = yield call(searchFlights, payload);

      if (res.status && res.status === 'success') {
        yield put(flights.flightSearchSuccess({error: false, data: res}));
      } else {
        yield put(flights.flightSearchFailure({error: true, data: res}));
      }
    } catch (e) {
      yield put(flights.flightSearchFailure({error: true, data: {}}));
    }
  } else {
    yield put(flights.flightSearchSuccess({error: false, data: {}}));
  }
}

export function* getReturnFlightsWorker({payload}) {
  if (payload) {
    try {
      const res = yield call(getReturnFlights, payload);

      if (res.status && res.status === 'success') {
        yield put(flights.getReturnFlightsSuccess({error: false, data: res}));
      } else {
        yield put(flights.getReturnFlightsFailure({error: true, data: res}));
      }
    } catch (e) {
      yield put(flights.getReturnFlightsFailure({error: true, data: {}}));
    }
  } else {
    yield put(flights.getReturnFlightsSuccess({error: false, data: {}}));
  }
}

export function* getContractDataWorker({payload}) {
  if (payload) {
    try {
      const res = yield call(getContractData, payload);

      if (res.status && res.status === 'success') {
        yield put(flights.getContractDataSuccess({error: false, data: res}));
      } else {
        yield put(flights.getContractDataFailure({error: true, data: res}));
      }
    } catch (e) {
      yield put(flights.getContractDataFailure({error: true, data: {}}));
    }
  } else {
    yield put(flights.getContractDataSuccess({error: false, data: {}}));
  }
}

export function* bookFlightWorker({payload}) {
  if (payload) {
    try {
      const data = yield call(bookFlight, payload);

      if (data.status) {
        if (data.status === 'success') {
          yield put(flights.bookFlightSuccess({error: false, data: data}));
          yield put(getUserOffers());
        } else {
          yield put(flights.bookFlightFailure({error: true, data: data}));
        }
      } else {
        yield put(flights.bookFlightFailure({error: true, data: {}}));
      }
    } catch (e) {
      yield put(flights.bookFlightFailure({error: true, data: {}}));
    }
  } else {
    yield put(flights.bookFlightSuccess({error: false, data: {}}));
  }
}

export function* getFlightConfirmationWorker({payload}) {
  if (payload) {
    try {
      const data = yield call(getFlightConfirmation, payload);

      if (data.status) {
        if (data.status === 'success') {
          yield put(flights.getFlightConfirmationSuccess({error: false, data: data}));
        } else {
          yield put(flights.getFlightConfirmationFailure({error: true, data: data}));
        }
      } else {
        yield put(flights.getFlightConfirmationFailure({error: true, data: {}}));
      }
    } catch (e) {
      yield put(flights.getFlightConfirmationFailure({error: true, data: {}}));
    }
  } else {
    yield put(flights.getFlightConfirmationSuccess({error: false, data: {}}));
  }
}

export function* cancelReservationWorker({payload}) {
  if (payload) {
    try {
      const data = yield call(cancelReservation, payload);

      if (data.status) {
        if (data.status === 'success') {
          yield put(flights.cancelReservationSuccess({error: false, data: data}));
        } else {
          yield put(flights.cancelReservationFailure({error: true, data: data}));
        }
      } else {
        yield put(flights.cancelReservationFailure({error: true, data: {}}));
      }
    } catch (e) {
      yield put(flights.cancelReservationFailure({error: true, data: {}}));
    }
  } else {
    yield put(flights.cancelReservationSuccess({error: false, data: {}}));
  }
}

export function* flightsWatcher() {
  yield takeLatest(flights.flightSearch.toString(), flightSearchWorker);
  yield takeLatest(flights.getReturnFlights.toString(), getReturnFlightsWorker);
  yield takeLatest(flights.getContractData.toString(), getContractDataWorker);
  yield takeLatest(flights.bookFlight.toString(), bookFlightWorker);
  yield takeLatest(flights.getFlightConfirmation.toString(), getFlightConfirmationWorker);
  yield takeLatest(flights.cancelReservation.toString(), cancelReservationWorker);
}
