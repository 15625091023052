import {
  AZURE_API_MANAGEMENT_SUBSCRIPTION_KEY,
  AZURE_API_MANAGEMENT_URL,
  BASE_API,
} from '../constants/api';

//types
export type HttpMethod = 'GET' | 'PUT' | 'POST' | 'PATCH' | 'DELETE';

export type ApiHeaders = {
  Accept: 'application/json, text/json';
  'Content-Type':
    | 'application/json'
    | 'text/json'
    | 'application/x-www-form-urlencoded'
    | 'multipart/form-data';
  Authorization?: string;
  'api-key'?: string;
  'Ocp-Apim-Subscription-Key'?: string;
};

export type AllowedContentTypes =
  | 'application/json'
  | 'text/json'
  | 'application/x-www-form-urlencoded'
  | 'multipart/form-data';

export interface Params {
  apiPath?: string;
  contentType?: AllowedContentTypes;
  body?: any;
  accessToken?: string;
  extraHeaders?: object;
  external?: boolean;
  token?: boolean;
  externalToken?: string;
  apiKey?: string;
  apiManagementKey?: string;
}

interface IFetch {
  mode: 'cors' | 'navigate' | 'no-cors' | 'same-origin';
  headers?: HeadersInit;
  method?: HttpMethod;
  body?: any;
}

//functions
export function getHeadersInit(options: Params): ApiHeaders {
  const headersInit: ApiHeaders = {
    Accept: 'application/json, text/json',
    'Content-Type': options.contentType || 'application/json',
    ...options.extraHeaders,
  };

  if (options.accessToken) {
    headersInit.Authorization = `Bearer ${options.accessToken}`;
  }

  if (options.apiKey) {
    headersInit['api-key'] = options.apiKey;
  }
  if (options.apiManagementKey) {
    headersInit['Ocp-Apim-Subscription-Key'] = options.apiManagementKey;
  }

  return headersInit;
}

export function getFetchInit(
  method: HttpMethod,
  headers: HeadersInit,
  body?: object | string,
): RequestInit {
  const fetchInit: IFetch = {mode: 'cors'};
  fetchInit.headers = new Headers({...headers});
  fetchInit.method = method;

  if (body) {
    if (typeof body === 'string') {
      fetchInit.body = body;
    } else if (typeof body === 'object') {
      fetchInit.body = JSON.stringify(body);
    }
  }

  return fetchInit;
}

export async function accessibleGoFetch(
  method: HttpMethod,
  endpoint: string,
  params?: Params,
): Promise<Response> {
  let options;

  try {
    const accessToken =
      params && params.externalToken ? params.externalToken : localStorage.getItem('accessToken');

    if (accessToken && (!params || !params.external || params.token)) {
      options = {
        ...params,
        accessToken: accessToken,
      };
    } else {
      options = {
        ...params,
      };
    }

    const headersInit: HeadersInit = getHeadersInit(options) as HeadersInit;
    const fetchInit: RequestInit = getFetchInit(method, headersInit, options.body);

    if (params && params.external) {
      return fetch(endpoint, fetchInit);
    } else {
      return fetch(`${BASE_API}/api/${endpoint}`, fetchInit);
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function accessibleGoFetchApiManagement(
  method: HttpMethod,
  endpoint: string,
  params?: Params,
): Promise<Response> {
  let options;

  try {
    const accessToken =
      params && params.externalToken ? params.externalToken : localStorage.getItem('accessToken');

    if (accessToken && (!params || !params.external || params.token)) {
      options = {
        ...params,
        accessToken: accessToken,
      };
    } else {
      options = {
        ...params,
      };
    }

    const apiManagementKey = AZURE_API_MANAGEMENT_SUBSCRIPTION_KEY;
    if (apiManagementKey) {
      options.apiManagementKey = apiManagementKey;
    }

    const headersInit: HeadersInit = getHeadersInit(options) as HeadersInit;
    const fetchInit: RequestInit = getFetchInit(method, headersInit, options.body);

    if (params && params.external) {
      return fetch(endpoint, fetchInit);
    } else {
      return fetch(`${AZURE_API_MANAGEMENT_URL}/api/${endpoint}`, fetchInit);
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export const GET = (endpoint: string, params?: Params): Promise<Response> =>
  accessibleGoFetch('GET', endpoint, params);
export const GET_API_MANAGEMENT = (endpoint: string, params?: Params): Promise<Response> =>
  accessibleGoFetchApiManagement('GET', endpoint, params);
export const PUT = (endpoint: string, params?: Params): Promise<Response> =>
  accessibleGoFetch('PUT', endpoint, params);
export const POST = (endpoint: string, params?: Params): Promise<Response> =>
  accessibleGoFetch('POST', endpoint, params);
export const PATCH = (endpoint: string, params?: Params): Promise<Response> =>
  accessibleGoFetch('PATCH', endpoint, params);
export const DELETE = (endpoint: string, params?: Params): Promise<Response> =>
  accessibleGoFetch('DELETE', endpoint, params);

export function handleExceptions(response: Response) {
  if (response.status >= 400) {
    return response.text().then((text) => {
      throw text;
    });
  }

  return response;
}

export function handleBody(response: Response): Promise<object | null> {
  if (!response) {
    return Promise.reject(new Error('No response supplied to handleBody'));
  }

  if (response.status === 204) {
    return response.text().then((text) => ({text}));
  }

  const contentType: string | null = response.headers.get('Content-Type');

  switch (contentType && contentType.toLowerCase()) {
    case 'application/json':
    case 'application/json; charset=utf-8':
    case 'application/json; odata.metadata=minimal':
    case 'application/json; odata.metadata=minimal; odata.streaming=true; charset=utf-8':
    case 'application/x-www-form-urlencoded; charset=utf-8':
    case 'text/json':
      try {
        return response.json().then((data) => data as object | null);
      } catch (error) {
        return Promise.reject(new Error("Got a JSON response, but it couldn't be parsed"));
      }

    case 'text/plain':
    case 'text/html; charset=utf-8':
    case 'text/html':
      try {
        return response.text().then((text) => ({text}));
      } catch (error) {
        return Promise.reject(new Error("Got a text response, but it couldn't be parsed"));
      }

    default:
      return Promise.reject(new Error(`Unrecognized Content-Type: ${contentType || ''}`));
  }
}

export function createArrayEndpoint(baseName: string, params?: object): string {
  if (params) {
    const resultString = Object.entries(params).map((item) => {
      const paramKeyName = item[0];
      const paramArray = item[1];
      if (Array.isArray(paramArray)) {
        return paramArray
          .map((arrayItem: number[]) => {
            return paramKeyName + '=' + arrayItem;
          })
          .join('&');
      } else {
        return item.join('=');
      }
    });

    return `${baseName}?${resultString.join('&')}`;
  } else {
    return `${baseName}`;
  }
}

export async function getCachedData(cacheName: string, url: string) {
  const cacheStorage = await caches.open(cacheName);
  const cachedResponse = await cacheStorage.match(url);
  if (!cachedResponse || !cachedResponse.ok) {
    return false;
  }
  // console.log(await cachedResponse.json());
  return await cachedResponse.json();
}

export function addDataIntoCache(cacheName: string, url: string, response) {
  const data = new Response(JSON.stringify(response));

  if ('caches' in window) {
    caches.open(cacheName).then((cache) => {
      cache.put(url, data);
    });
  }
}

export function deleteCache(cacheName: string) {
  if ('caches' in window) {
    caches.open(cacheName).then((cache) => {
      cache.delete(cacheName);
    });
  }
}
