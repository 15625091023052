import {createAction} from 'redux-actions';

const PREFIX = 'BUSINESSPAGE_';

export const getBusinessPageProfile = createAction(`${PREFIX}GET_PROFILE`);
export const getBusinessPageProfileSuccess = createAction(
  `${PREFIX}GET_PROFILE_SUCCESS`,
  (payload) => payload,
);
export const getBusinessPageProfileFailure = createAction(
  `${PREFIX}GET_PROFILE_FAILURE`,
  (payload) => payload,
);
