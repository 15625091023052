import {handleActions, combineActions} from 'redux-actions';
import * as checkout from '../../actions/checkout';
import {IBookHotelResponse, IContract} from '../../../interfaces/checkout';

export interface checkoutStateType {
  contractData: {error: boolean; data: IContract};
  regularRate: {error: boolean; data: IContract};
  bookHotel: {error: boolean; data: IBookHotelResponse};
}

const initialState = {
  contractData: {},
  regularRate: {},
  bookHotel: {},
};

const handlerMap = new Map([
  [
    combineActions(checkout.getBookingContractDataSuccess, checkout.getBookingContractDataFailure),
    (state, {payload}) => {
      return {
        ...state,
        contractData: {...payload},
      };
    },
  ],
  [
    combineActions(checkout.getRegularRateSuccess, checkout.getRegularRateFailure),
    (state, {payload}) => {
      return {
        ...state,
        regularRate: {...payload},
      };
    },
  ],
  [
    combineActions(checkout.bookHotelSuccess, checkout.bookHotelFailure),
    (state, {payload}) => {
      return {
        ...state,
        bookHotel: {...payload},
      };
    },
  ],
]);

export default handleActions(handlerMap, initialState);
