import {handleActions, combineActions} from 'redux-actions';
import * as settings from '../../actions/settings';

export interface settingsStateType {
  isUser: {error?: boolean; status: string; errors: object | null; data: boolean};
  changePassword: string | null;
  clientIP: {error: boolean; data: string};
}

export const initialState = {
  isUser: {},
  changePassword: {error: false},
  clientIP: {error: false, data: ''},
};

const handlerMap = new Map([
  [
    combineActions(settings.getIsUserFailure, settings.getIsUserSuccess),
    (state, {payload}) => {
      return {
        ...state,
        isUser: {
          ...payload,
        },
      };
    },
  ],
  [
    combineActions(settings.getClientIPSuccess, settings.getClientIPFailure),
    (state, {payload}) => {
      return {
        ...state,
        clientIP: {
          ...payload,
        },
      };
    },
  ],
  [
    combineActions(settings.changePasswordSuccess, settings.changePasswordFailure),
    (state, {payload}) => {
      return {
        ...state,
        changePassword: {
          ...payload,
        },
      };
    },
  ],
]);

export default handleActions(handlerMap, initialState);
