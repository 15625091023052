import React from 'react';
import auth0 from 'auth0-js';
import * as Sentry from '@sentry/react';
import {CaptureConsole, HttpClient} from '@sentry/integrations';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import {Provider} from 'react-redux';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import reportWebVitals from './reportWebVitals';
import App from './App';
import store from './redux/store';
import {getConfig} from './config';
import './styles/main.less';

const config = getConfig();

export const webAuth = new auth0.WebAuth({
  domain: config.domain,
  clientID: config.clientId,
  audience: config.audience,
  scope: 'openid profile email',
  responseType: 'token',
  redirectUri: window.location.href,
});

['production', 'development'].indexOf(process.env.NODE_ENV) > -1 &&
  Sentry.init({
    dsn: config.dsn,
    integrations: [
      new HttpClient(),
      new CaptureConsole({levels: ['error']}),
      new Sentry.BrowserTracing(),
      new Sentry.Replay({maskAllText: true, blockAllMedia: true}),
    ],
    environment: process.env.NODE_ENV,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: ['Non-Error promise rejection captured', 'TypeError: cancelled', 'NetworkError'],

    //SDK will attempt to print out useful debugging information if something goes wrong with sending the event. The default is always false. It's generally not recommended to turn it on in production, though turning debug mode on will not cause any safety concerns
    debug: process.env.NODE_ENV != 'production',
  });

process.env.NODE_ENV === 'production' && ReactGA.initialize(process.env.REACT_GA_MEASUREMENT_ID);
process.env.NODE_ENV === 'production' &&
  TagManager.initialize({
    gtmId: process.env.REACT_GTM_ID,
  });

ReactDOM.createRoot(document.getElementById('root') as Element).render(
  <Sentry.ErrorBoundary>
    <Provider store={store}>
      <BrowserRouter>
        <App webAuth={webAuth} />
      </BrowserRouter>
    </Provider>
  </Sentry.ErrorBoundary>,
);

reportWebVitals();
