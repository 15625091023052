import {handleActions, combineActions} from 'redux-actions';
import {rentVanSuccess, rentVanFailure} from '../../actions/vanRentals';
import {rentVanResponse} from '../../../interfaces/vanRentals';

export interface vanRentalsStateType {
  error?: string | object;
  data?: rentVanResponse;
}

const initialState = {};

const handlerMap = new Map([
  [
    combineActions(rentVanSuccess, rentVanFailure),
    (state, {payload}) => {
      return {
        ...state,
        ...payload,
      };
    },
  ],
]);

export default handleActions(handlerMap, initialState);
