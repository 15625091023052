import {createAction} from 'redux-actions';

const PREFIX = 'CITYGUIDES_';

// export const getCities = createAction(`${PREFIX}GET_CITIES`);
// export const getCitiesSuccess = createAction(`${PREFIX}GET_CITIES_SUCCESS`, (payload) => payload);
// export const getCitiesFailure = createAction(`${PREFIX}GET_CITIES_FAILURE`, (payload) => payload);

export const getCityArticles = createAction(`${PREFIX}GET_CITY_ARTICLES`);
export const getCityArticlesSuccess = createAction(
  `${PREFIX}GET_CITY_ARTICLES_SUCCESS`,
  (payload) => payload,
);
export const getCityArticlesFailure = createAction(
  `${PREFIX}GET_CITY_ARTICLES_FAILURE`,
  (payload) => payload,
);

export const getArticles = createAction(`${PREFIX}GET_ARTICLES`);
export const getArticlesSuccess = createAction(
  `${PREFIX}GET_ARTICLES_SUCCESS`,
  (payload) => payload,
);
export const getArticlesFailure = createAction(
  `${PREFIX}GET_ARTICLES_FAILURE`,
  (payload) => payload,
);
