import {handleActions, combineActions} from 'redux-actions';
import * as hotel from '../../actions/hotel';
import {IHotelDetailsState, IRoomsState} from '../../../interfaces/hotel';

export interface hotelStateType {
  hotelDetails: {error: boolean; data: IHotelDetailsState};
  roomsData: {error: boolean; data: IRoomsState};
}

const initialState = {
  hotelDetails: {error: false, data: {}},
  roomsData: {},
};

const handlerMap = new Map([
  [
    combineActions(hotel.getHotelDetailsSuccess, hotel.getHotelDetailsFailure),
    (state, {payload}) => {
      return {
        ...state,
        hotelDetails: {...payload},
      };
    },
  ],
  [
    combineActions(hotel.getRoomsDataSuccess, hotel.getRoomsDataFailure),
    (state, {payload}) => {
      return {
        ...state,
        roomsData: {...payload},
      };
    },
  ],
]);

export default handleActions(handlerMap, initialState);
