import {takeLatest, call, put} from 'redux-saga/effects';
import * as businessPages from '../actions/businessPages';
import {
  //getArticles,
  // getCities,
  getBusinessPage,
} from '../../api/businessPages';

// export function* getCitiesWorker() {
//   try {
//     const data = yield call(getCities);

//     if (data.destinations) {
//       yield put(cityGuides.getCitiesSuccess({error: false, data: data.destinations}));
//     } else {
//       yield put(cityGuides.getCitiesFailure({error: true, data: data}));
//     }
//   } catch (e) {
//     yield put(cityGuides.getCitiesFailure({error: true, destinations: []}));
//   }
// }

export function* getBusinessPageProfileWorker({payload}) {
  try {
    const data = yield call(getBusinessPage, payload);

    if (data) {
      yield put(businessPages.getBusinessPageProfileSuccess({error: false, data: data}));
    } else {
      yield put(businessPages.getBusinessPageProfileFailure({error: true, data: data}));
    }
  } catch (e) {
    yield put(businessPages.getBusinessPageProfileFailure({error: true, data: {}}));
  }
}

// export function* getArticlesWorker({payload}) {
//   try {
//     const data = yield call(getArticles, payload);

//     if (data) {
//       yield put(cityGuides.getArticlesSuccess({error: false, data: data}));
//     } else {
//       yield put(cityGuides.getArticlesFailure({error: true, data: data}));
//     }
//   } catch (e) {
//     yield put(cityGuides.getArticlesFailure({error: true, destinations: {}}));
//   }
// }

export function* getBusinessPageWatcher() {
  // yield takeLatest(cityGuides.getCities.toString(), getCitiesWorker);
  yield takeLatest(businessPages.getBusinessPageProfile.toString(), getBusinessPageProfileWorker);
  //yield takeLatest(cityGuides.getArticles.toString(), getArticlesWorker);
}
