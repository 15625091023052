import {createAction} from 'redux-actions';

const PREFIX = 'REVIEWS_';

export const getUserReviews = createAction(`${PREFIX}GET_USER_REVIEWS`);
export const getUserReviewsSuccess = createAction(
  `${PREFIX}GET_USER_REVIEWS_SUCCESS`,
  (payload) => payload,
);
export const getUserReviewsFailure = createAction(
  `${PREFIX}GET_USER_REVIEWS_FAILURE`,
  (payload) => payload,
);

export const getAllReviews = createAction(`${PREFIX}GET_ALL_REVIEWS`);
export const getAllReviewsSuccess = createAction(
  `${PREFIX}GET_ALL_REVIEWS_SUCCESS`,
  (payload) => payload,
);
export const getAllReviewsFailure = createAction(
  `${PREFIX}GET_ALL_REVIEWS_FAILURE`,
  (payload) => payload,
);

export const getHotelDetails = createAction(`${PREFIX}GET_HOTEL_DETAILS`);
export const getHotelDetailsSuccess = createAction(
  `${PREFIX}GET_HOTEL_DETAILS_SUCCESS`,
  (payload) => payload,
);
export const getHotelDetailsFailure = createAction(
  `${PREFIX}GET_HOTEL_DETAILS_FAILURE`,
  (payload) => payload,
);

export const addHotelReview = createAction(`${PREFIX}ADD_HOTEL_REVIEW`);
export const addHotelReviewSuccess = createAction(
  `${PREFIX}ADD_HOTEL_REVIEW_SUCCESS`,
  (payload) => payload,
);
export const addHotelReviewFailure = createAction(
  `${PREFIX}ADD_HOTEL_REVIEW_FAILURE`,
  (payload) => payload,
);
