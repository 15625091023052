import {
  handleBody,
  handleExceptions,
  GET_API_MANAGEMENT,
  POST,
  GET,
  createArrayEndpoint,
} from '../utils/api';

export const getAirports = () => {
  const endpoint = 'airports/all';

  return GET_API_MANAGEMENT(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const getAirlines = () => {
  const endpoint = 'airlines/all';

  return GET_API_MANAGEMENT(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const addFlightReview = (data: {
  travel_date: string;
  review: string;
  flights: Array<{
    airline_code: string;
    departure_iata: string;
    departure_facilities_rating: number;
    departure_counterToGate_rating: number;
    departure_boarding_rating: number;
    arrival_iata: string;
    arrival_facilities_rating: number;
    experience_rating: number;
    aircraft_rating: number;
    inflight_entertainment_rating: number;
    deplaning_rating: number;
    equipment_handling_rating: number;
    inflight_staff_rating: number;
  }>;
}) => {
  const endpoint = 'flight/review';
  const options = {
    body: data,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const getAirlineReview = (data: {
  airlineCode: string;
}): Promise<void | Response | object | null> => {
  const endpoint = createArrayEndpoint('flight/getReviewsByAirlines', data);

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
