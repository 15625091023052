import {createAction} from 'redux-actions';

const PREFIX = 'AUTH_';

//signin
export const signIn = createAction(`${PREFIX}SIGN_IN`);
export const signInSuccess = createAction(`${PREFIX}SIGN_IN_SUCCESS`, (payload) => payload);
export const signInFailure = createAction(`${PREFIX}SIGN_IN_FAILURE`, (payload) => payload);

export const socialSignIn = createAction(`${PREFIX}SOCIAL_SIGN_IN`);
export const socialSignInSuccess = createAction(
  `${PREFIX}SOCIAL_SIGN_IN_SUCCESS`,
  (payload) => payload,
);
export const socialSignInFailure = createAction(
  `${PREFIX}SOCIAL_SIGN_IN_FAILURE`,
  (payload) => payload,
);

//user session/token rotation
export const updateToken = createAction(`${PREFIX}UPDATE_TOKEN`);
export const updateTokenSuccess = createAction(
  `${PREFIX}UPDATE_TOKEN_SUCCESS`,
  (payload) => payload,
);
export const updateTokenFailure = createAction(
  `${PREFIX}UPDATE_TOKEN_FAILURE`,
  (payload) => payload,
);

//signout
export const signOut = createAction(`${PREFIX}SIGN_OUT`);
export const signOutSuccess = createAction(`${PREFIX}SIGN_OUT_SUCCESS`);
export const signOutFailure = createAction(`${PREFIX}SIGN_OUT_FAILURE`);

//user info from auth
export const getUserData = createAction(`${PREFIX}GET_USER_DATA`);
export const getUserDataSuccess = createAction(
  `${PREFIX}GET_USER_DATA_SUCCESS`,
  (payload) => payload,
);
export const getUserDataFailure = createAction(
  `${PREFIX}GET_USER_DATA_FAILURE`,
  (payload) => payload,
);

//signUp
export const signUp = createAction(`${PREFIX}SIGN_UP`);
export const signUpSuccess = createAction(`${PREFIX}SIGN_UP_SUCCESS`, (payload) => payload);
export const signUpFailure = createAction(`${PREFIX}SIGN_UP_FAILURE`, (payload) => payload);

//socials SignUp
export const socialSignUp = createAction(`${PREFIX}SOCIAL_SIGN_UP`);
export const socialSignUpSuccess = createAction(
  `${PREFIX}SOCIAL_SIGN_UP_SUCCESS`,
  (payload) => payload,
);
export const socialSignUpFailure = createAction(
  `${PREFIX}SOCIAL_SIGN_UP_FAILURE`,
  (payload) => payload,
);
