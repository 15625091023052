//FB Integration :: SDK
export const fbSDKIntegration =
  'window.fbAsyncInit = function() {' +
  '  FB.init({' +
  '    appId      : ' +
  process.env.REACT_FB_APP_ID +
  ',' +
  '    cookie     : true,' +
  '    xfbml      : true,' +
  "    version    : 'v3.0'" +
  '  });' +
  '  FB.AppEvents.logPageView();   ' +
  '};' +
  '(function(d, s, id) {' +
  'var js, fjs = d.getElementsByTagName(s)[0];' +
  'if (d.getElementById(id)) return;' +
  'js = d.createElement(s); js.id = id;' +
  'js.src = "//connect.facebook.net/en_US/sdk.js";' + //#xfbml=1&cookie=1&version=v3.0&appId=' +process.env.REACT_FB_APP_ID +'&autoLogAppEvents=1"
  'fjs.parentNode.insertBefore(js, fjs);' +
  '}(document, "script", "facebook-jssdk"));';

//FB Integration :: Pixel
export const fbPixelIntegration =
  '!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?' +
  'n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;' +
  "n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;" +
  't.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,' +
  "document,'script','https://connect.facebook.net/en_US/fbevents.js');" +
  "fbq('init', '312392209172643');" +
  "fbq('track', 'PageView');";

// ReferralCandy tracking code for Referral program
export const ReferralCandyCode = `!function(d,s) {
    var rc = "//go.referralcandy.com/purchase/qv7h147v5o3bpfzoyy9t6btwo.js";
    var js = d.createElement(s);
    js.src = rc;
    var fjs = d.getElementsByTagName(s)[0];
    fjs.parentNode.insertBefore(js,fjs);
  }(document,"script");`;
