import {createSelector} from 'reselect';
import {StateShape} from '../redux/store/reducers';
import {globalConfigStateType} from '../redux/store/reducers/globalConfig';

export const getGlobalConfigState = (state: StateShape) => state.globalConfig;

export const grayScaleModeState = createSelector(
  getGlobalConfigState,
  (globalConfig: globalConfigStateType) => globalConfig.grayScaleMode,
);
