import {handleBody, handleExceptions, POST} from '../utils/api';

export const bookTransfer = (data: {
  nameFirst: string;
  nameLast: string;
  email: string;
  phone: string;
  LocationPickUp: string;
  LocationDropoff: string;
  travelDate: string;
  flightNumber?: string;
  pickupTimeHour?: string;
  pickupTimeMin?: string;
  numPassengers: number;
  isWheelchairOrScooter: boolean;
  isFoldable?: boolean;
  isMotorized?: boolean;
  weight?: string;
  size?: string;
  canUseIndependently?: boolean;
  wheelchairAssistance?: string;
  specialRequests?: string;
  IsTest?: boolean;
}) => {
  const endpoint = 'transfers/jayride';
  const options = {
    body: data,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
