import {handleActions} from 'redux-actions';
import {setGrayscaleMode} from '../../actions/globalConfig';

export interface globalConfigStateType {
  grayScaleMode: string;
}

const initialState = {grayScaleMode: localStorage.getItem('greyMode') || false};

const handlerMap = new Map([
  [
    setGrayscaleMode,
    (state, {payload}) => {
      return {
        ...state,
        grayScaleMode: payload,
      };
    },
  ],
]);

export default handleActions(handlerMap, initialState);
