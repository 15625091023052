import {takeLatest, call, put} from 'redux-saga/effects';
import * as forum from '../actions/circleForum';
import {circleLogin} from '../../api/circleForum';

export function* circleSignInWorker() {
  try {
    const res = yield call(circleLogin);
    yield put(forum.signInSuccess({error: false, data: res}));
  } catch (e) {
    yield put(forum.signInFailure({error: true, data: {}}));
  }
}

export function* circleSignInWatcher() {
  yield takeLatest(forum.signIn.toString(), circleSignInWorker);
}
