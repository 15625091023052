import {takeLatest, call, put} from 'redux-saga/effects';
import * as travelIdeas from '../actions/travelIdeas';
import {getTravelIdeas} from '../../api/travelIdeas';

export function* getTravelIdeasWorker() {
  try {
    const res = yield call(getTravelIdeas);

    if (res.success) {
      yield put(travelIdeas.getTravelIdeasSuccess({error: false, data: res.data}));
    } else {
      yield put(travelIdeas.getTravelIdeasFailure({error: true, data: res}));
    }
  } catch (e) {
    yield put(travelIdeas.getTravelIdeasFailure({error: true, data: {}}));
  }
}

export function* getTravelIdeasWatcher() {
  yield takeLatest(travelIdeas.getTravelIdeas.toString(), getTravelIdeasWorker);
}
