import {GET, POST, createArrayEndpoint, handleBody, handleExceptions} from '../utils/api';
import Cookies from 'js-cookie';

export const searchCars = (data: {
  pickup_date?: string;
  dropoff_date?: string;
  pickup_airport_code?: string;
  pickup_city_id?: string;
  is_dropoff_same: boolean;
  dropoff_airport_code: string;
  dropoff_city_id: string;
  car_type: string;
  company_codes: string;
  ip: string;
}): Promise<void | Response | object | null> => {
  const params = {
    pickup_date_time: data.pickup_date,
    dropoff_date_time: data.dropoff_date,
    ...(data.pickup_airport_code && {pickup_airport_code: data.pickup_airport_code}),
    ...(data.pickup_city_id && {pickup_city_id: data.pickup_city_id}),
    is_dropoff_same: data.is_dropoff_same,
    ...(data.dropoff_airport_code && {dropoff_airport_code: data.dropoff_airport_code}),
    ...(data.dropoff_city_id && {dropoff_city_id: data.dropoff_city_id}),
    ...(data.car_type && {filter_car_type: data.car_type}),
    ...(data.company_codes && {filter_company_codes: data.company_codes}),
    client_ip: data.ip,
    client_user_agent: window.navigator.userAgent,
  };

  const endpoint = createArrayEndpoint('car/search', params);

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const getContractData = (data: {
  ppn_bundle: string;
  ip: string;
}): Promise<void | Response | object | null> => {
  const endpoint = 'car/contract';
  const options = {
    body: {
      ppn_bundle: data.ppn_bundle,
      client_ip: data.ip,
      client_user_agent: window.navigator.userAgent,
      rsClientCookie: Cookies.get('RS-CLIENT'),
    },
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const rentCar = (data: {
  ppn_bundle: string;
  contact: {
    email: string;
    phone: string;
  };
  driver: {
    first_name: string;
    last_name: string;
    age_at_pickup: number;
  };
  acessibility_request: {
    hand_controls: boolean;
    spinner_knob: boolean;
    message: string;
  };
  special_request: {
    booster_seat: boolean;
    infant_child_seat: boolean;
    toddler_child_seat: boolean;
    navigation: boolean;
  };
  collision_damage: boolean;
  flight_information: {
    airline_code: string;
    number: string;
  };
  credit_card: {
    first_name: string;
    last_name: string;
    type: string;
    number: string;
    exp_month: string;
    exp_year: string;
    security_code: string;
    street: string;
    zip_code: string;
    city: string;
    country: string;
    state: string;
  };
  pickupPPNId: string;
  pickupDate: string;
  pickupTime: string;
  dropoffPPNId: string;
  dropoffDate: string;
  dropoffTime: string;
  terms_agreed: string;
  tracking_id: string;
  rsClientCookie: string;
}) => {
  const endpoint = 'car/book';
  const options = {
    body: data,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const getRentalConfirmation = (data: {
  bid: string;
  e: string;
}): Promise<void | Response | object | null> => {
  const endpoint = createArrayEndpoint('car/confirmation', data);

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const cancelReservation = (data: {
  bookingId: string;
  email: string;
  cancelReason?: string;
}): Promise<void | Response | object | null> => {
  const endpoint = 'car/cancel';
  const options = {
    body: data,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const sendEmail = (data: {
  bookingId: string;
  email: string;
  sendToEmail: string;
}): Promise<void | Response | object | null> => {
  const endpoint = 'car/sendItinerary';
  const options = {
    body: data,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
