import {createAction} from 'redux-actions';

const PREFIX = 'HOTEL_';

//Retrieves hotel & rate data (new request)
export const getHotelDetails = createAction(`${PREFIX}GET_HOTEL_DETAILS`);
export const getHotelDetailsSuccess = createAction(
  `${PREFIX}GET_HOTEL_DETAILS_SUCCESS`,
  (payload) => payload,
);
export const getHotelDetailsFailure = createAction(
  `${PREFIX}GET_HOTEL_DETAILS_FAILURE`,
  (payload) => payload,
);

//Retrieves room data
export const getRoomsData = createAction(`${PREFIX}GET_ROOMS_DATA`);
export const getRoomsDataSuccess = createAction(
  `${PREFIX}GET_ROOMS_DATA_SUCCESS`,
  (payload) => payload,
);
export const getRoomsDataFailure = createAction(
  `${PREFIX}GET_ROOMS_DATA_FAILURE`,
  (payload) => payload,
);
