import {handleBody, handleExceptions, POST} from '../utils/api';

export const getDiscoursePayload = (data: {
  base64EncodedPayload: string;
}): Promise<void | Response | object | null> => {
  const endpoint = 'forum/getDiscoursePayload';
  const options = {
    body: data,
    'Content-Type': 'application/x-www-form-urlencoded',
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
