import {handleBody, handleExceptions, GET, POST} from '../utils/api';

//Bio

export const getBio = () => {
  const endpoint = 'user/getUserBio';

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

//old api
// export const saveBio = (data: {
//   givenName: string;
//   familyName: string;
//   ageRange: string;
//   gender: string;
//   travelFrequency: string;
//   travelPurpose: string;
//   bio: string;
// }): Promise<void | Response | object | null> => {
//   const endpoint = 'user/saveUserBio';
//   const options = {
//     body: data,
//   };

//   return POST(endpoint, options).then((response) => {
//     if (response.status >= 400) {
//       return handleExceptions(response);
//     }

//     return handleBody(response);
//   });
// };

export const updateBio = (data: {
  givenName?: string | null;
  familyName?: string | null;
  phone?: string | null;
  ageRange?: string | null;
  gender?: string | null;
  travelFrequency?: string | null;
  bio?: string | null;
  fieldType: string;
}): Promise<void | Response | object | null> => {
  const endpoint = 'user/updateUserBio';
  const options = {
    body: data,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

//Location

export const getLocation = () => {
  const endpoint = 'user/getUserLocation';

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const saveLocation = (data: {
  city: string;
  stateProv: string;
  country: string;
}): Promise<void | Response | object | null> => {
  const endpoint = 'user/saveUserLocation';
  const options = {
    body: data,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

//Preferences

export const getPreferences = () => {
  const endpoint = 'user/getUserPreferences';

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const savePreference = (data: {
  category: string;
  type: string;
}): Promise<void | Response | object | null> => {
  const endpoint = 'user/saveUserPreference';
  const options = {
    body: data,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const deletePreference = (data: {
  category: string;
  type: string;
}): Promise<object | null> => {
  const endpoint = 'user/removeUserPreference';

  const options = {
    body: data,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
