import {handleActions, combineActions} from 'redux-actions';
import * as rentalCar from '../../actions/rentalCar';
import {
  SearchCarResult,
  IRentCarResult,
  IRentCarContract,
  IRentConfirmationRes,
  ICancelCarReservationState,
  ISendEmailState,
} from '../../../interfaces/rentalCar';

export interface rentalCarStateType {
  search: {error: boolean; data: SearchCarResult};
  contractData: {error: boolean; data: IRentCarContract};
  rentCarRes: {error: boolean; data: IRentCarResult};
  rentalConfirmation: {error: boolean; data: IRentConfirmationRes};
  cancelReservation: {error: boolean; data: Partial<ICancelCarReservationState>};
  sendEmail: {error: boolean; data: Partial<ISendEmailState>};
}

const initialState: rentalCarStateType = {
  search: {error: false, data: {}},
  contractData: {error: false, data: {}},
  rentCarRes: {error: false, data: {}},
  rentalConfirmation: {error: false, data: {}},
  cancelReservation: {error: false, data: {}},
  sendEmail: {error: false, data: {}},
};

const handlerMap = new Map([
  [
    combineActions(rentalCar.searchCarSuccess, rentalCar.searchCarFailure),
    (state, {payload}) => {
      return {
        ...state,
        search: payload,
      };
    },
  ],
  [
    combineActions(rentalCar.getContractDataSuccess, rentalCar.getContractDataFailure),
    (state, {payload}) => {
      return {
        ...state,
        contractData: payload,
      };
    },
  ],
  [
    combineActions(rentalCar.rentCarSuccess, rentalCar.rentCarFailure),
    (state, {payload}) => {
      return {
        ...state,
        rentCarRes: payload,
      };
    },
  ],
  [
    combineActions(rentalCar.getRentalConfirmationSuccess, rentalCar.getRentalConfirmationFailure),
    (state, {payload}) => {
      return {
        ...state,
        rentalConfirmation: payload,
      };
    },
  ],
  [
    combineActions(rentalCar.cancelReservationSuccess, rentalCar.cancelReservationFailure),
    (state, {payload}) => {
      return {
        ...state,
        cancelReservation: payload,
      };
    },
  ],
  [
    combineActions(rentalCar.sendEmailSuccess, rentalCar.sendEmailFailure),
    (state, {payload}) => {
      return {
        ...state,
        sendEmail: payload,
      };
    },
  ],
]);

export default handleActions(handlerMap, initialState);
