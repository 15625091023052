import {createAction} from 'redux-actions';

const PREFIX = 'TYPEAHEAD_';

export const getSuggestions = createAction(`${PREFIX}GET_SUGGESTIONS`);
export const getSuggestionsSuccess = createAction(
  `${PREFIX}GET_SUGGESTIONS_SUCCESS`,
  (payload) => payload,
);
export const getSuggestionsFailure = createAction(
  `${PREFIX}GET_SUGGESTIONS_FAILURE`,
  (payload) => payload,
);

export const getCities = createAction(`${PREFIX}GET_CITIES`);
export const getCitiesSuccess = createAction(`${PREFIX}GET_CITIES_SUCCESS`, (payload) => payload);
export const getCitiesFailure = createAction(`${PREFIX}GET_CITIES_FAILURE`, (payload) => payload);

export const getAirports = createAction(`${PREFIX}GET_AIRPORTS`);
export const getAirportsSuccess = createAction(
  `${PREFIX}GET_AIRPORTS_SUCCESS`,
  (payload) => payload,
);
export const getAirportsFailure = createAction(
  `${PREFIX}GET_AIRPORTS_FAILURE`,
  (payload) => payload,
);

export const getHotels = createAction(`${PREFIX}GET_HOTELS`);
export const getHotelsSuccess = createAction(`${PREFIX}GET_HOTELS_SUCCESS`, (payload) => payload);
export const getHotelsFailure = createAction(`${PREFIX}GET_HOTELS_FAILURE`, (payload) => payload);

export const getPosts = createAction(`${PREFIX}GET_POSTS`);
export const getPostsSuccess = createAction(`${PREFIX}GET_POSTS_SUCCESS`, (payload) => payload);
export const getPostsFailure = createAction(`${PREFIX}GET_POSTS_FAILURE`, (payload) => payload);

export const getArticles = createAction(`${PREFIX}GET_ARTICLES`);
export const getArticlesSuccess = createAction(
  `${PREFIX}GET_ARTICLES_SUCCESS`,
  (payload) => payload,
);
export const getArticlesFailure = createAction(
  `${PREFIX}GET_ARTICLES_FAILURE`,
  (payload) => payload,
);
