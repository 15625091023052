import React from 'react';
import {useSelector} from 'react-redux';
import Loader from './Loader';
import {grayScaleModeState} from '../../../selectors/globalConfig';
import './styles.less';

interface IProps {
  full?: boolean;
}

const LoaderPage = ({full}: IProps) => {
  const greyMode = useSelector(grayScaleModeState);

  return (
    <div
      className={`ac-loaderPage ${full ? 'ac-loaderPage--full' : ''} ${
        greyMode === 'true' ? 'ac-greyMode' : ''
      }`}
    >
      <Loader />
    </div>
  );
};

export default LoaderPage;
