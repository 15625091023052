import {handleBody, handleExceptions, createArrayEndpoint, GET, POST} from '../utils/api';

//search2
export const getHotels2 = (data: {
  ppnid: string;
  type: string;
  display?: string;
  checkin: string;
  checkout: string;
  rooms: string;
  adults: string;
  children: string;
  radius?: string;
  lon?: string;
  lat?: string;
  sort?: string;
  numResults?: string;
}) => {
  const params = {
    ppnid: data.ppnid,
    type: data.type,
    display: data.display,
    checkInDate: data.checkin,
    checkoutDate: data.checkout,
    rooms: data.rooms,
    adults: data.adults,
    children: data.children,
    radius: data.radius,
    lon: data.lon,
    lat: data.lat,
    sort: data.sort || 'top',
    // numResults: data.numResults ? '' : '20',
    numResults: data.numResults || '',
    ...(data.type === 'city' && {cug: false}),
  };

  const endpoint = createArrayEndpoint('hotel/search2', params);

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const getAccessibilityData = (data: Array<string>) => {
  const endpoint = 'https://api.accessibilitydata.com/ago/GetHotels';
  const options = {
    body: {hids: data},
    external: true,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
