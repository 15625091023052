import {createSelector} from 'reselect';
import {StateShape} from '../redux/store/reducers/index';
import {authStateType} from '../redux/store/reducers/auth';

export const getAuth = (state: StateShape) => state.auth;

export const getSignUpStatus = createSelector(getAuth, (auth: authStateType) => auth.signUpState);

export const getAuthorizationState = createSelector(
  getAuth,
  (auth: authStateType) => auth.authState,
);

export const getUser = createSelector(getAuth, (auth: authStateType) => auth.user);
