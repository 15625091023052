import {handleActions, combineActions} from 'redux-actions';
import {signInSuccess, signInFailure} from '../../actions/circleForum';

export interface circleForumStateType {
  error?: boolean;
  data?: any;
}

const initialState = {};

const handlerMap = new Map([
  [
    combineActions(signInSuccess, signInFailure),
    (state, {payload}) => {
      return {
        ...state,
        ...payload,
      };
    },
  ],
]);

export default handleActions(handlerMap, initialState);
