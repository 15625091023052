export const toastMessages = {
  dataFetching: 'Oops, something went wrong while loading your data. Try reloading the page later',
  saveOperation: 'Oops, something went wrong while saving your information. Please try again later',
  signUp: 'Oops, something went wrong. Please check your information and try again',
  error: 'Oops, something went wrong. Please try again later',
  //profile img upload error messages on Account page
  maxNumber: 'Please select 1 image',
  acceptType:
    'Your selected file type is not allowed. Please select an image in jpg, png or jpeg format',
  maxFileSize: 'Please select a smaller image',
  resolution: 'Selected file is not match your desired resolution',
};
