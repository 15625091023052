import {
  handleBody,
  handleExceptions,
  createArrayEndpoint,
  GET,
  GET_API_MANAGEMENT,
} from '../utils/api';
import {SEARCH_DESTINATIONS_KEY, SEARCH_POSTS_KEY} from '../constants/api';

export const getSuggestions = () => {
  const endpoint = 'search/suggestions';

  return GET_API_MANAGEMENT(endpoint, {apiKey: SEARCH_DESTINATIONS_KEY}).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const getDestinations = (data: {
  top: number;
  search: string;
  type: string;
  subType: string;
}) => {
  const params = {
    'api-version': '2020-06-30',
    suggesterName: `accessiblego-search-index-${data.subType}-suggester`,
    $top: data.top,
    $select: '*',
    search: `${data.search}*`,
    fuzzy: ['hotels', 'cities'].indexOf(data.subType) > -1,
    $orderby:
      data.subType == 'airports' ? 'search.score() desc' : 'rn desc, i desc, search.score() desc', //must sort server side (AND client side below)
    // highlightPreTag: '%23%23',
    // highlightPostTag: '%23%23',
  };

  const endpoint = createArrayEndpoint(
    `https://accessiblego-search-service-typeahead.search.windows.net/indexes/accessiblego-search-index-${data.type}/docs/suggest`,
    params,
  );

  return GET(endpoint, {apiKey: SEARCH_DESTINATIONS_KEY, external: true}).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const getPosts = (data: {search: string}) => {
  const params = {
    api_key: SEARCH_POSTS_KEY,
    q: `${data.search}%20after:2020-01-01%20min_posts:1`,
  };

  const endpoint = createArrayEndpoint('https://community.accessiblego.com/search.json', params);

  return GET(endpoint, {external: true}).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const getArticles = (data: {search: string}) => {
  const params = {
    phrase: data.search,
  };

  const endpoint = createArrayEndpoint(
    'https://accessiblego-admin.azurewebsites.net/Article/Search',
    params,
  );

  return GET(endpoint, {external: true}).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
