import {createSelector} from 'reselect';
import {StateShape} from '../redux/store/reducers';
import {dealsState} from '../redux/store/reducers/deals';

export const getDealsState = (state: StateShape) => state.deals;

export const getMembershipStatusState = createSelector(
  getDealsState,
  (deals: dealsState) => deals.membershipStatus,
);

export const getJoinClubStatus = createSelector(
  getDealsState,
  (deals: dealsState) => deals.joinStatus,
);

export const getOrganizationName = createSelector(
  getDealsState,
  (deals: dealsState) => deals.disabilityOrganization,
);

export const getDiscountActivationResult = createSelector(
  getDealsState,
  (deals: dealsState) => deals.oneTimeDiscount,
);

export const getReferralDiscountResult = createSelector(
  getDealsState,
  (deals: dealsState) => deals.referralDiscount,
);

export const getGivebackParticipationResult = createSelector(
  getDealsState,
  (deals: dealsState) => deals.givebackParticipant,
);

export const getUserOffersState = createSelector(
  getDealsState,
  (deals: dealsState) => deals.userOffers,
);
