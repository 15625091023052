import React from 'react';
import {useSelector} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {grayScaleModeState} from '../../../../selectors/globalConfig';
import './styles.less';

const Sidebar = () => {
  const greyMode = useSelector(grayScaleModeState);

  return (
    <ul className={`ac-sidebar ${greyMode === 'true' ? 'ac-greyMode' : ''}`}>
      <li>
        <NavLink to='/account/profile' end>
          Profile
        </NavLink>
      </li>
      <li>
        <NavLink to='/account/bookings'>Bookings</NavLink>
      </li>
      <li>
        <NavLink to='/account/favorites'>Favorites</NavLink>
      </li>
      <li>
        <NavLink to='/account/reviews'>Reviews</NavLink>
      </li>
      <li>
        <NavLink to='/account/deals'>Deals</NavLink>
      </li>
      <li>
        <NavLink to='/account/settings'>Settings</NavLink>
      </li>
    </ul>
  );
};

export default Sidebar;
