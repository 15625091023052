import {createAction} from 'redux-actions';

export const getLatestPosts = createAction('GET_LATESTPOSTS');
export const getLatestPostsSuccess = createAction('GET_LATESTPOSTS_SUCCESS', (payload) => payload);
export const getLatestPostsFailure = createAction('GET_LATESTPOSTS_FAILURE', (payload) => payload);

export const getAirTravelPosts = createAction('GET_AIR_TRAVEL_POSTS');
export const getAirTravelPostsSuccess = createAction(
  'GET_AIR_TRAVEL_POSTS_SUCCESS',
  (payload) => payload,
);
export const getAirTravelPostsFailure = createAction(
  'GET_AIR_TRAVEL_POSTS_FAILURE',
  (payload) => payload,
);
