import {handleActions, combineActions} from 'redux-actions';
import * as favorites from '../../actions/favorites';
import {
  IFavoriteItem,
  IAddFavoriteResponse,
  IRemoveFavoriteResponse,
} from '../../../interfaces/favorites';

export interface favoritesStateType {
  userFavorites: {
    error?: string | object;
    status?: string;
    errors?: null | Array<string>;
    data?: Array<IFavoriteItem>;
  };
  addUserFavorite: {error?: boolean; data?: IAddFavoriteResponse};
  removeUserFavorite: {error?: boolean; data?: IRemoveFavoriteResponse};
}

const initialState: favoritesStateType = {
  userFavorites: {},
  addUserFavorite: {},
  removeUserFavorite: {},
};

const handlerMap = new Map([
  [
    combineActions(favorites.getFavoritesSuccess, favorites.getFavoritesFailure),
    (state, {payload}) => {
      return {
        ...state,
        userFavorites: payload,
      };
    },
  ],
  [
    combineActions(favorites.addFavoriteSuccess, favorites.addFavoriteFailure),
    (state, {payload}) => {
      return {
        ...state,
        addUserFavorite: payload,
      };
    },
  ],
  [
    combineActions(favorites.removeFavoriteSuccess, favorites.removeFavoriteFailure),
    (state, {payload}) => {
      return {
        ...state,
        removeUserFavorite: payload,
      };
    },
  ],
]);

export default handleActions(handlerMap, initialState);
