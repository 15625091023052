import React, {memo, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Switch from 'react-switch';

import {updateUserPreferences, deleteUserPreferences} from '../../../../../redux/actions/account';
import {getUserPreferencesState} from '../../../../../selectors/account';
import {grayScaleModeState} from '../../../../../selectors/globalConfig';

const travelRequirements = [
  {option: 'I am a wheelchair user', id: 'WheelchairUser', checked: false},
  {option: 'I have a mobility disability', id: 'MobilityDisability', checked: false},
  {option: 'I am blind or have low vision', id: 'VisionDisability', checked: false},
  {option: 'I am deaf or hard of hearing', id: 'HearingDisability', checked: false},
  {option: 'I travel with a service animal', id: 'ServiceAnimal', checked: false},
  {option: 'I am assisting a family member or friend', id: 'Assistant', checked: false},
];

interface Requirements {
  option: string;
  id: string;
  checked: boolean;
}

const TravelRequirements = () => {
  const [selected, setSelected] = useState<Array<Requirements>>(travelRequirements);

  const dispatch = useDispatch();

  const greyMode = useSelector(grayScaleModeState);
  const preferencesState = useSelector(getUserPreferencesState);

  const handlePreferences = () => {
    const preferenceTypes: Array<string> = preferencesState.data.data.length
      ? preferencesState.data.data.map((item) => item.type)
      : [];

    setSelected((currentState) =>
      currentState.filter((item) => {
        if (preferenceTypes.some((i) => i === item.id)) item.checked = true;
        return item;
      }),
    );
  };

  useEffect(() => {
    if (preferencesState.data.data) {
      handlePreferences();
    }
  }, [preferencesState]);

  const handleSelect = (checked: boolean, e: any, id: string): void => {
    setSelected(
      selected.map((el) => {
        if (el.id === id) {
          return {...el, checked: checked};
        }
        return el;
      }),
    );
    if (checked) {
      dispatch(
        updateUserPreferences({
          category: 'TravelRequirement',
          type: id,
        }),
      );
    } else {
      dispatch(
        deleteUserPreferences({
          category: 'TravelRequirement',
          type: id,
        }),
      );
    }
  };

  return (
    <section className={greyMode === 'true' ? 'ac-greyMode' : ''}>
      <h3>Travel Requirements</h3>
      <ul>
        {selected.map((item) => (
          <li key={item.id}>
            <Switch
              className='switcher'
              title={item.option}
              onChange={handleSelect}
              checked={item.checked}
              onColor='#29807c'
              offColor='#4d4d4d'
              offHandleColor='#fff'
              onHandleColor='#fff'
              uncheckedIcon={false}
              // checkedIcon={false}
              activeBoxShadow='0px 0px 0px 0px rgba(255, 255, 255, 0.2)'
              height={20}
              width={40}
              id={item.id}
            />
            {item.option}
          </li>
        ))}
      </ul>
    </section>
  );
};

export default memo(TravelRequirements);
