import {handleBody, handleExceptions, GET, POST, createArrayEndpoint} from '../utils/api';

//Profile
export const getBookings = () => {
  const endpoint = 'user/getUserBookings';

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

//Hotel booking
export const getCurrentBooking = (data: {
  bid: string;
  e: string;
}): Promise<void | Response | object | null> => {
  const endpoint = createArrayEndpoint('hotel/getBooking', {...data, c: 'VIEW'});

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const getConfirmation = (data: {
  bid: string;
  e: string;
}): Promise<void | Response | object | null> => {
  const endpoint = createArrayEndpoint('hotel/getConfirmation', data);

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const cancelBooking = (data: {
  ppn_bundle: string;
  ppnBookingId: string;
}): Promise<void | Response | object | null> => {
  const endpoint = 'hotels/cancelbooking';
  const options = {
    body: data,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
