import {takeLatest, call, put} from 'redux-saga/effects';
import * as favorites from '../actions/favorites';
import {addUserFavorite, getFavorites, removeUserFavorite} from '../../api/favorites';

export function* getFavoritesWorker() {
  try {
    const data = yield call(getFavorites);
    const formatedData = JSON.parse(data.substring(5));

    yield put(favorites.getFavoritesSuccess(formatedData));
  } catch (e) {
    yield put(favorites.getFavoritesFailure({error: true}));
  }
}

export function* getFavoritesWatcher() {
  yield takeLatest(favorites.getFavorites.toString(), getFavoritesWorker);
}

export function* addFavoriteWorker({payload}) {
  try {
    const data = yield call(addUserFavorite, payload);
    const res = JSON.parse(data.substring(5));

    if (res.status && res.status === 'success') {
      yield put(favorites.getFavorites());
      yield put(favorites.addFavoriteSuccess({error: false, data: res}));
    } else {
      yield put(favorites.addFavoriteFailure({error: true, data: res}));
    }
  } catch (e) {
    yield put(favorites.addFavoriteFailure({error: true, data: {}}));
  }
}

export function* addFavoriteWatcher() {
  yield takeLatest(favorites.addFavorite.toString(), addFavoriteWorker);
}

export function* removeFavoriteWorker({payload}) {
  try {
    const data = yield call(removeUserFavorite, payload);
    const res = JSON.parse(data.substring(5));

    if (res.status && res.status === 'success') {
      yield put(favorites.getFavorites());
      yield put(favorites.removeFavoriteSuccess({error: false, data: res}));
    } else {
      yield put(favorites.removeFavoriteFailure({error: true, data: res}));
    }
  } catch (e) {
    yield put(favorites.removeFavoriteFailure({error: true, data: {}}));
  }
}

export function* removeFavoriteWatcher() {
  yield takeLatest(favorites.removeFavorite.toString(), removeFavoriteWorker);
}
