import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import ImageUploading, {ImageListType, ErrorsType} from 'react-images-uploading';

import {getUser} from '../../../../../../selectors/auth';
import {grayScaleModeState} from '../../../../../../selectors/globalConfig';

import Image from '../../../../../../components/UI/Image';
import photoIcon from '../../../../../../../assets/photo-icon.svg';
import upload from '../../../../../../../assets/upload.svg';
import uploading from '../../../../../../../assets/uploading.svg';
import './styles.less';

interface IIMGtoUpload {
  dataURL: string;
}

interface IProps {
  setExternalError: (value: boolean) => void;
  setExternalErrorType: (value: string) => void;
}

//to do - saving on the server
const ProfilePhoto = ({setExternalError, setExternalErrorType}: IProps): JSX.Element => {
  const [images, setImages] = useState<IIMGtoUpload[]>([]);

  const greyMode = useSelector(grayScaleModeState);
  const userData = useSelector(getUser);

  useEffect(() => {
    if (userData.data.picture && userData.data.picture.length !== 0) {
      setImages([...images, {dataURL: userData.data.picture}]);
    }
  }, [userData]);

  const maxNumber = 1;
  const acceptType = ['jpg', 'png', 'jpeg'];

  const onChange = (imageList: ImageListType): void => {
    setImages(() => {
      return imageList as IIMGtoUpload[];
    });
  };

  const errorFunction = (errors: ErrorsType): void => {
    if (errors) {
      setExternalError(true);
      setExternalErrorType(Object.keys(errors)[0]);
    }
  };

  return (
    <ImageUploading
      value={images}
      onChange={onChange}
      maxNumber={maxNumber}
      acceptType={acceptType}
      onError={errorFunction}
      resolutionWidth={300}
      resolutionHeight={300}
    >
      {({imageList, onImageUpload, onImageUpdate, isDragging, dragProps}) => (
        <div className={`ac-upload ${greyMode === 'true' ? 'ac-greyMode' : ''}`}>
          {!imageList.length && (
            <button
              onClick={onImageUpload}
              {...dragProps}
              className={`ac-upload__add-img ${isDragging ? 'ac-upload__add-img--drag' : ''}`}
            >
              {isDragging ? (
                <Image
                  attr={{
                    src: uploading,
                    alt: 'uploading',
                    width: '207',
                    height: '120',
                  }}
                />
              ) : (
                <Image
                  attr={{
                    src: photoIcon,
                    alt: 'avatar',
                    width: '207',
                    height: '120',
                  }}
                />
              )}
            </button>
          )}

          {imageList.map((image, index) => (
            <div key={index}>
              <div className='ac-upload__image-item'>
                <Image
                  attr={{
                    src: image.dataURL,
                    width: '207',
                    height: '120',
                  }}
                />
                <button
                  className='ac-upload__image-item--hover'
                  onClick={() => {
                    onImageUpdate(index);
                  }}
                >
                  <Image
                    attr={{
                      src: upload,
                      alt: 'edit-profile-avatar',
                      width: '40',
                      height: '32',
                    }}
                  />
                  <p>Click to change photo</p>
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </ImageUploading>
  );
};

export default ProfilePhoto;
