import {takeLatest, call, put} from 'redux-saga/effects';
import * as airlineReview from '../actions/flightReview';
import {addFlightReview, getAirlineReview, getAirlines, getAirports} from '../../api/flightReview';

export function* getAirportsWorker() {
  try {
    const res = yield call(getAirports);

    if (res.results) {
      yield put(airlineReview.getAirportsSuccess({error: false, data: res}));
    } else {
      yield put(airlineReview.getAirportsFailure({error: true, data: res}));
    }
  } catch (e) {
    yield put(airlineReview.getAirportsFailure({error: true, data: {}}));
  }
}

export function* getAirlinesWorker() {
  try {
    const res = yield call(getAirlines);

    if (res.results) {
      yield put(airlineReview.getAirlinesSuccess({error: false, data: res}));
    } else {
      yield put(airlineReview.getAirlinesFailure({error: true, data: res}));
    }
  } catch (e) {
    yield put(airlineReview.getAirlinesFailure({error: true, data: {}}));
  }
}

export function* addFlightReviewWorker({payload}) {
  try {
    const res = yield call(addFlightReview, payload);

    if (res.data) {
      yield put(airlineReview.addFlightReviewSuccess({error: false, data: res}));
    } else {
      yield put(airlineReview.addFlightReviewFailure({error: true, data: res}));
    }
  } catch (e) {
    yield put(airlineReview.addFlightReviewFailure({error: true, data: {}}));
  }
}

export function* getAirlineReviewWorker({payload}) {
  try {
    const res = yield call(getAirlineReview, payload);

    if (res.data) {
      yield put(airlineReview.getAirlineReviewSuccess({error: false, data: res}));
    } else {
      yield put(airlineReview.getAirlineReviewFailure({error: true, data: res}));
    }
  } catch (e) {
    yield put(airlineReview.getAirlineReviewFailure({error: true, data: {}}));
  }
}

export function* getAirportsWatcher() {
  yield takeLatest(airlineReview.getAirports.toString(), getAirportsWorker);
  yield takeLatest(airlineReview.getAirlines.toString(), getAirlinesWorker);
  yield takeLatest(airlineReview.addFlightReview.toString(), addFlightReviewWorker);
  yield takeLatest(airlineReview.getAirlineReview.toString(), getAirlineReviewWorker);
}
