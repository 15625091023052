import {takeLatest, call, put} from 'redux-saga/effects';
import * as rentalCar from '../actions/rentalCar';
import {
  cancelReservation,
  getContractData,
  getRentalConfirmation,
  rentCar,
  searchCars,
  sendEmail,
} from '../../api/rentalCar';
import {getUserOffers} from '../actions/deals';

export function* searchCarWorker({payload}) {
  if (payload) {
    try {
      const res = yield call(searchCars, payload);

      if (res.status && res.status === 'success') {
        yield put(rentalCar.searchCarSuccess({error: false, data: res}));
      } else {
        yield put(rentalCar.searchCarFailure({error: true, data: res}));
      }
    } catch (e) {
      yield put(rentalCar.searchCarFailure({error: true, data: {}}));
    }
  } else {
    yield put(rentalCar.searchCarSuccess({error: false, data: {}}));
  }
}

export function* getContractDataWorker({payload}) {
  if (payload) {
    try {
      const data = yield call(getContractData, payload);

      if (data.status) {
        yield put(rentalCar.getContractDataSuccess({error: false, data: data}));
      } else {
        yield put(rentalCar.getContractDataFailure({error: true, data: {}}));
      }
    } catch (e) {
      yield put(rentalCar.getContractDataFailure({error: true, data: {}}));
    }
  } else {
    yield put(rentalCar.getContractDataSuccess({error: false, data: {}}));
  }
}

export function* rentCarWorker({payload}) {
  if (payload) {
    try {
      const data = yield call(rentCar, payload);

      if (data.status) {
        if (data.status === 'success') {
          yield put(rentalCar.rentCarSuccess({error: false, data: data}));
          yield put(getUserOffers());
        } else {
          yield put(rentalCar.rentCarFailure({error: true, data: data}));
        }
      } else {
        yield put(rentalCar.rentCarFailure({error: true, data: {}}));
      }
    } catch (e) {
      yield put(rentalCar.rentCarFailure({error: true, data: {}}));
    }
  } else {
    yield put(rentalCar.rentCarSuccess({error: false, data: {}}));
  }
}

export function* getRentalConfirmationWorker({payload}) {
  if (payload) {
    try {
      const res = yield call(getRentalConfirmation, payload);

      if (res.status) {
        if (res.status === 'success') {
          yield put(rentalCar.getRentalConfirmationSuccess({error: false, data: res}));
        } else {
          yield put(rentalCar.getRentalConfirmationFailure({error: true, data: res}));
        }
      }
    } catch (e) {
      yield put(rentalCar.getRentalConfirmationFailure({error: true, data: {}}));
    }
  } else {
    yield put(rentalCar.getRentalConfirmationSuccess({error: false, data: {}}));
  }
}

export function* cancelReservationWorker({payload}) {
  if (payload) {
    try {
      const data = yield call(cancelReservation, payload);

      if (data.status) {
        if (data.status === 'success') {
          yield put(rentalCar.cancelReservationSuccess({error: false, data: data}));
        } else {
          yield put(rentalCar.cancelReservationFailure({error: true, data: data}));
        }
      } else {
        yield put(rentalCar.cancelReservationFailure({error: true, data: {}}));
      }
    } catch (e) {
      yield put(rentalCar.cancelReservationFailure({error: true, data: {}}));
    }
  } else {
    yield put(rentalCar.cancelReservationSuccess({error: false, data: {}}));
  }
}

export function* sendEmailWorker({payload}) {
  if (payload) {
    try {
      const data = yield call(sendEmail, payload);

      if (data.status) {
        if (data.status === 'success') {
          yield put(rentalCar.sendEmailSuccess({error: false, data: data}));
        } else {
          yield put(rentalCar.sendEmailFailure({error: true, data: data}));
        }
      } else {
        yield put(rentalCar.sendEmailFailure({error: true, data: {}}));
      }
    } catch (e) {
      yield put(rentalCar.sendEmailFailure({error: true, data: {}}));
    }
  } else {
    yield put(rentalCar.sendEmailSuccess({error: false, data: {}}));
  }
}

export function* getRentalCarWatcher() {
  yield takeLatest(rentalCar.searchCar.toString(), searchCarWorker);
  yield takeLatest(rentalCar.getContractData.toString(), getContractDataWorker);
  yield takeLatest(rentalCar.rentCar.toString(), rentCarWorker);
  yield takeLatest(rentalCar.getRentalConfirmation.toString(), getRentalConfirmationWorker);
  yield takeLatest(rentalCar.cancelReservation.toString(), cancelReservationWorker);
  yield takeLatest(rentalCar.sendEmail.toString(), sendEmailWorker);
}
