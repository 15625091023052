import {takeLatest, call, put} from 'redux-saga/effects';
import {getCachedData, addDataIntoCache} from '../../utils/api';
import * as search from '../actions/hotelsSearch';
import {getAccessibilityData, getHotels2} from '../../api/hotelsSearch';

//search
// export function* getHotelsWorker({payload}) {
//   if (payload) {
//     try {
//       let res;
//       const searchCacheName = 'searchQueries';
//       const hotelsCacheName = 'hotels';
//       const url = window.origin;

//       const searchQueries = yield call(getCachedData, searchCacheName, url);
//       const data = yield call(getCachedData, hotelsCacheName, url);
//       const dataStorageTime =
//         (new Date().getTime() - new Date(searchQueries.ts).getTime()) / (1000 * 60);

//       if (
//         searchQueries &&
//         data &&
//         dataStorageTime &&
//         Object.entries(searchQueries.search).toString() === Object.entries(payload).toString() &&
//         dataStorageTime < 3
//       ) {
//         res = data;
//       } else {
//         yield call(addDataIntoCache, searchCacheName, url, {ts: new Date(), search: payload});
//         res = yield call(getHotels, payload);
//         yield call(addDataIntoCache, hotelsCacheName, url, res);

//         sessionStorage.setItem('tsSearch', new Date().toISOString());
//       }

//       if (res.data.status === 'success') {
//         yield put(search.getHotelsSuccess({error: false, data: res.data.data.results}));
//       } else {
//         yield put(search.getHotelsFailure({error: true, data: res.data}));
//       }
//     } catch (e) {
//       yield put(search.getHotelsFailure({error: true, data: []}));
//     }
//   } else {
//     yield put(search.getHotelsSuccess({error: false, data: {}}));
//   }
// }

// export function* getHotelsWatcher() {
//   yield takeLatest(search.getHotels.toString(), getHotelsWorker);
// }

//search2
export function* getHotelsWorker({payload}) {
  if (payload) {
    try {
      let res;
      const searchCacheName = 'searchQueries';
      const hotelsCacheName = 'hotels';
      const url = window.origin;

      const searchQueries = yield call(getCachedData, searchCacheName, url);
      const data = yield call(getCachedData, hotelsCacheName, url);
      const dataStorageTime =
        (new Date().getTime() - new Date(searchQueries.ts).getTime()) / (1000 * 60);

      if (
        searchQueries &&
        data &&
        dataStorageTime &&
        Object.entries(searchQueries.search).toString() === Object.entries(payload).toString() &&
        dataStorageTime < 3
      ) {
        res = data;
      } else {
        yield call(addDataIntoCache, searchCacheName, url, {ts: new Date(), search: payload});
        res = yield call(getHotels2, payload);
        yield call(addDataIntoCache, hotelsCacheName, url, res);

        sessionStorage.setItem('tsSearch', new Date().toISOString());
      }

      if (res.status && res.status === 'success') {
        yield put(search.getHotelsSuccess({error: false, data: res}));
      } else {
        yield put(search.getHotelsFailure({error: true, data: res}));
      }
    } catch (e) {
      yield put(search.getHotelsFailure({error: true, data: {}}));
    }
  } else {
    yield put(search.getHotelsSuccess({error: false, data: {}}));
  }
}

export function* getHotelsWatcher() {
  yield takeLatest(search.getHotels.toString(), getHotelsWorker);
}

//accessibility data
export function* getAccessibilityDataWorker({payload}) {
  if (payload) {
    try {
      let res;
      const cacheName = 'acHotels';
      const url = window.origin;

      const data = yield call(getCachedData, cacheName, url);
      const dataStorageTime = (new Date().getTime() - new Date(data.ts).getTime()) / (1000 * 60);

      if (!data || data.hotels.length === 0 || dataStorageTime > 60) {
        res = yield call(getAccessibilityData, payload);
        yield call(addDataIntoCache, cacheName, url, res);
      } else {
        const storedHotels = data.hotels.map((hotel) => hotel.ppnId);

        const newHotels = payload.filter((hotel) => !storedHotels.includes(hotel));
        const restHotels = payload.filter((hotel) => storedHotels.includes(hotel));

        let newRes;

        if (newHotels.length > 0 && restHotels.length > 0) {
          newRes = yield call(getAccessibilityData, newHotels);

          res = {
            ...newRes,
            hotels: [...data.hotels, ...newRes.hotels],
          };

          yield call(addDataIntoCache, cacheName, url, {
            ...res,
            hotels: [...data.hotels, ...newRes.hotels],
          });
        } else if (newHotels.length > 0 && restHotels.length === 0) {
          newRes = yield call(getAccessibilityData, newHotels);

          res = newRes;
          yield call(addDataIntoCache, cacheName, url, res);
        } else if (newHotels.length === 0 && restHotels.length > 0) {
          res = {
            ...data,
            hotels: [...data.hotels.filter((hotel) => restHotels.includes(hotel.ppnId))],
          };
          yield call(addDataIntoCache, cacheName, url, res);
        }
      }

      if (res.state === 'success') {
        yield put(search.getAccessibilityDataSuccess({error: false, data: res}));
      } else {
        yield put(search.getAccessibilityDataFailure({error: true, data: {}}));
      }
    } catch (e) {
      yield put(search.getAccessibilityDataFailure({error: true, data: {}}));
    }
  } else {
    yield put(search.getAccessibilityDataFailure({error: false, data: {}}));
  }
}

export function* getAccessibilityDataWatcher() {
  yield takeLatest(search.getAccessibilityData.toString(), getAccessibilityDataWorker);
}
