import {createAction} from 'redux-actions';

const PREFIX = 'SEARCH_';

//search
export const getHotels = createAction(`${PREFIX}GET_HOTELS`);
export const getHotelsSuccess = createAction(`${PREFIX}GET_HOTELS_SUCCESS`, (payload) => payload);
export const getHotelsFailure = createAction(`${PREFIX}GET_HOTELS_FAILURE`, (payload) => payload);

//accessibility data
export const getAccessibilityData = createAction(`${PREFIX}GET_ACCESSIBILITY_DATA`);
export const getAccessibilityDataSuccess = createAction(
  `${PREFIX}GET_ACCESSIBILITY_DATA_SUCCESS`,
  (payload) => payload,
);
export const getAccessibilityDataFailure = createAction(
  `${PREFIX}GET_ACCESSIBILITY_DATA_FAILURE`,
  (payload) => payload,
);
