import {handleBody, handleExceptions, GET, POST} from '../utils/api';

export const getFavorites = () => {
  const endpoint = 'user/getUserFavorites';

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const addUserFavorite = (data: {
  type: string;
  referenceId: string;
}): Promise<void | Response | object | null> => {
  const endpoint = 'user/addUserFavorite';
  const options = {
    body: data,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const removeUserFavorite = (data: {
  userFavoriteId: string;
}): Promise<void | Response | object | null> => {
  const endpoint = 'user/removeUserFavorite';
  const options = {
    body: data,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
