import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Select from 'react-select';
import {Form, OverlayTrigger, Tooltip} from 'react-bootstrap';

import Image from '../../../../../components/UI/Image';
import edit from '../../../../../../assets/edit.svg';
import './styles.less';

import {updateUserBio} from '../../../../../redux/actions/account';
import {getUserBioState} from '../../../../../selectors/account';
import {grayScaleModeState} from '../../../../../selectors/globalConfig';

const age_options = [
  {value: '< 18', label: '< 18', id: 'age'},
  {value: '18 - 25', label: '18 - 25', id: 'age'},
  {value: '26 - 40', label: '26 - 40', id: 'age'},
  {value: '41 - 55', label: '41 - 55', id: 'age'},
  {value: '56 - 70', label: '56 - 70', id: 'age'},
  {value: '> 70', label: '> 70', id: 'age'},
];

const gender_options = [
  {value: 'Female', label: 'Female', id: 'gender'},
  {value: 'Male', label: 'Male', id: 'gender'},
  {value: 'Custom', label: 'Custom', id: 'gender'},
];

const travelCount_options = [
  {value: '1 - 2 times per year', label: '1 - 2 times per year', id: 'travelCount'},
  {value: '3 - 5 times per year', label: '3 - 5 times per year', id: 'travelCount'},
  {value: '> 5 times per year', label: '> 5 times per year', id: 'travelCount'},
];

interface Option {
  value: string;
  label: string;
  id: string;
}

interface Editor {
  editAge: boolean;
  editGender: boolean;
  editCounter: boolean;
  editShortBio: boolean;
}

const PersonalBio = () => {
  const [age, setAge] = useState<Option>({value: '', label: '', id: ''});
  const [gender, setGender] = useState<Option>({value: '', label: '', id: ''});
  const [travelCount, setTravelCount] = useState<Option>({value: '', label: '', id: ''});
  const [travelReason, setTravelReason] = useState<string>('');
  const [shortBio, setShortBio] = useState<string>('');
  const [isEdit, setIsEdit] = useState<Editor>({
    editAge: false,
    editGender: false,
    editCounter: false,
    editShortBio: false,
  });

  const dispatch = useDispatch();
  const greyMode = useSelector(grayScaleModeState);
  const userBioState = useSelector(getUserBioState);

  useEffect(() => {
    if (userBioState.data) {
      const {data} = userBioState.data;

      if (data) {
        if (data.ageRange) setAge({value: data.ageRange, label: data.ageRange, id: 'age'});
        if (data.gender) setGender({value: data.gender, label: data.gender, id: 'gender'});
        if (data.travelFrequency) {
          setTravelCount({
            value: data.travelFrequency,
            label: data.travelFrequency,
            id: 'travelCount',
          });
        }
        if (data.travelPurpose) setTravelReason(data.travelPurpose);
        if (data.bio) setShortBio(data.bio ? data.bio : '');
      }
    }
  }, [userBioState]);

  const selectChange = (option: {value: string; label: string; id: string}) => {
    if (option.id === 'age') {
      setAge(option);
      dispatch(
        updateUserBio({
          ageRange: option.value.length > 0 ? option.value : null,
          fieldType: 'ageRange',
        }),
      );
      setIsEdit({...isEdit, editAge: false});
    }
    if (option.id === 'gender') {
      setGender(option);
      dispatch(
        updateUserBio({
          gender: option.value.length > 0 ? option.value : null,
          fieldType: 'gender',
        }),
      );
      setIsEdit({...isEdit, editGender: false});
    }
    if (option.id === 'travelCount') {
      setTravelCount(option);
      dispatch(
        updateUserBio({
          travelFrequency: option.value.length > 0 ? option.value : null,
          fieldType: 'travelFrequency',
        }),
      );
      setIsEdit({...isEdit, editCounter: false});
    }
  };

  const handleEdit = (e): void => {
    if (e.target.id === 'age') setIsEdit({...isEdit, editAge: true});
    if (e.target.id === 'gender') setIsEdit({...isEdit, editGender: true});
    if (e.target.id === 'travelAmount') setIsEdit({...isEdit, editCounter: true});
    if (e.target.id === 'shortBioEdit') setIsEdit({...isEdit, editShortBio: true});
    if (e.target.id === 'shortBio') setShortBio(e.target.value);
    if (e.target.id === 'cancelShortBioEdit') setIsEdit({...isEdit, editShortBio: false});
  };

  const saveShortBio = (): void => {
    setIsEdit({...isEdit, editShortBio: false});
    dispatch(
      updateUserBio({
        bio: shortBio.length > 0 ? shortBio : null,
        fieldType: 'bio',
      }),
    );
  };

  const handleCheck = (e): void => {
    const id = e.target.id;
    setTravelReason(id);

    dispatch(
      updateUserBio({
        travelFrequency: id.length > 0 ? id : null,
        fieldType: 'travelPurpose',
      }),
    );
  };

  const cancelEdit = (e): void => {
    const id = e.target.id;
    if (id === 'editAge') setIsEdit({...isEdit, editAge: false});
    if (id === 'editGender') setIsEdit({...isEdit, editGender: false});
    if (id === 'editCounter') setIsEdit({...isEdit, editCounter: false});
    if (id === 'editShortBio') setIsEdit({...isEdit, editShortBio: false});
  };

  return (
    <section className={`personal-bio ${greyMode === 'true' ? 'ac-greyMode' : ''}`}>
      <h3>Personal Bio</h3>
      <div className='ac-account__label-block personal-bio__select-label'>
        <p>
          Age<span>{age.value.length > 0 && `: ${age.value}`}</span>
        </p>
        <OverlayTrigger
          placement='top'
          overlay={<Tooltip className='ac-tooltip'>Click to edit</Tooltip>}
        >
          <button onClick={handleEdit} title='edit age'>
            <Image
              attr={{
                src: edit,
                alt: 'edit',
                width: '17',
                height: '17',
              }}
              id='age'
            />
          </button>
        </OverlayTrigger>
      </div>
      {isEdit.editAge && (
        <div className='ac-account__input-block ac-account__input-block--select'>
          <Select
            options={age_options}
            value={age}
            onChange={selectChange}
            placeholder='Choose an option...'
            className='personal-bio__profile-select'
            classNamePrefix='personal-bio'
            data-testid='age-select'
          />
          <button onClick={cancelEdit} id='editAge'>
            cancel
          </button>
        </div>
      )}

      <div className='ac-account__label-block personal-bio__select-label'>
        <p>
          Gender<span>{gender.value.length > 0 && `: ${gender.value}`}</span>
        </p>
        <OverlayTrigger
          placement='top'
          overlay={<Tooltip className='ac-tooltip'>Click to edit</Tooltip>}
        >
          <button onClick={handleEdit} title='edit gender'>
            <Image
              attr={{
                src: edit,
                alt: 'edit',
                width: '17',
                height: '17',
              }}
              id='gender'
            />
          </button>
        </OverlayTrigger>
      </div>
      {isEdit.editGender && (
        <div className='ac-account__input-block ac-account__input-block--select'>
          <OverlayTrigger
            placement='top'
            overlay={
              <Tooltip className='ac-tooltip'>
                Select Custom to choose another gender, or if you would rather not say.
              </Tooltip>
            }
          >
            <Select
              options={gender_options}
              value={gender}
              onChange={selectChange}
              placeholder='Choose an option...'
              className='personal-bio__profile-select'
              classNamePrefix='personal-bio'
              data-testid='gender-select'
            />
          </OverlayTrigger>
          <button onClick={cancelEdit} id='editGender'>
            cancel
          </button>
        </div>
      )}

      <div className='ac-account__label-block personal-bio__select-label'>
        <p>
          How often do you travel?
          <span>{travelCount.value.length > 0 && ` ${travelCount.value}`}</span>
        </p>
        <OverlayTrigger
          placement='top'
          overlay={<Tooltip className='ac-tooltip'>Click to edit</Tooltip>}
        >
          <button onClick={handleEdit} title='edit travel frequency'>
            <Image
              attr={{
                src: edit,
                alt: 'edit',
                width: '17',
                height: '17',
              }}
              id='travelAmount'
            />
          </button>
        </OverlayTrigger>
      </div>
      {isEdit.editCounter && (
        <div className='ac-account__input-block ac-account__input-block--select'>
          <Select
            options={travelCount_options}
            value={travelCount}
            onChange={selectChange}
            placeholder='Choose an option...'
            className='personal-bio__profile-select'
            classNamePrefix='personal-bio'
            data-testid='travelCount-select'
          />
          <button onClick={cancelEdit} id='editCounter'>
            cancel
          </button>
        </div>
      )}

      <div className='personal-bio__radio-select'>
        <fieldset>
          <legend>I travel mostly for:</legend>

          <Form.Check
            inline
            label='Business'
            name='travel-reason'
            type='radio'
            onChange={handleCheck}
            id='Business'
            checked={travelReason === 'Business'}
          />
          <Form.Check
            inline
            label='Personal'
            name='travel-reason'
            type='radio'
            onChange={handleCheck}
            id='Personal'
            checked={travelReason === 'Personal'}
          />
          <Form.Check
            inline
            label='Both'
            name='travel-reason'
            type='radio'
            onChange={handleCheck}
            id='Both'
            checked={travelReason === 'Both'}
          />
        </fieldset>
      </div>

      <div className='ac-account__label-block personal-bio__select-label'>
        <p>Short Bio</p>
        <OverlayTrigger
          placement='top'
          overlay={<Tooltip className='ac-tooltip'>Click to edit</Tooltip>}
        >
          <button onClick={handleEdit} title='edit bio'>
            <Image
              attr={{
                src: edit,
                alt: 'edit',
                width: '17',
                height: '17',
              }}
              id='shortBioEdit'
            />
          </button>
        </OverlayTrigger>
      </div>
      {isEdit.editShortBio ? (
        <>
          <textarea value={shortBio} onChange={handleEdit} id='shortBio' />
          <div className='buttons-block'>
            <button onClick={saveShortBio}>save</button>
            <button onClick={handleEdit} id='cancelShortBioEdit'>
              cancel
            </button>
          </div>
        </>
      ) : (
        shortBio && shortBio.length > 0 && <p>{shortBio}</p>
      )}
    </section>
  );
};

export default PersonalBio;
