import {takeLatest, call, put} from 'redux-saga/effects';
import {addDataIntoCache} from '../../utils/api';
import * as hotel from '../actions/hotel';
import {getHotelDetails, getRoomsData} from '../../api/hotel';

export function* getHotelDetailsWorker({payload}) {
  if (payload) {
    try {
      const data = yield call(getHotelDetails, payload);

      if (data.status) {
        yield put(hotel.getHotelDetailsSuccess({error: false, data: data}));
      } else {
        yield put(hotel.getHotelDetailsFailure({error: true, data: {}}));
      }
    } catch (e) {
      yield put(hotel.getHotelDetailsFailure({error: true, data: {}}));
    }
  } else {
    yield put(hotel.getHotelDetailsSuccess({error: false, data: {}}));
  }
}

export function* getHotelDetailsWatcher() {
  yield takeLatest(hotel.getHotelDetails.toString(), getHotelDetailsWorker);
}

export function* getRoomsDataWorker({payload}) {
  if (payload) {
    try {
      const data = yield call(getRoomsData, payload);
      yield call(addDataIntoCache, 'searchQueriesHotel', window.origin, payload);

      if (data.status === 'success' || data.data) {
        yield put(hotel.getRoomsDataSuccess({error: false, data: data}));
      } else {
        yield put(hotel.getRoomsDataFailure({error: true, data: data}));
      }
    } catch (e) {
      yield put(hotel.getRoomsDataFailure({error: true, data: {}}));
    }
  } else {
    yield put(hotel.getRoomsDataSuccess({}));
  }
}

export function* getRoomsDataWatcher() {
  yield takeLatest(hotel.getRoomsData.toString(), getRoomsDataWorker);
}
