import {takeLatest, call, put} from 'redux-saga/effects';
import * as discourse from '../actions/forumDiscourse';
import {getDiscoursePayload} from '../../api/forumDiscourse';

export function* getDiscoursePayloadWorker({payload}) {
  try {
    const res = yield call(getDiscoursePayload, payload);
    yield put(discourse.getDiscoursePayloadSuccess({error: false, data: res}));
  } catch (e) {
    yield put(discourse.getDiscoursePayloadFailure({error: true, data: {}}));
  }
}

export function* getDiscoursePayloadWatcher() {
  yield takeLatest(discourse.getDiscoursePayload.toString(), getDiscoursePayloadWorker);
}
