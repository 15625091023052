import {takeLatest, call, put} from 'redux-saga/effects';
import {getCachedData, addDataIntoCache} from '../../utils/api';
import * as reviews from '../actions/reviews';
import * as apiReviews from '../../api/reviews';

export function* getUserReviewsWorker() {
  try {
    const data = yield call(apiReviews.getUserReviews);
    const formatedData = JSON.parse(data.substring(5));

    yield put(reviews.getUserReviewsSuccess(formatedData));
  } catch (e) {
    yield put(reviews.getUserReviewsFailure({error: true}));
  }
}

export function* getUserReviewsWatcher() {
  yield takeLatest(reviews.getUserReviews.toString(), getUserReviewsWorker);
}

export function* getAllReviewsWorker() {
  try {
    let res;
    const cacheName = 'reviews';
    const url = window.origin;

    const savedData = yield call(getCachedData, cacheName, url);
    const dataStorageTime =
      (new Date().getTime() - new Date(savedData.ts).getTime()) / (1000 * 60 * 60 * 24);

    if (savedData && dataStorageTime && dataStorageTime < 1) {
      res = savedData.data;
    } else {
      res = yield call(apiReviews.getAllReviews);
      yield call(addDataIntoCache, cacheName, url, {ts: new Date(), data: res});
    }

    if (res.data) {
      yield put(reviews.getAllReviewsSuccess({error: false, data: res.data}));
    } else {
      yield put(reviews.getAllReviewsFailure({error: true, data: []}));
    }
  } catch (e) {
    yield put(reviews.getAllReviewsFailure({error: true, data: []}));
  }
}

export function* getAllReviewsWatcher() {
  yield takeLatest(reviews.getAllReviews.toString(), getAllReviewsWorker);
}

export function* getHotelDetailsWorker({payload}) {
  try {
    const res = yield call(apiReviews.getHotelDetails, payload);

    if (res.hotelid_ppn) {
      yield put(reviews.getHotelDetailsSuccess({error: false, data: res}));
    } else {
      yield put(reviews.getHotelDetailsFailure({error: true, data: res}));
    }
  } catch (e) {
    yield put(reviews.getHotelDetailsFailure({error: true, data: {}}));
  }
}

export function* getHotelDetailsWatcher() {
  yield takeLatest(reviews.getHotelDetails.toString(), getHotelDetailsWorker);
}

export function* addHotelReviewWorker({payload}) {
  try {
    const res = yield call(apiReviews.addHotelReview, payload);
    const formatedData = JSON.parse(res.substring(5));

    if (formatedData.status === 'success') {
      yield put(reviews.addHotelReviewSuccess({error: false, data: formatedData}));
    } else {
      yield put(reviews.addHotelReviewFailure({error: true, data: formatedData}));
    }
  } catch (e) {
    yield put(reviews.addHotelReviewFailure({error: true, data: {}}));
  }
}

export function* addHotelReviewWatcher() {
  yield takeLatest(reviews.addHotelReview.toString(), addHotelReviewWorker);
}
