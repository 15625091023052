import {handleBody, handleExceptions, GET} from '../utils/api';

export const getPopularDestinations = () => {
  const endpoint = 'popularDestinations';

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
