import {handleActions, combineActions} from 'redux-actions';
import * as search from '../../actions/typeAheadSearch';
import {
  SuggestionOption,
  DestinationOption,
  PostOption,
  TopicOption,
  ArticleOption,
} from '../../../interfaces/typeAheadSearch';

export interface searchStateType {
  suggestions: {error: boolean; data: Array<SuggestionOption>};
  cities: {error: boolean; data: Array<DestinationOption>};
  airports: {error: boolean; data: Array<DestinationOption>};
  hotels: {error: boolean; data: Array<DestinationOption>};
  posts: {
    error: boolean;
    data: {
      posts: Array<PostOption>;
      topics: Array<TopicOption>;
    };
  };
  articles: {error: boolean; data: Array<ArticleOption>};
}

const initialState: searchStateType = {
  suggestions: {error: false, data: undefined},
  cities: {error: false, data: undefined},
  airports: {error: false, data: undefined},
  hotels: {error: false, data: undefined},
  posts: {error: false, data: undefined},
  articles: {error: false, data: undefined},
};

const handlerMap = new Map([
  [
    combineActions(search.getSuggestionsSuccess, search.getSuggestionsFailure),
    (state, {payload}) => {
      return {
        ...state,
        suggestions: payload,
      };
    },
  ],
  [
    combineActions(search.getCitiesSuccess, search.getCitiesFailure),
    (state, {payload}) => {
      return {
        ...state,
        cities: payload,
      };
    },
  ],
  [
    combineActions(search.getAirportsSuccess, search.getAirportsFailure),
    (state, {payload}) => {
      return {
        ...state,
        airports: payload,
      };
    },
  ],
  [
    combineActions(search.getHotelsSuccess, search.getHotelsFailure),
    (state, {payload}) => {
      return {
        ...state,
        hotels: payload,
      };
    },
  ],
  [
    combineActions(search.getPostsSuccess, search.getPostsFailure),
    (state, {payload}) => {
      return {
        ...state,
        posts: payload,
      };
    },
  ],
  [
    combineActions(search.getArticlesSuccess, search.getArticlesFailure),
    (state, {payload}) => {
      return {
        ...state,
        articles: payload,
      };
    },
  ],
]);

export default handleActions(handlerMap, initialState);
