import {takeLatest, call, put} from 'redux-saga/effects';
import * as bookings from '../actions/bookings';
import {cancelBooking, getBookings, getConfirmation, getCurrentBooking} from '../../api/bookings';

//Profile
export function* getBookingsWorker({payload}) {
  if (!payload) {
    try {
      const data = yield call(getBookings);
      const formatedData = JSON.parse(data.substring(5));

      yield put(bookings.getBookingsSuccess(formatedData));
    } catch (e) {
      yield put(bookings.getBookingsFailure({error: true}));
    }
  } else {
    yield put(bookings.getBookingsSuccess({}));
  }
}

export function* getBookingsWatcher() {
  yield takeLatest(bookings.getBookings.toString(), getBookingsWorker);
}

//Hotel booking
export function* getCurrentBookingWorker({payload}) {
  if (payload) {
    try {
      const res = yield call(getCurrentBooking, payload);

      if (res.data) {
        yield put(bookings.getCurrentBookingSuccess({error: false, data: res}));
      } else {
        yield put(bookings.getCurrentBookingFailure({error: true, data: res}));
      }
    } catch (e) {
      yield put(bookings.getCurrentBookingFailure({error: true}));
    }
  } else {
    yield put(bookings.getCurrentBookingSuccess({error: false, data: {}}));
  }
}

export function* getCurrentBookingWatcher() {
  yield takeLatest(bookings.getCurrentBooking.toString(), getCurrentBookingWorker);
}

export function* getBookingConfirmationWorker({payload}) {
  if (payload) {
    try {
      const res = yield call(getConfirmation, payload);

      const formatedData = JSON.parse(res.substring(5));

      if (formatedData.data) {
        yield put(bookings.getBookingConfirmationSuccess({error: false, data: formatedData}));
      } else {
        yield put(bookings.getBookingConfirmationFailure({error: true, data: formatedData}));
      }
    } catch (e) {
      yield put(bookings.getBookingConfirmationFailure({error: true}));
    }
  } else {
    yield put(bookings.getBookingConfirmationSuccess({error: false, data: {}}));
  }
}

export function* getBookingConfirmationWatcher() {
  yield takeLatest(bookings.getBookingConfirmation.toString(), getBookingConfirmationWorker);
}

export function* cancelBookingWorker({payload}) {
  if (payload) {
    try {
      const res = yield call(cancelBooking, payload);

      if (res.data) {
        yield put(bookings.cancelBookingSuccess({error: false, data: res}));
      } else {
        yield put(bookings.cancelBookingFailure({error: true, data: res}));
      }
    } catch (e) {
      const error = JSON.parse(e);

      yield put(
        bookings.cancelBookingFailure({error: true, data: error && error.message ? error : {}}),
      );
    }
  } else {
    yield put(bookings.cancelBookingSuccess({}));
  }
}

export function* cancelBookingWatcher() {
  yield takeLatest(bookings.cancelBooking.toString(), cancelBookingWorker);
}
