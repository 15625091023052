import {handleBody, handleExceptions, createArrayEndpoint, GET} from '../utils/api';

export const getHotelDetails = (data: {hid: string | number}) => {
  const params = {
    hid: data.hid,
  };

  const endpoint = createArrayEndpoint('hotel/getHotelDetail', params);

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const getRoomsData = (data: {
  hid: string | number;
  checkin: string;
  checkout: string;
  rooms: number;
  adults: number;
  children: number;
}) => {
  const params = {
    hid: data.hid,
    checkin: data.checkin,
    checkout: data.checkout,
    rooms: data.rooms,
    adults: data.adults,
    children: data.children,
    clientUserAgent: window.navigator.userAgent,
  };

  const endpoint = createArrayEndpoint('hotel/getRates', params);

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
