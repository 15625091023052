import {handleActions, combineActions} from 'redux-actions';
import {sendUserMessageSuccess, sendUserMessageFailure} from '../../actions/contact';
import {contactUsResponse} from '../../../interfaces/contact';

export interface contactUsStateType {
  error?: string | object;
  data?: contactUsResponse;
}

const initialState = {};

const handlerMap = new Map([
  [
    combineActions(sendUserMessageSuccess, sendUserMessageFailure),
    (state, {payload}) => {
      return {
        ...state,
        ...payload,
      };
    },
  ],
]);

export default handleActions(handlerMap, initialState);
