import {handleBody, handleExceptions, GET, POST, createArrayEndpoint} from '../utils/api';

export const getMembershipStatus = () => {
  const endpoint = 'CugMember/getCugDetails';

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const joinDeals = (data: {customerId: string; cugOrganization: string}) => {
  const endpoint = 'CugMember/Enroll';

  const options = {
    body: {
      customerId: data.customerId,
      cugOrganization: data.cugOrganization,
    },
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

// export const getDisabilityOrganizationName = (data: {oid: string}) => {
//   const endpoint = createArrayEndpoint('org/getDisabilityOrganization', {...data});

//   return GET(endpoint).then((response) => {
//     if (response.status >= 400) {
//       return handleExceptions(response);
//     }

//     return handleBody(response);
//   });
// };
export const getOrganizationName = (data: {oid: string}) => {
  const endpoint = createArrayEndpoint('org/getPartner', {...data});

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const activateOneTimeDiscount = (data: {partnerId: string}) => {
  const endpoint = 'user/activateOneTimeDiscount';
  const options = {
    body: data,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const activateReferralDiscount = (data: {partnerId: string}) => {
  const endpoint = 'user/activateReferralDiscount';
  const options = {
    body: data,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const activateGivebackProgram = (data: {partnerId: string}) => {
  const endpoint = 'user/activateGivebackParticipant';
  const options = {
    body: data,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const getUserOffers = () => {
  const endpoint = 'user/getUserOffers';

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
