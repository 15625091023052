import {createSelector} from 'reselect';
import {StateShape} from '../redux/store/reducers';
import {accountStateType} from '../redux/store/reducers/account';

export const getAccountState = (state: StateShape) => state.account;

export const getUserPreferencesState = createSelector(
  getAccountState,
  (account: accountStateType) => account.userPreferences,
);

export const getUserLocationState = createSelector(
  getAccountState,
  (account: accountStateType) => account.userLocation,
);

export const getUserBioState = createSelector(
  getAccountState,
  (account: accountStateType) => account.userBio,
);

export const saveBioState = createSelector(
  getAccountState,
  (account: accountStateType) => account.updateUserBio,
);
export const saveLocationState = createSelector(
  getAccountState,
  (account: accountStateType) => account.updateUserLocation,
);
export const savePreferencesState = createSelector(
  getAccountState,
  (account: accountStateType) => account.actionPreferences,
);
