import {handleActions, combineActions} from 'redux-actions';
import {getDiscoursePayloadSuccess, getDiscoursePayloadFailure} from '../../actions/forumDiscourse';
import {IDiscoursePayload} from '../../../interfaces/forumDiscourse';

export interface discoursePayloadStateType {
  error?: boolean;
  data?: IDiscoursePayload;
}

const initialState = {};

const handlerMap = new Map([
  [
    combineActions(getDiscoursePayloadSuccess, getDiscoursePayloadFailure),
    (state, {payload}) => {
      return {
        ...state,
        ...payload,
      };
    },
  ],
]);

export default handleActions(handlerMap, initialState);
