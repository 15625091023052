import {createAction} from 'redux-actions';

const PREFIX = 'AIRLINE_REVIEW_';

export const getAirports = createAction(`${PREFIX}GET_AIRPORTS`);
export const getAirportsSuccess = createAction(
  `${PREFIX}GET_AIRPORTS_SUCCESS`,
  (payload) => payload,
);
export const getAirportsFailure = createAction(
  `${PREFIX}GET_AIRPORTS_FAILURE`,
  (payload) => payload,
);

export const getAirlines = createAction(`${PREFIX}GET_AIRLINES`);
export const getAirlinesSuccess = createAction(
  `${PREFIX}GET_AIRLINES_SUCCESS`,
  (payload) => payload,
);
export const getAirlinesFailure = createAction(
  `${PREFIX}GET_AIRLINES_FAILURE`,
  (payload) => payload,
);

export const addFlightReview = createAction(`${PREFIX}ADD_FLIGHT_REVIEW`);
export const addFlightReviewSuccess = createAction(
  `${PREFIX}ADD_FLIGHT_REVIEW_SUCCESS`,
  (payload) => payload,
);
export const addFlightReviewFailure = createAction(
  `${PREFIX}ADD_FLIGHT_REVIEW_FAILURE`,
  (payload) => payload,
);

export const getAirlineReview = createAction(`${PREFIX}GET_REVIEW`);
export const getAirlineReviewSuccess = createAction(
  `${PREFIX}GET_REVIEW_SUCCESS`,
  (payload) => payload,
);
export const getAirlineReviewFailure = createAction(
  `${PREFIX}GET_REVIEW_FAILURE`,
  (payload) => payload,
);
