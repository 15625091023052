import {GET, POST, createArrayEndpoint, handleBody, handleExceptions} from '../utils/api';
import Cookies from 'js-cookie';

export const searchFlights = (data: {
  flight1: string;
  flight2?: string;
  flight3?: string;
  flight4?: string;
  flight5?: string;
  return_date?: string;
  adults: number;
  children: number;
  lap_infants: number;
  cabin_class: string;
  nonStop: boolean;
  specialAssistance: boolean;
}): Promise<void | Response | object | null> => {
  const params = {
    ...data,
  };

  const endpoint = createArrayEndpoint('flight/depart', params);

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const getReturnFlights = (data: {
  ppnBundle: string;
}): Promise<void | Response | object | null> => {
  const endpoint = 'flight/return';
  const options = {
    body: data,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const getContractData = (data: {
  ppnContractBundle: string;
  ip: string;
}): Promise<void | Response | object | null> => {
  const endpoint = 'flight/contract';
  const options = {
    body: {
      ppnContractBundle: data.ppnContractBundle,
      clientIp: data.ip,
      clientUserAgent: window.navigator.userAgent,
      rsClientCookie: Cookies.get('RS-CLIENT'),
    },
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const bookFlight = (data: {
  tracking_id: string;
  ppnBookBundle: string;
  addInsurance: boolean;
  customer: {
    nameFirst: string;
    nameLast: string;
    email: string;
    phoneNumber: string;
  };
  creditCard: {
    address: string;
    city: string;
    stateCode: string;
    postalCode: string;
    countryCode: string;
    ccName: string;
    ccType: string;
    ccNumber: string;
    ccExpMo: string;
    ccExpYr: string;
    ccCode: string;
  };
  passengers: Array<{
    firstName: string;
    lastName: string;
    gender: string;
    birthday: string;
    midName: string;
    namePrefix: string;
    nameSuffix: string;
    redressNumber: string;
    knownTravelerNumber: string;
    frequentFlyer: Array<{
      frequentFlyerNumber: string;
      frequentFlyerCode: string;
    }>;
    flightSeats: Array<any>;
    seatPreference: string;
    mealPreference: string;
  }>;
  accessibility: {
    comments: string;
    blindness: boolean;
    deafness: boolean;
    cognitiveDisability: boolean;
    serviceDog: boolean;
    wheelchairType: string;
    wheelchairTicketingToSeat: boolean;
  };
  clientIP: string;
  deviceTypeId: string;
  initials: string;
  phoneAgent: string;
  posCurrency: string;
  refclickid: string;
  requestedCurrency: string;
  sid: string;
  rsClientCookie: string;
}) => {
  const endpoint = 'flight/book';
  const options = {
    body: data,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const getFlightConfirmation = (data: {
  bookingId: string;
  email: string;
}): Promise<void | Response | object | null> => {
  const endpoint = createArrayEndpoint('flight/lookup', data);

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const cancelReservation = (data: {
  bookingId: string;
  email: string;
  commentReason: string;
}): Promise<void | Response | object | null> => {
  const endpoint = 'flight/cancel';
  const options = {
    body: data,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
