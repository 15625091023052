import {takeLatest, call, put} from 'redux-saga/effects';
import * as deals from '../actions/deals';
import {
  activateOneTimeDiscount,
  getOrganizationName,
  getMembershipStatus,
  getUserOffers,
  joinDeals,
  activateGivebackProgram,
  activateReferralDiscount,
} from '../../api/deals';
import {gaEvents} from '../../constants/ga4events';

export function* getMembershipStatusWorker() {
  try {
    const data = yield call(getMembershipStatus);
    const formatedData = JSON.parse(data.substring(5));

    if (formatedData.status && formatedData.status === 'success') {
      yield put(deals.getMembershipSuccess({error: false, data: formatedData}));

      //in researching how best to track membership status on the client side (from 02/20/2023)
      //if (process.env.NODE_ENV !== 'production') {
      localStorage.setItem('clubMember', formatedData.status);
      //}
    } else {
      yield put(deals.getMembershipFailure({error: true, data: formatedData}));
    }
  } catch (e) {
    yield put(deals.getMembershipFailure({error: true, data: {}}));
  }
}

export function* getMembershipStatusWatcher() {
  yield takeLatest(deals.getMembershipStatus.toString(), getMembershipStatusWorker);
}

export function* joinDealsWorker({payload}) {
  try {
    // yield call(refreshToken);

    const data = yield call(joinDeals, payload);
    const formatedData = JSON.parse(data.substring(5));

    if (formatedData.status && formatedData.status === 'success') {
      yield put(deals.joinDealsSuccess({eror: false, data: formatedData}));

      localStorage.setItem('clubMember', formatedData.status);
      gaEvents.eventDisabilityOrgSpecified({org: payload.cugOrganization});
    } else {
      yield put(deals.joinDealsFailure({error: true, data: formatedData}));
    }
  } catch (e) {
    yield put(deals.joinDealsFailure({error: true, data: ''}));
  }
}

export function* joinDealsWatcher() {
  yield takeLatest(deals.joinDeals.toString(), joinDealsWorker);
}

export function* getOrganizationNameWorker({payload}) {
  try {
    const data = yield call(getOrganizationName, payload);

    if (data) {
      yield put(deals.getOrganizationNameSuccess({eror: false, data: data}));
    } else {
      yield put(deals.getOrganizationNameFailure({error: true, data: data}));
    }
  } catch (e) {
    yield put(deals.getOrganizationNameFailure({error: true, data: {}}));
  }
}

export function* getOrganizationNameWatcher() {
  yield takeLatest(deals.getOrganizationName.toString(), getOrganizationNameWorker);
}

export function* activateOneTimeDiscountWorker({payload}) {
  try {
    const data = yield call(activateOneTimeDiscount, payload);

    if (data) {
      yield put(deals.activateOneTimeDiscountSuccess({eror: false, data: data}));
      yield put(deals.getUserOffers());
    } else {
      yield put(deals.activateOneTimeDiscountFailure({error: true, data: data}));
    }
  } catch (e) {
    /* eslint-disable-next-line */
    console.log(e);
    yield put(deals.activateOneTimeDiscountFailure({error: true, data: ''}));
  }
}

export function* activateOneTimeDiscountWatcher() {
  yield takeLatest(deals.activateOneTimeDiscount.toString(), activateOneTimeDiscountWorker);
}

export function* activateReferralDiscountWorker({payload}) {
  try {
    const data = yield call(activateReferralDiscount, payload);

    if (data) {
      yield put(deals.activateReferralDiscountSuccess({eror: false, data: data}));
      yield put(deals.getUserOffers());
    } else {
      yield put(deals.activateReferralDiscountFailure({error: true, data: data}));
    }
  } catch (e) {
    /* eslint-disable-next-line */
    console.log(e);
    yield put(deals.activateReferralDiscountFailure({error: true, data: ''}));
  }
}

export function* activateReferralDiscountWatcher() {
  yield takeLatest(deals.activateReferralDiscount.toString(), activateReferralDiscountWorker);
}

export function* activateGivebackParticipantWorker({payload}) {
  try {
    const data = yield call(activateGivebackProgram, payload);

    if (data) {
      yield put(deals.activateGivebackParticipantSuccess({eror: false, data: data}));
      yield put(deals.getUserOffers());
    } else {
      yield put(deals.activateGivebackParticipantFailure({error: true, data: data}));
    }
  } catch (e) {
    /* eslint-disable-next-line */
    console.log(e);
    yield put(deals.activateGivebackParticipantFailure({error: true, data: {}}));
  }
}

export function* activateGivebackParticipantWatcher() {
  yield takeLatest(deals.activateGivebackParticipant.toString(), activateGivebackParticipantWorker);
}

export function* getUserOffersWorker() {
  try {
    const res = yield call(getUserOffers);
    const formatedData = JSON.parse(res.substring(5));

    if (formatedData.status && formatedData.status === 'success') {
      if (formatedData.data.length > 0) {
        const givebackProgram = formatedData.data.filter(
          (offer) => offer.type === 'giveback-participant',
        );
        const offers = formatedData.data.find(
          (offer) =>
            offer.type === 'one-time-discount' || offer.type === 'one-time-discount-referral',
        );

        const offerType =
          givebackProgram.length > 0 ? givebackProgram[0].type : offers ? offers.type : null;

        if (offerType) {
          yield put(deals.getUserOffersSuccess({eror: false, data: offerType}));

          localStorage.setItem(
            'UserOffers',
            JSON.stringify({
              offerType: offerType,
              partnerId: givebackProgram.length > 0 ? givebackProgram[0].partnerId : '',
              partnerName:
                givebackProgram.length > 0 && givebackProgram[0].partnerName
                  ? givebackProgram[0].partnerName
                  : '',
            }),
          );
        } else {
          yield put(deals.getUserOffersSuccess({eror: false, data: ''}));
        }
      } else {
        yield put(deals.getUserOffersSuccess({eror: false, data: ''}));
      }
    } else {
      yield put(deals.getUserOffersFailure({error: true, data: ''}));
    }
  } catch (e) {
    /* eslint-disable-next-line */
    console.log(e);
    yield put(deals.getUserOffersFailure({error: true, data: ''}));
  }
}

export function* getUserOffersWatcher() {
  yield takeLatest(deals.getUserOffers.toString(), getUserOffersWorker);
}
