import {handleBody, handleExceptions, GET, createArrayEndpoint, POST} from '../utils/api';

export const getUserReviews = () => {
  const endpoint = 'user/getUserReviews';

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const getAllReviews = () => {
  const endpoint = 'review/getAllByType?rType=h';

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const getHotelDetails = (data: {ppnid: string}) => {
  const endpoint = createArrayEndpoint('reference/hoteldetail', {...data});

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const addHotelReview = (data: {
  ppnId: string;
  rating: number;
  title: string;
  description: string;
  travelDate?: string;
  accWheelchairUsers?: number | string;
  accLowVision?: number | string;
  accLowHearing?: number | string;
  accLowMobility?: number | string;
  entranceandmainareas?: number | string;
  parking?: number | string;
  elevator?: number | string;
  restaurant?: number | string;
  room?: number | string;
  pool?: number | string;
  fitnesscenter?: number | string;
  businesscenter?: number | string;
  tip?: string;
  accomodationEffort?: string;
}) => {
  const endpoint = 'reference/addHotelreview';
  const options = {
    body: data,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
