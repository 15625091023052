import {handleActions, combineActions} from 'redux-actions';
import * as businessPage from '../../actions/businessPages';
import {
  IBusinessPage,
  //   IArticle,
  //   IArticlesData,
  //   // , ICity
} from '../../../interfaces/businessPage';

export interface businessPageStateType {
  // cities: {error: boolean; data: Array<ICity>};
  //articles: {error: boolean; data: Array<IArticle>};
  businessProfile: {error: boolean; data: IBusinessPage};
  //businessProfileData: {error: boolean; data: IBusinessPage};
}

const initialState = {
  // cities: {error: false, data: null},
  businessProfile: {error: false, data: null},
  //businessProfileData: {error: false, data: null},
  //articlesData: {error: false, data: null},
};

const handlerMap = new Map([
  // [
  //   combineActions(cityGuides.getCitiesSuccess, cityGuides.getCitiesFailure),
  //   (state, {payload}) => {
  //     return {
  //       ...state,
  //       cities: payload,
  //     };
  //   },
  // ],
  [
    combineActions(
      businessPage.getBusinessPageProfileSuccess,
      businessPage.getBusinessPageProfileFailure,
    ),
    (state, {payload}) => {
      return {
        ...state,
        businessProfile: payload,
      };
    },
  ],
]);

export default handleActions(handlerMap, initialState);
