import {handleActions, combineActions} from 'redux-actions';
import * as reviews from '../../actions/reviews';
import {
  IReview,
  HotelReview,
  IReviewHotelDetails,
  IAddReviewResponse,
} from '../../../interfaces/reviews';

export interface reviewsStateType {
  userReviews: {
    error?: string | object;
    status?: string;
    errors?: object | null;
    data?: Array<IReview>;
  };
  allReviews: {
    error: boolean;
    data: Array<HotelReview>;
  };
  hotelDetails: {
    error: boolean;
    data: IReviewHotelDetails;
  };
  addReview: {
    error: boolean;
    data: IAddReviewResponse;
  };
}

export const initialState = {userReviews: {}, allReviews: {}, hotelDetails: {}};

const handlerMap = new Map([
  [
    combineActions(reviews.getUserReviewsSuccess, reviews.getUserReviewsFailure),
    (state, {payload}) => {
      return {
        ...state,
        userReviews: {...payload},
      };
    },
  ],
  [
    combineActions(reviews.getAllReviewsSuccess, reviews.getAllReviewsFailure),
    (state, {payload}) => {
      return {
        ...state,
        allReviews: {...payload},
      };
    },
  ],
  [
    combineActions(reviews.getHotelDetailsSuccess, reviews.getHotelDetailsFailure),
    (state, {payload}) => {
      return {
        ...state,
        hotelDetails: {...payload},
      };
    },
  ],
  [
    combineActions(reviews.addHotelReviewSuccess, reviews.addHotelReviewFailure),
    (state, {payload}) => {
      return {
        ...state,
        addReview: {...payload},
      };
    },
  ],
]);

export default handleActions(handlerMap, initialState);
