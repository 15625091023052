import {createAction} from 'redux-actions';

export const sendUserMessage = createAction('SEND_USER_MESSAGE');
export const sendUserMessageSuccess = createAction(
  'SEND_USER_MESSAGE_SUCCESS',
  (payload) => payload,
);
export const sendUserMessageFailure = createAction(
  'SEND_USER_MESSAGE_FAILURE',
  (payload) => payload,
);
