import {handleBody, handleExceptions, GET_API_MANAGEMENT, GET} from '../utils/api';

export const getLatestPosts = () => {
  const endpoint = 'community/posts';

  return GET_API_MANAGEMENT(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const getAirTravelPosts = () => {
  const endpoint = 'community/posts/air-travel';

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
