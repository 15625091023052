import {takeLatest, call, put} from 'redux-saga/effects';
import * as cityGuides from '../actions/cityGuides';
import {
  getArticles,
  // getCities,
  getCityArticle,
} from '../../api/cityGuides';

// export function* getCitiesWorker() {
//   try {
//     const data = yield call(getCities);

//     if (data.destinations) {
//       yield put(cityGuides.getCitiesSuccess({error: false, data: data.destinations}));
//     } else {
//       yield put(cityGuides.getCitiesFailure({error: true, data: data}));
//     }
//   } catch (e) {
//     yield put(cityGuides.getCitiesFailure({error: true, destinations: []}));
//   }
// }

export function* getCityArticlesWorker({payload}) {
  try {
    const data = yield call(getCityArticle, payload);

    if (data) {
      yield put(cityGuides.getCityArticlesSuccess({error: false, data: data}));
    } else {
      yield put(cityGuides.getCityArticlesFailure({error: true, data: data}));
    }
  } catch (e) {
    yield put(cityGuides.getCityArticlesFailure({error: true, data: {}}));
  }
}

export function* getArticlesWorker({payload}) {
  try {
    const data = yield call(getArticles, payload);

    if (data) {
      yield put(cityGuides.getArticlesSuccess({error: false, data: data}));
    } else {
      yield put(cityGuides.getArticlesFailure({error: true, data: data}));
    }
  } catch (e) {
    yield put(cityGuides.getArticlesFailure({error: true, destinations: {}}));
  }
}

export function* getCitiesWatcher() {
  // yield takeLatest(cityGuides.getCities.toString(), getCitiesWorker);
  yield takeLatest(cityGuides.getCityArticles.toString(), getCityArticlesWorker);
  yield takeLatest(cityGuides.getArticles.toString(), getArticlesWorker);
}
