import React, {memo, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ToastContainer from 'react-bootstrap/ToastContainer';
import PersonalBio from './PersonalBio';
import Main from './MainInformation';
import TravelRequirements from './TravelRequirements';
import LoaderPage from '../../../../components/UI/Loader';
import ToastMessage from '../../../../components/Popups/Toast';
import './styles.less';

import {grayScaleModeState} from '../../../../selectors/globalConfig';
import {
  getUserBioState,
  getUserLocationState,
  getUserPreferencesState,
  saveBioState,
  saveLocationState,
} from '../../../../selectors/account';
import {getUserBio, getUserLocation, getUserPreferences} from '../../../../redux/actions/account';

const MyAccount = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [errorType, setErrorType] = useState<string>('');

  const dispatch = useDispatch();

  const greyMode = useSelector(grayScaleModeState);
  const userBioState = useSelector(getUserBioState);
  const locationState = useSelector(getUserLocationState);
  const preferencesState = useSelector(getUserPreferencesState);
  const updateBioState = useSelector(saveBioState);
  const updateLocationState = useSelector(saveLocationState);

  useEffect(() => {
    dispatch(getUserBio());
    dispatch(getUserLocation());
    dispatch(getUserPreferences());
  }, []);

  useEffect(() => {
    if (
      (updateBioState && !updateBioState.status) ||
      (updateLocationState && !updateLocationState.status)
    ) {
      setError(true);
      setErrorType('saveOperation');
    }
  }, [updateBioState, updateLocationState]);

  useEffect(() => {
    if (userBioState && preferencesState && locationState) {
      if (
        Object.keys(userBioState.data).length !== 0 &&
        Object.keys(preferencesState.data).length !== 0 &&
        Object.keys(locationState.data).length !== 0
      ) {
        setLoading(false);
      }

      if (userBioState.error || locationState.error || preferencesState.error) {
        setLoading(false);
        setError(true);
        setErrorType('dataFetching');
      }
    }
  }, [userBioState, locationState, preferencesState]);

  return (
    <>
      <div className='ac-account'>
        <h2 className={greyMode === 'true' ? 'ac-greyMode' : ''}>My Profile</h2>
        {loading ? (
          <LoaderPage />
        ) : (
          <>
            <Main setExternalError={setError} setExternalErrorType={setErrorType} />
            <section className={greyMode === 'true' ? 'ac-greyMode' : ''}>
              <h3 className='ac-account__welcome-message'>Hi Traveler!</h3>
              <p>
                Please fill out your profile details below so we can send you relevant deals, travel
                articles, and tips, as well as negotiate discounted travel opportunities on behalf
                of our community. (We never share individual profile details with 3rd parties.)
              </p>
            </section>
            <PersonalBio />
            <TravelRequirements />

            <ToastContainer className='ac-toasts-container'>
              {error && <ToastMessage setError={setError} type={errorType} />}
            </ToastContainer>
          </>
        )}
      </div>
    </>
  );
};

export default memo(MyAccount);
