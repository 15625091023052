import {handleBody, handleExceptions, POST} from '../utils/api';

export const rentVan = (data: {
  CustomerId?: string;
  Name: string;
  Email: string;
  Phone?: string;
  LocationPickUp: string;
  LocationDropoff: string;
  DatePickup: string;
  DateDropoff: string;
  IsTest?: boolean;
}) => {
  const endpoint = 'rental/request';
  const options = {
    body: data,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
