import {createAction} from 'redux-actions';

const PREFIX = 'SETTINGS_';

export const getIsUser = createAction(`${PREFIX}GET_IS_USER`);
export const getIsUserSuccess = createAction(`${PREFIX}GET_IS_USER_SUCCESS`, (payload) => payload);
export const getIsUserFailure = createAction(`${PREFIX}GET_IS_USER_FAILURE`, (payload) => payload);

export const changePassword = createAction(`${PREFIX}CHANGE_PASSWORD`);
export const changePasswordSuccess = createAction(
  `${PREFIX}CHANGE_PASSWORD_SUCCESS`,
  (payload) => payload,
);
export const changePasswordFailure = createAction(
  `${PREFIX}CHANGE_PASSWORD_FAILURE`,
  (payload) => payload,
);

export const getClientIP = createAction(`${PREFIX}GET_CLIENT_IP`);
export const getClientIPSuccess = createAction(
  `${PREFIX}GET_CLIENT_IP_SUCCESS`,
  (payload) => payload,
);
export const getClientIPFailure = createAction(
  `${PREFIX}GET_CLIENT_IP_FAILURE`,
  (payload) => payload,
);
