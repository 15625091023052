import {handleActions, combineActions} from 'redux-actions';
import * as bookings from '../../actions/bookings';
import {
  IBookingItem,
  ICurrentBooking,
  ICancelBooking,
  IBookingConfirmation,
} from '../../../interfaces/bookings';

export interface bookingsStateType {
  allBookings: {
    error?: string | object;
    status?: string;
    errors?: null | Array<string>;
    data?: Array<IBookingItem>;
  };
  currentBooking: {error: boolean; data: ICurrentBooking};
  bookingConfirmation: {error: boolean; data: IBookingConfirmation};
  cancelBooking: {error: boolean; data: ICancelBooking};
}

const initialState = {
  allBookings: {},
  currentBooking: {},
  bookingConfirmation: {},
  cancelBooking: {},
};

const handlerMap = new Map([
  [
    combineActions(bookings.getBookingsSuccess, bookings.getBookingsFailure),
    (state, {payload}) => {
      return {
        ...state,
        allBookings: payload,
      };
    },
  ],
  [
    combineActions(bookings.getCurrentBookingSuccess, bookings.getCurrentBookingFailure),
    (state, {payload}) => {
      return {
        ...state,
        currentBooking: payload,
      };
    },
  ],
  [
    combineActions(bookings.getBookingConfirmationSuccess, bookings.getBookingConfirmationFailure),
    (state, {payload}) => {
      return {
        ...state,
        bookingConfirmation: payload,
      };
    },
  ],
  [
    combineActions(bookings.cancelBookingSuccess, bookings.cancelBookingFailure),
    (state, {payload}) => {
      return {
        ...state,
        cancelBooking: payload,
      };
    },
  ],
]);

export default handleActions(handlerMap, initialState);
