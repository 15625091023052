import {takeLatest, call, put} from 'redux-saga/effects';
import * as checkout from '../actions/checkout';
import {getBookingContractData, bookHotel} from '../../api/checkout';
import {getUserOffers} from '../actions/deals';

export function* getBookingContractDataWorker({payload}) {
  if (payload) {
    try {
      const data = yield call(getBookingContractData, payload);

      if (data.status) {
        yield put(checkout.getBookingContractDataSuccess({error: false, data: data}));
      } else {
        yield put(checkout.getBookingContractDataFailure({error: true, data: {}}));
      }
    } catch (e) {
      yield put(checkout.getBookingContractDataFailure({error: true, data: {}}));
    }
  } else {
    yield put(checkout.getBookingContractDataSuccess({error: false, data: {}}));
  }
}

export function* getBookingContractDataWatcher() {
  yield takeLatest(checkout.getBookingContractData.toString(), getBookingContractDataWorker);
}

export function* getRegularRateWorker({payload}) {
  if (payload) {
    try {
      const data = yield call(getBookingContractData, payload);

      if (data.status) {
        yield put(checkout.getRegularRateSuccess({error: false, data: data}));
      } else {
        yield put(checkout.getRegularRateFailure({error: true, data: {}}));
      }
    } catch (e) {
      yield put(checkout.getRegularRateFailure({error: true, data: {}}));
    }
  } else {
    yield put(checkout.getRegularRateSuccess({error: false, data: {}}));
  }
}

export function* getRegularRateWatcher() {
  yield takeLatest(checkout.getRegularRate.toString(), getRegularRateWorker);
}

export function* bookHotelWorker({payload}) {
  if (payload) {
    try {
      const data = yield call(bookHotel, payload);
      const formatedData = JSON.parse(data.substring(5));

      if (formatedData.status) {
        if (formatedData.status === 'success') {
          yield put(checkout.bookHotelSuccess({error: false, data: formatedData}));
          localStorage.removeItem('UserOffers');
          localStorage.removeItem('specialOffers');
          yield put(getUserOffers());
        } else {
          yield put(checkout.bookHotelFailure({error: true, data: formatedData}));
        }
      } else {
        yield put(checkout.bookHotelFailure({error: true, data: {}}));
      }
    } catch (e) {
      yield put(checkout.bookHotelFailure({error: true, data: {}}));
    }
  } else {
    yield put(checkout.bookHotelSuccess({error: false, data: {}}));
  }
}

export function* bookHotelWatcher() {
  yield takeLatest(checkout.bookHotel.toString(), bookHotelWorker);
}
