import {handleBody, handleExceptions, GET, createArrayEndpoint} from '../utils/api';

export const getBusinessPage = (data: {slug: string}) => {
  const endpoint = createArrayEndpoint('business/profile', {...data});

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
