import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';

interface IMetaTag {
  name: string;
  content: string;
}

interface ILinkTag {
  rel: string;
  href: string;
}

interface IProps {
  customMetaTags?: Array<IMetaTag>;
  customLinkTags?: Array<ILinkTag>;
}

const MetaTags = ({customMetaTags, customLinkTags}: IProps) => {
  const defaultDescription = {
    name: 'description',
    content: 'Get accessibility details and community reviews for hotels, flights and more.',
  };
  const defaultMetaTags = [{name: 'theme-color', content: '#3b3f45'}];

  const [metaTags, setMetaTags] = useState<Array<IMetaTag>>([
    defaultDescription,
    ...defaultMetaTags,
  ]);
  const [linkTags, setLinkTags] = useState<Array<ILinkTag>>([]);

  useEffect(() => {
    if (customMetaTags) {
      const description = customMetaTags.filter((tag) => tag.name === 'description').length > 0;

      setMetaTags([
        ...(!description ? [defaultDescription] : []),
        ...defaultMetaTags,
        ...customMetaTags,
      ]);
    }
    if (customLinkTags) setLinkTags(customLinkTags);
  }, []);

  return (
    <Helmet>
      {metaTags.length > 0 &&
        metaTags.map((tag, index) => <meta key={index} name={tag.name} content={tag.content} />)}

      {linkTags.length > 0 &&
        linkTags.map((tag, index) => <link key={index} rel={tag.rel} href={tag.href} />)}
    </Helmet>
  );
};

export default MetaTags;
