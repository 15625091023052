import {createAction} from 'redux-actions';

const PREFIX = 'DEALS_';

export const getMembershipStatus = createAction(`${PREFIX}GET_MEMBERSHIP_STATUS`);
export const getMembershipSuccess = createAction(
  `${PREFIX}GET_MEMBERSHIP_STATUS_SUCCESS`,
  (payload) => payload,
);
export const getMembershipFailure = createAction(
  `${PREFIX}GET_MEMBERSHIP_STATUS_FAILURE`,
  (payload) => payload,
);

export const joinDeals = createAction(`${PREFIX}JOIN_DEALS`);
export const joinDealsSuccess = createAction(`${PREFIX}JOIN_DEALS_SUCCESS`, (payload) => payload);
export const joinDealsFailure = createAction(`${PREFIX}JOIN_DEALS_FAILURE`, (payload) => payload);

export const getOrganizationName = createAction(`${PREFIX}GET_ORGANIZATION_NAME`);
export const getOrganizationNameSuccess = createAction(
  `${PREFIX}GET_ORGANIZATION_NAME_SUCCESS`,
  (payload) => payload,
);
export const getOrganizationNameFailure = createAction(
  `${PREFIX}GET_ORGANIZATION_NAME_FAILURE`,
  (payload) => payload,
);

export const activateOneTimeDiscount = createAction(`${PREFIX}ACTIVATE_ONE_TIME_DISCOUNT`);
export const activateOneTimeDiscountSuccess = createAction(
  `${PREFIX}ACTIVATE_ONE_TIME_DISCOUNT_SUCCESS`,
  (payload) => payload,
);
export const activateOneTimeDiscountFailure = createAction(
  `${PREFIX}ACTIVATE_ONE_TIME_DISCOUNT_FAILURE`,
  (payload) => payload,
);

export const activateReferralDiscount = createAction(`${PREFIX}ACTIVATE_REFERRAL_DISCOUNT`);
export const activateReferralDiscountSuccess = createAction(
  `${PREFIX}ACTIVATE_REFERRAL_DISCOUNT_SUCCESS`,
  (payload) => payload,
);
export const activateReferralDiscountFailure = createAction(
  `${PREFIX}ACTIVATE_REFERRAL_DISCOUNT_FAILURE`,
  (payload) => payload,
);

export const activateGivebackParticipant = createAction(`${PREFIX}ACTIVATE_GIVEBACK_PARTICIPANT`);
export const activateGivebackParticipantSuccess = createAction(
  `${PREFIX}ACTIVATE_GIVEBACK_PARTICIPANT_SUCCESS`,
  (payload) => payload,
);
export const activateGivebackParticipantFailure = createAction(
  `${PREFIX}ACTIVATE_GIVEBACK_PARTICIPANT_FAILURE`,
  (payload) => payload,
);

export const getUserOffers = createAction(`${PREFIX}GET_USER_OFFERS`);
export const getUserOffersSuccess = createAction(
  `${PREFIX}GET_USER_OFFERS_SUCCESS`,
  (payload) => payload,
);
export const getUserOffersFailure = createAction(
  `${PREFIX}GET_USER_OFFERS_FAILURE`,
  (payload) => payload,
);
