export const routeNames = {
  home: '/',
  redirection: '/r',
  // redirection_OLD: '/d',
  forumRedirection: '/f',
  account: '/account',
  unsubscribe: '/unsubscribe',
  verified: '/verified',
  social_login_redir: '/social_login_redir',
  passchanged: '/passchanged',

  about: '/about',
  accessibility: '/accessibility',
  partners: '/partners',
  press: '/press',
  contact: '/contact',
  faq: '/faq',
  terms_of_use: '/terms_of_use',
  terms_of_use_business: '/terms_of_use_business',
  privacy_policy: '/privacy_policy',
  sitemap: '/sitemap',
  supported_browsers: '/supported-browsers',

  travelKit: '/travelkit',
  city_guides: '/by-city',
  city: '/city',
  resources: '/resources',
  articles: '/articles',
  reviewHotel: '/reviewHotel',
  travel_ideas: '/travel-ideas',
  community: '/community',
  vanRentals: '/VanRentals',
  mobilityRentals: '/MobilityRentals',

  hotels: '/hotels',
  hotel: '/hotel',
  reserve: '/hotel/reserve',
  booking: '/booking/hotel',
  confirmation: '/confirmation',
  review: '/review',
  hotelCertification: '/certification',

  rentalCar: '/cars',
  reserveCar: '/cars/reserve',
  rentalCarConf: '/cars/confirmation',
  carReservation: '/cars/reservation',

  flights: '/flights',
  reserveFlight: '/flights/reserve',
  flightConf: '/flights/confirmation',
  flightReservation: '/flights/reservation',
  airlineReview: '/flights/review',

  transfers: '/transfers',

  business: '/b',

  widget: '/w',
};

export const profileRoutes = {
  profile: 'profile',
  bookings: 'bookings',
  favorites: 'favorites',
  reviews: 'reviews',
  deals: 'deals',
  settings: 'settings',
};

export const routeTitles = {
  site: 'Accessible Travel:',
  tagline: 'One stop shop for all your disability travel needs.',

  home: 'Find and book accessible hotels, rental cars, and more.',
  'travel-ideas': 'Travel Ideas',

  //hotels
  hotels: 'Hotels - Search',
  hotel: 'View Hotel Detail',
  reserve: 'accessibleGO.com – Hotels - Reserve',
  confirmation: 'Hotel Booking Confirmation',
  review: 'Write a Hotel Accessibility Review',

  //cars
  cars: 'Car Rentals',
  'cars/confirmation': 'Rental Car Booking Confirmation',
  rentalCarConf: 'Rental Car Booking Confirmation',

  //flights
  flights: 'Flights',

  //rentals
  VanRentals: 'Wheelchair Van Rentals',
  MobilityRentals: 'Mobility Equipment Rentals',
  transfers: 'Wheelchair Accessible Rides',

  //account
  account: 'My Account',
  'account/profile': 'Account: My Profile',
  'account/bookings': 'Account: My Bookings',
  'account/favorites': 'Account: My Favorites',
  'account/reviews': 'Account: My Reviews',
  'account/deals': 'Account: Deals',
  'account/settings': 'Account: Settings',
  verified: 'Account: Email Verified',

  //content
  travelKit: 'Travel Kit',
  articles: 'Articles',
  'by-city': 'City Guides',

  //about
  about: 'About',
  accessibility: 'Website Accessibility',
  partners: 'Our Partners',
  press: 'In the Press',
  contact: 'Contact Us',
  faq: 'Frequently Asked Questions',

  //policies
  terms_of_use: 'Terms of Use',
  terms_of_use_business: 'Terms of Use for Businesses',
  privacy_policy: 'Privacy Policy',

  //other
  sitemap: 'Sitemap',
  'supported-browsers': 'Supported Browsers',
};

export const communityLink = 'https://community.accessiblego.com';
