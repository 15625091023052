import React, {useEffect, useState, lazy, Suspense, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Navigate, Route, Routes, useLocation, useSearchParams} from 'react-router-dom';
import {gaEvents} from './constants/ga4events';
import {routeNames} from './constants/routes';
import ErrorBoundary from './utils/ErrorBoundary';
import ScrollToTop from './utils/ScrollToTop';
import {getMembershipStatusState} from './selectors/deals';
import {getUserData, signOut, socialSignInSuccess, updateToken} from './redux/actions/auth';
import {
  activateReferralDiscount,
  getMembershipStatus,
  getUserOffers,
  getUserOffersSuccess,
} from './redux/actions/deals';
import {getUserBio} from './redux/actions/account';
import {getAuthorizationState} from './selectors/auth';
import {getConfig} from './config';
import MetaTags from './utils/MetaTags';
import {fbPixelIntegration, fbSDKIntegration} from './constants/inlineScripts';
import useScript from './hooks/useScript';

import Profile from './pages/Profile';

const Home = lazy(() => import('./pages/Home'));
const TravelIdeas = lazy(() => import('./pages/TravelIdeas'));
const VanRentals = lazy(() => import('./pages/VanRentals'));
const MobilityRentals = lazy(() => import('./pages/MobilityRentals'));
// const CircleForum = lazy(() => import('./pages/CircleForum'));
const Terms = lazy(() => import('./pages/Terms'));
const TermsBusiness = lazy(() => import('./pages/TermsBusiness'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const Sitemap = lazy(() => import('./pages/Sitemap'));
const SupportedBrowsers = lazy(() => import('./pages/SupportedBrowsers'));
const TravelKit = lazy(() => import('./pages/TravelKit'));
const About = lazy(() => import('./pages/About'));
const Accessibility = lazy(() => import('./pages/Accessibility'));
const Partners = lazy(() => import('./pages/Partners'));
const News = lazy(() => import('./pages/News'));
const Unsubscribe = lazy(() => import('./pages/Unsubscribe'));
const Contact = lazy(() => import('./pages/Contact'));
const FAQ = lazy(() => import('./pages/FAQ'));

const Hotels = lazy(() => import('./pages/Hotels'));
const Hotel = lazy(() => import('./pages/Hotel'));
const HotelContract = lazy(() => import('./pages/HotelContract'));
const BookingPage = lazy(() => import('./pages/HotelBooking'));
const HotelCertification = lazy(() => import('./pages/HotelCertification'));

const RentalCarPage = lazy(() => import('./pages/RentalCar'));
const RentalCarContract = lazy(() => import('./pages/RentalCarContract'));
const RentalCarConfirmation = lazy(() => import('./pages/RentalCarConfirmation'));
const ViewCarReservation = lazy(() => import('./pages/RentalCarReservation'));

const Flights = lazy(() => import('./pages/Flights'));
const FlightsContract = lazy(() => import('./pages/FlightsContract'));
const FlightsConfirmation = lazy(() => import('./pages/FlightsConfirmation'));
const FlightsReservation = lazy(() => import('./pages/FlightsReservation'));
const FlightReview = lazy(() => import('./pages/FlightReview'));
const Business = lazy(() => import('./pages/Business'));
const Widget = lazy(() => import('./pages/Widget'));
const Transfers = lazy(() => import('./pages/Transfers'));
const CityGuides = lazy(() => import('./pages/CityGuides'));
const City = lazy(() => import('./pages/City'));
const Resources = lazy(() => import('./pages/Resources'));
const Articles = lazy(() => import('./pages/Articles'));
const Review = lazy(() => import('./pages/Review'));
const Verified = lazy(() => import('./pages/Verified'));
const ReviewHotel = lazy(() => import('./pages/ReviewHotel'));
const Confirmation = lazy(() => import('./pages/HotelConfirmation'));
const PasswordReset = lazy(() => import('./pages/PasswordReset'));
const Header = lazy(() => import('./components/Header'));
const Footer = lazy(() => import('./components/Footer'));
const SessionPopup = lazy(() => import('./components/Popups/AccountPopups/SessionPopup'));
const LogoutPopup = lazy(() => import('./components/Popups/AccountPopups/LogoutPopup'));
const LogInPopup = lazy(
  () => import('./components/Popups/AccountPopups/Authentication/LogInPopup'),
);
const SignUpPopup = lazy(
  () => import('./components/Popups/AccountPopups/Authentication/SignUpPopup'),
);
const LoaderPage = lazy(() => import('./components/UI/Loader'));
const LoginRedirectionRoute = lazy(() => import('./routes/LoginRedirectionRoute'));
const ForumRedirectionRoute = lazy(() => import('./routes/ForumRedirectionRoute'));
// const RedirectionRoute = lazy(() => import('./routes/RedirectionRoute'));
const RedirectionRouteNEW = lazy(() => import('./routes/RedirectionRoute_NEW'));

const config = getConfig();

function App({webAuth}) {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [showSessionPopup, setShowSessionPopup] = useState<boolean>(false);
  const [showlogoutPopup, setShowlogoutPopup] = useState<boolean>(false);
  const [showJoinPopup, setShowJoinPopup] = useState<boolean>(false);
  const [showLoginPopup, setShowLoginPopup] = useState<boolean>(false);

  const location = useLocation();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const memberStatus = useSelector(getMembershipStatusState);
  const authState = useSelector(getAuthorizationState);

  if (['production'].indexOf(process.env.NODE_ENV) > -1) {
    //FB Integration :: SDK
    useScript(fbSDKIntegration, 'head', {defer: 'defer', SameSite: 'Secure'});

    //FB Integration :: Pixel
    useScript(fbPixelIntegration, 'head', {defer: 'defer', SameSite: 'Secure'});
  }

  //ppn fraud prevention
  useScript(
    '',
    'head',
    {
      type: 'text/javascript',
      src: 'https://secure.rezserver.com/sdk/v1/7760/client.js',
      async: 'async',
    },
    true,
  );

  //token rotation
  //#region
  const workerRef = useRef(new Worker(new URL('./worker.ts', import.meta.url)));
  const worker = workerRef.current;
  worker.onmessage = (e) => {
    const refreshToken = localStorage.getItem('rT');
    if (e.data) {
      if (refreshToken) {
        dispatch(updateToken());
      } else {
        webAuth.checkSession(
          {
            domain: config.domain,
            clientID: config.clientId,
            audience: config.audience,
            redirectUri: window.location.origin,
            scope: 'read:messages',
          },
          function (err, authResult) {
            if (authResult && authResult.accessToken) {
              dispatch(socialSignInSuccess({error: false, data: authResult}));
              localStorage.setItem('accessToken', authResult.accessToken);
              localStorage.setItem(
                'loginTs',
                JSON.stringify({expiresIn: authResult.expiresIn, updateTime: new Date()}),
              );
            }

            if (err) {
              dispatch(signOut());
            }
          },
        );
      }
    }
  };

  useEffect(() => {
    let ts;
    const storedTs = localStorage.getItem('loginTs');

    if (authState.data.expires_in) {
      ts = {expiresIn: authState.data.expires_in, updateTime: new Date()};
    } else if (storedTs) {
      ts = JSON.parse(storedTs);
    }

    if (ts) worker.postMessage(ts);
  }, [authState]);
  //#endregion

  useEffect(() => {
    if (memberStatus.data.status) {
      if ((window as any).SessionStack) {
        (window as any).SessionStack.identify({
          // required variables
          userId: localStorage.getItem('userId'),
          email: localStorage.getItem('email'),
          displayName: localStorage.getItem('Name'),

          // custom variables
          hasDeal_bool: memberStatus.data.status === 'success',
        });
      }
    }
  }, [memberStatus]);

  useEffect(() => {
    //login
    webAuth.parseHash({hash: window.location.hash}, function (err, authResult) {
      if (!authResult) {
        setIsAuthenticated(false);
      } else {
        setIsAuthenticated(true);
        window.location.hash = '';

        //ga-4
        switch (authResult.state) {
          case 'google-oauth2 login':
            gaEvents.eventLoginGoogle();
            break;
          case 'facebook login':
            gaEvents.eventLoginFacebook();
            break;
          case 'google-oauth2 signUp':
            gaEvents.eventSignUpGoogle();
            break;
          case 'facebook signUp':
            gaEvents.eventSignUpFacebook();
            break;
          default:
            break;
        }
        //

        localStorage.setItem('accessToken', authResult.accessToken);
        localStorage.setItem(
          'loginTs',
          JSON.stringify({expiresIn: authResult.expiresIn, updateTime: new Date()}),
        );
        localStorage.removeItem('redirection');

        dispatch(getMembershipStatus());
        dispatch(getUserOffers());
        dispatch(getUserBio());
        dispatch(getUserData());
        dispatch(socialSignInSuccess({error: false, data: authResult}));

        if ((window as any).SessionStack) {
          (window as any).SessionStack.log('User Signed In (social)', {level: 'info'});
        }
      }
    });
    //

    // Begin SessionStack Integration
    if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'development') {
      const scriptElement = document.createElement('script');
      scriptElement.setAttribute('defer', 'defer');
      scriptElement.setAttribute('SameSite', 'Secure');
      const js =
        '' +
        '!function(a,b){var c=window;c.SessionStackKey=a,c[a]=c[a]||{t:b,' +
        'q:[]};for(var d=["start","stop","identify","getSessionId","log","setOnDataCallback"],e=0;e<d.length;e++)!function(b){' +
        'c[a][b]=c[a][b]||function(){c[a].q.push([b].concat([].slice.call(arguments,0)));' +
        '}}(d[e]);var f=document.createElement("script");f.async=1;f.crossOrigin="anonymous";' +
        'f.src="https://cdn.sessionstack.com/sessionstack.js";' +
        'var g=document.getElementsByTagName("script")[0];g.parentNode.insertBefore(f,g);' +
        '}("SessionStack","' +
        process.env.REACT_APP_SESSIONSTACK_PROJECT_TOKEN +
        '");';

      const inlineScript = document.createTextNode(js);
      scriptElement.appendChild(inlineScript);
      document.head.insertAdjacentElement('afterbegin', scriptElement);

      (window as any).SessionStack.start({
        sensitiveInputFields: false,
      });
    }
    //End SessionStack Integration

    //Begin Hotjar Integration
    if (process.env.NODE_ENV === 'production') {
      const scriptElement = document.createElement('script');
      scriptElement.setAttribute('defer', 'defer');
      scriptElement.setAttribute('SameSite', 'Secure');
      const jsScript =
        '' +
        '(function(h,o,t,j,a,r){' +
        'h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};' +
        'h._hjSettings={hjid:1004038,hjsv:6};' +
        "a=o.getElementsByTagName('head')[0];" +
        "r=o.createElement('script');r.async=1;" +
        "r.crossorigin='anonymous';" +
        'r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;' +
        'a.appendChild(r);' +
        "})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');";
      const inlineScript = document.createTextNode(jsScript);
      scriptElement.appendChild(inlineScript);
      document.head.insertAdjacentElement('afterbegin', scriptElement);

      (window as any).hj('stateChange', window.location.href);
    }
    //End Hotjar Integration
  }, []);

  useEffect(() => {
    const params = {} as any;
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }

    if (params.aic) {
      localStorage.setItem(
        'UserOffers',
        JSON.stringify({
          offerType: 'one-time-discount-referral',
          partnerId: '',
          partnerName: '',
        }),
      );
      dispatch(getUserOffersSuccess({eror: false, data: 'one-time-discount-referral'}));
    }

    const offers = localStorage.getItem('UserOffers');

    if (authState.data.access_token && offers) {
      const offer = JSON.parse(offers);
      if (offer.offerType === 'one-time-discount-referral') {
        dispatch(activateReferralDiscount({partnerId: ''}));
      }
    }

    if (!isAuthenticated && authState.isAuth) setIsAuthenticated(authState.isAuth);
  }, [authState]);

  useEffect(() => {
    const auth = localStorage.getItem('accessToken');
    const logout = sessionStorage.getItem('logout');

    if (auth) setIsAuthenticated(true);
    if (logout) setShowlogoutPopup(true);
  }, [location]);

  //session handling for inactive user
  //#region
  let warnTimeout;
  const events: Array<string> = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

  const setTimeOut = () => {
    warnTimeout = setTimeout(() => setShowSessionPopup(true), 14 * 24 * 60 * 60 * 1000);
  };

  const resetTimeOut = (): void => {
    clearTimeout(warnTimeout);
    setTimeOut();
  };

  useEffect(() => {
    if (isAuthenticated) {
      for (const i in events) {
        window.addEventListener(events[i], resetTimeOut);
      }
      setTimeOut();
    }

    return () => {
      for (const i in events) {
        window.removeEventListener(events[i], resetTimeOut);
      }
    };
  }, [isAuthenticated]);
  //#endregion

  return (
    <>
      {/* <React.StrictMode> */}
      <MetaTags />
      <Suspense fallback={<LoaderPage full />}>
        <Header />

        <ErrorBoundary>
          <ScrollToTop>
            <Routes>
              <Route path={routeNames.home} element={<Home />} />
              <Route path={routeNames.travel_ideas} element={<TravelIdeas />} />
              {/* <Route path={routeNames.community} element={<CircleForum />} /> */}
              <Route path={routeNames.vanRentals} element={<VanRentals />} />
              <Route path={routeNames.mobilityRentals} element={<MobilityRentals />} />

              <Route path={routeNames.about} element={<About />} />
              <Route path={routeNames.accessibility} element={<Accessibility />} />
              <Route path={routeNames.partners} element={<Partners />} />
              <Route path={routeNames.press} element={<News />} />
              <Route path={routeNames.unsubscribe} element={<Unsubscribe />} />
              <Route path={routeNames.contact} element={<Contact />} />
              <Route path={routeNames.faq} element={<FAQ />} />
              <Route path={routeNames.travelKit} element={<TravelKit />} />

              <Route path={routeNames.hotels} element={<Hotels />} />
              <Route path={routeNames.hotel} element={<Hotel />} />
              <Route path={routeNames.reserve} element={<HotelContract />} />
              <Route path={routeNames.booking} element={<BookingPage />} />
              <Route path={routeNames.hotelCertification} element={<HotelCertification />} />

              <Route path={routeNames.rentalCar} element={<RentalCarPage />} />
              <Route path={routeNames.reserveCar} element={<RentalCarContract />} />
              <Route path={routeNames.rentalCarConf} element={<RentalCarConfirmation />} />
              <Route path={routeNames.carReservation} element={<ViewCarReservation />} />

              <Route path={routeNames.flights} element={<Flights />} />
              <Route path={routeNames.reserveFlight} element={<FlightsContract />} />
              <Route path={routeNames.flightConf} element={<FlightsConfirmation />} />
              <Route path={routeNames.flightReservation} element={<FlightsReservation />} />
              <Route path={routeNames.airlineReview} element={<FlightReview />} />

              <Route path={routeNames.transfers} element={<Transfers />} />

              <Route path={routeNames.terms_of_use} element={<Terms />} />
              <Route path={routeNames.terms_of_use_business} element={<TermsBusiness />} />

              <Route path={routeNames.privacy_policy} element={<PrivacyPolicy />} />
              <Route path={routeNames.sitemap} element={<Sitemap />} />
              <Route path={routeNames.supported_browsers} element={<SupportedBrowsers />} />
              <Route path={routeNames.city_guides} element={<CityGuides />} />
              <Route path={`${routeNames.city}/:city/:state/:country/:action`} element={<City />} />
              <Route
                path={`${routeNames.resources}/:city/:state/:country/:action`}
                element={<Resources />}
              />
              <Route path={`${routeNames.articles}/:title`} element={<Articles />} />
              <Route path={routeNames.review} element={<Review />} />
              <Route path={routeNames.reviewHotel} element={<ReviewHotel />} />

              {/* <Route path={routeNames.redirection_OLD} element={<RedirectionRoute />} /> */}
              <Route path={routeNames.redirection} element={<RedirectionRouteNEW />} />
              <Route path={routeNames.social_login_redir} element={<LoginRedirectionRoute />} />
              <Route path={routeNames.forumRedirection} element={<ForumRedirectionRoute />} />
              <Route path={routeNames.verified} element={<Verified />} />
              <Route path={routeNames.passchanged} element={<PasswordReset />} />

              <Route path={`${routeNames.business}/:slug`} element={<Business />} />

              <Route path={`${routeNames.widget}/:page`} element={<Widget />} />

              <Route path={'*'} element={<Home />} />

              {isAuthenticated ? (
                <>
                  <Route path={routeNames.confirmation} element={<Confirmation />} />
                  <Route path={`${routeNames.account}/*`} element={<Profile />} />
                </>
              ) : (
                <>
                  <Route
                    path={routeNames.confirmation}
                    element={<Navigate replace to={routeNames.home} />}
                  />
                  <Route
                    path={routeNames.airlineReview}
                    element={<Navigate replace to={routeNames.home} />}
                  />
                  <Route
                    path={`${routeNames.account}/*`}
                    element={
                      <Navigate
                        replace
                        to={`${routeNames.redirection}?a=l&r=${location.pathname.slice(1)}`}
                      />
                    }
                  />
                </>
              )}
            </Routes>
          </ScrollToTop>

          {showSessionPopup && (
            <SessionPopup showPopup={showSessionPopup} setShowPopup={setShowSessionPopup} />
          )}

          {showlogoutPopup && (
            <LogoutPopup
              showPopup={showlogoutPopup}
              setShowPopup={setShowlogoutPopup}
              setShowLoginPopup={setShowLoginPopup}
            />
          )}

          {showLoginPopup && (
            <LogInPopup
              showPopup={showLoginPopup}
              setShowPopup={setShowLoginPopup}
              setShowJoinPopup={setShowJoinPopup}
            />
          )}

          {showJoinPopup && (
            <SignUpPopup
              showPopup={showJoinPopup}
              setShowPopup={setShowJoinPopup}
              setShowLoginPopup={setShowLoginPopup}
            />
          )}
        </ErrorBoundary>

        <Footer auth={isAuthenticated} />
      </Suspense>
      {/* </React.StrictMode> */}
    </>
  );
}

export default App;
