import {handleActions, combineActions} from 'redux-actions';
import * as search from '../../actions/hotelsSearch';
import {HotelSearchResults, AccessibilityHotelsData} from '../../../interfaces/hotelsSearch';

export interface hotelsSearchStateType {
  accessibilityHotelsData: AccessibilityHotelsData;
  searchResults: HotelSearchResults;
}

const initialState = {
  accessibilityHotelsData: {error: false, data: {}},
  searchResults: {error: false, data: {}},
};

const handlerMap = new Map([
  [
    combineActions(search.getAccessibilityDataSuccess, search.getAccessibilityDataFailure),
    (state, {payload}) => {
      return {
        ...state,
        accessibilityHotelsData: payload,
      };
    },
  ],
  [
    combineActions(search.getHotelsSuccess, search.getHotelsFailure),
    (state, {payload}) => {
      return {
        ...state,
        searchResults: payload,
      };
    },
  ],
]);

export default handleActions(handlerMap, initialState);
